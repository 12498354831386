import { Tooltip } from 'antd';
import StatusBadge from 'business/common/components/statusBadge';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import {
  getCountriesKey,
  getCountryKey,
} from 'technical/countriesTranslation/constants';
import { formatDate } from 'technical/date';
import sanitizeStringList from 'technical/format';
import { StatusType } from 'business/common/services';
import { isAnnuityNotHandledByBdl } from 'business/patent/services/patent.service';

const patentColumns = (t: TFunction) => ({
  patentFamily: [
    {
      title: t('table.common.column.status'),
      dataIndex: 'status',
      key: 'status',
      width: '70px',
      render: (_: any, patent: any) => (
        <StatusBadge
          status={
            isAnnuityNotHandledByBdl(patent)
              ? StatusType.NotHandledByBdl
              : patent.status
          }
        />
      ),
      sorter: false,
    },
    {
      title: t('table.common.column.holder'),
      dataIndex: 'holder',
      key: 'holder',
      sorter: true,
    },
    {
      title: t('table.common.column.effectiveHolder'),
      dataIndex: 'effectiveHolder',
      key: 'effectiveHolder',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.title', { context: 'patent' }),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (_: any, record: any) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={`/patent/${record.id}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      title: t('table.common.column.countries'),
      sorter: true,
      dataIndex: 'countryFr',
      key: 'countryFr',
      render: (_: any, data: any) => {
        return (
          <Tooltip title={data[getCountryKey()]}>
            {data[getCountryKey()]}
          </Tooltip>
        );
      },
    },
    {
      title: t('table.common.column.depositNumber'),
      dataIndex: 'depositNumber',
      key: 'depositNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.depositDate'),
      dataIndex: 'depositDate',
      key: 'depositDate',
      render: (_: any, data: any) => {
        if (data.depositDate) {
          return formatDate(new Date(data.depositDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.publicationNumber'),
      dataIndex: 'publicationNumber',
      key: 'publicationNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.publicationDate'),
      dataIndex: 'publicationDate',
      key: 'publicationDate',
      render: (_: any, data: any) => {
        if (data.publicationDate) {
          return formatDate(new Date(data.publicationDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.issueNumber'),
      dataIndex: 'issueNumber',
      key: 'issueNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.issueDate'),
      dataIndex: 'issueDate',
      key: 'issueDate',
      render: (_: any, data: any) => {
        if (data.issueDate) {
          return formatDate(new Date(data.issueDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.expirationDate'),
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: (_: any, data: any) => {
        if (data.expirationDate) {
          return formatDate(new Date(data.expirationDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.bdlRef'),
      dataIndex: 'bdlRef',
      key: 'bdlRef',
      sorter: true,
    },
    {
      title: t('table.common.column.misRef'),
      dataIndex: 'misRef',
      key: 'misRef',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.clientRef'),
      dataIndex: 'clientRef',
      key: 'clientRef',
      sorter: true,
    },
    {
      title: t('table.common.column.clientRef2'),
      dataIndex: 'clientRef2',
      key: 'clientRef2',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.bdlOfficer'),
      dataIndex: 'bdlOfficer',
      key: 'bdlOfficer',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.inventors'),
      dataIndex: 'inventors',
      key: 'inventors',
      render: (_: any, data: any) => sanitizeStringList(data?.inventors),
      sorter: true,
      hidden: true,
    },
  ],
  patentFamilies: [
    {
      title: t('table.common.column.holder'),
      dataIndex: 'holder',
      key: 'holder',
      sorter: true,
    },
    {
      title: t('table.common.column.title', { context: 'patent' }),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      width: '250px',
      render: (_: any, record: any) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={`/patent/family/${record.id}`}
        >
          {record.title.toUpperCase()}
        </Link>
      ),
    },
    {
      title: t('table.common.column.countries'),
      sorter: true,
      dataIndex: 'countriesFr',
      key: 'countriesFr',
      render: (_: any, data: any) => {
        return (
          <Tooltip title={data[getCountriesKey()]}>
            {data[getCountriesKey()]}
          </Tooltip>
        );
      },
    },
    {
      title: t('table.common.column.firstDepositNumber'),
      dataIndex: 'firstDepositNumber',
      key: 'firstDepositNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.firstDepositDate'),
      dataIndex: 'firstDepositDate',
      key: 'firstDepositDate',
      render: (_: any, data: any) => {
        if (data.firstDepositDate) {
          return formatDate(new Date(data.firstDepositDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.clientRef'),
      dataIndex: 'clientRef',
      ellipsis: true,
      key: 'clientRef',
      sorter: true,
    },
    {
      title: t('table.common.column.bdlRef'),
      dataIndex: 'bdlRef',
      key: 'bdlRef',
      sorter: true,
    },
    {
      title: t('table.common.column.clientOfficer'),
      dataIndex: 'clientOfficer',
      key: 'clientOfficer',
      sorter: true,
    },
    {
      title: t('table.common.column.bdlOfficerName'),
      dataIndex: 'bdlOfficerName',
      key: 'bdlOfficerName',
      sorter: true,
    },
    {
      title: t('table.common.column.inventors'),
      dataIndex: 'inventors',
      key: 'inventors',
      width: '250px',
      render: (_: any, data: any) => sanitizeStringList(data?.inventors),
      sorter: true,
    },
  ],
});

export default patentColumns;
