import {
  GenerateExcelAfuQueryVariables,
  useGenerateExcelAfuLazyQuery,
  useGetTrademarkAffidavitRenewedQuery,
  useGetTrademarkAffidavitToRenewQuery,
  useGetTrademarkProofOfUseRenewedQuery,
  useGetTrademarkProofOfUseToRenewQuery,
} from 'generated/graphql';
import { useState } from 'react';
import { OsrFilterState } from './types';
import useTableColumn from 'technical/table/useTableColumn';
import {
  AFU_RENEWED_COLUMNS,
  AFU_TO_RENEW_COLUMNS,
  PROOF_OF_USED_RENEWED_COLUMNS,
  PROOF_OF_USED_TO_RENEW_COLUMNS,
} from 'business/resources/osr/components/trademark/trademarkOsr.constant';
import { convertToBinaryAndDownloadFile } from 'technical/fileManagement';
import { MIME_TYPES_BY_FILE_FORMAT } from 'technical/fileManagement/constants';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

export const useExportAfu = (filters: GenerateExcelAfuQueryVariables) => {
  const [loading, setLoading] = useState(false);
  const [generateExcelFile] = useGenerateExcelAfuLazyQuery();

  const generateExcel = async () => {
    try {
      setLoading(true);
      const { data } = await generateExcelFile({
        fetchPolicy: 'no-cache',
        variables: {
          ...filters,
        },
      });
      const document = data?.ExportAfu;

      if (!document) {
        return '';
      }

      return convertToBinaryAndDownloadFile(
        document,
        MIME_TYPES_BY_FILE_FORMAT.xlsx,
        'OSR_EXPORT',
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    generateExcel,
  };
};

export const useGetProofOfUseToRenew = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { nextRenewDate: 'asc' },
) => {
  const [searchProofOfUsedToRenew, setSearchProofOfUsedToRenew] =
    useState<string>();
  const [excludingArgentina, setExcludingArgentina] = useState(false);
  const { data, loading, error, refetch } =
    useGetTrademarkProofOfUseToRenewQuery({
      fetchPolicy: 'no-cache',
      variables: {
        limit: filters.limit,
        offset: filters.offset,
        where: {
          dueDate: filters.dueDate,
          countryCode: filters.countryCode,
          search: searchProofOfUsedToRenew,
          companyRef: filters.companyRef,
          excludingArgentina,
        },
        orderBy,
      },
    });

  return {
    loading,
    error,
    refetch,
    setSearchProofOfUsedToRenew,
    setExcludingArgentina,
    excludingArgentina,
    searchProofOfUsedToRenew,
    data: data?.GetTrademarkProofOfUseToRenew?.resources ?? [],
    total: data?.GetTrademarkProofOfUseToRenew?.count ?? 0,
  };
};

export const useGetProofOfUseRenewed = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { depositDate: 'asc' },
) => {
  const [searchProofOfUsedRenewed, setSearchProofOfUsedRenewed] =
    useState<string>();
  const { data, loading, error, refetch } =
    useGetTrademarkProofOfUseRenewedQuery({
      fetchPolicy: 'no-cache',
      variables: {
        limit: filters.limit,
        offset: filters.offset,
        where: {
          dueDate: filters.dueDate,
          countryCode: filters.countryCode,
          search: searchProofOfUsedRenewed,
          companyRef: filters.companyRef,
        },
        orderBy,
      },
    });

  return {
    loading,
    error,
    refetch,
    setSearchProofOfUsedRenewed,
    searchProofOfUsedRenewed,
    data: data?.GetTrademarkProofOfUseRenewed?.resources ?? [],
    total: data?.GetTrademarkProofOfUseRenewed?.count ?? 0,
  };
};

export const useGetAfuToRenew = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    search?: string;
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { dueDate: 'asc' },
) => {
  const [lastDeadlineAffidavit, setLastDeadlineAffidavit] = useState(true);
  const [excludingArgentina, setExcludingArgentina] = useState(false);
  const [firstAffidavitEvent, setFirstAffidavitEvent] = useState(false);
  const [searchAfuToRenew, setSearchAfuToRenew] = useState<string>();

  const { data, loading, error, refetch } =
    useGetTrademarkAffidavitToRenewQuery({
      fetchPolicy: 'no-cache',
      variables: {
        limit: filters.limit,
        offset: filters.offset,
        where: {
          dueDate: filters.dueDate,
          countryCode: filters.countryCode,
          search: searchAfuToRenew,
          companyRef: filters.companyRef,
          firstAffidavitEvent: firstAffidavitEvent,
          lastDeadlineAffidavit: lastDeadlineAffidavit,
          excludingArgentina: excludingArgentina,
        },
        orderBy,
      },
    });

  return {
    loading,
    error,
    setLastDeadlineAffidavit,
    setExcludingArgentina,
    setFirstAffidavitEvent,
    setSearchAfuToRenew,
    searchAfuToRenew,
    excludingArgentina,
    lastDeadlineAffidavit,
    firstAffidavitEvent,
    refetch,
    data: data?.GetTrademarkAffidavitToRenew?.resources ?? [],
    total: data?.GetTrademarkAffidavitToRenew?.count ?? 0,
  };
};

export const useGetAfuRenewed = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    search?: string;
    companyRef?: string;
    affidavitAr?: boolean;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { dueDate: 'asc' },
) => {
  const [searchAfuRenewed, setSearchAfuRenewed] = useState<string>();
  const [affidavitAr, setAffidavitAr] = useState(false);
  const { data, loading, error, refetch } =
    useGetTrademarkAffidavitRenewedQuery({
      fetchPolicy: 'no-cache',
      variables: {
        limit: filters.limit,
        offset: filters.offset,
        where: {
          dueDate: filters.dueDate,
          countryCode: filters.countryCode,
          search: searchAfuRenewed,
          companyRef: filters.companyRef,
          affidavitAr: affidavitAr,
        },
        orderBy,
      },
    });

  return {
    loading,
    error,
    refetch,
    setSearchAfuRenewed,
    setAffidavitAr,
    searchAfuRenewed,
    affidavitAr,
    data: data?.GetTrademarkAffidavitRenewed?.resources ?? [],
    total: data?.GetTrademarkAffidavitRenewed?.count ?? 0,
  };
};

export const useGetAfuData = (data: OsrFilterState) => {
  const afuToRenewTableColumns = useTableColumn(AFU_TO_RENEW_COLUMNS);
  const afuToRenew = useGetAfuToRenew(
    {
      limit: afuToRenewTableColumns.paginationState.pageSize,
      offset:
        (afuToRenewTableColumns.paginationState.current - 1) *
        afuToRenewTableColumns.paginationState.pageSize,
      dueDate: data.dueDate,
      companyRef: data.companyRef,
      countryCode: data.countryCode,
    },
    afuToRenewTableColumns.orderByState,
  );

  const afuRenewedTableColumns = useTableColumn(AFU_RENEWED_COLUMNS);
  const afuRenewed = useGetAfuRenewed(
    {
      limit: afuRenewedTableColumns.paginationState.pageSize,
      offset:
        (afuRenewedTableColumns.paginationState.current - 1) *
        afuRenewedTableColumns.paginationState.pageSize,
      dueDate: data.dueDate,
      companyRef: data.companyRef,
      countryCode: data.countryCode,
    },
    afuRenewedTableColumns.orderByState,
  );

  const proofOfUseToRenewTableColumns = useTableColumn(
    PROOF_OF_USED_TO_RENEW_COLUMNS,
  );
  const proofOfUseToRenew = useGetProofOfUseToRenew(
    {
      limit: proofOfUseToRenewTableColumns.paginationState.pageSize,
      offset:
        (proofOfUseToRenewTableColumns.paginationState.current - 1) *
        proofOfUseToRenewTableColumns.paginationState.pageSize,
      dueDate: data.dueDate,
      companyRef: data.companyRef,
      countryCode: data.countryCode,
    },
    proofOfUseToRenewTableColumns.orderByState,
  );

  const proofOfUseRenewedTableColumns = useTableColumn(
    PROOF_OF_USED_RENEWED_COLUMNS,
  );
  const proofOfUseRenewed = useGetProofOfUseRenewed(
    {
      limit: proofOfUseRenewedTableColumns.paginationState.pageSize,
      offset:
        (proofOfUseRenewedTableColumns.paginationState.current - 1) *
        proofOfUseRenewedTableColumns.paginationState.pageSize,
      dueDate: data.dueDate,
      companyRef: data.companyRef,
      countryCode: data.countryCode,
    },
    proofOfUseRenewedTableColumns.orderByState,
  );

  const { generateExcel } = useExportAfu({
    afuProofOfUseRenewedOrderBy: proofOfUseRenewedTableColumns.orderByState,
    afuProofOfUseToRenewOrderBy: proofOfUseToRenewTableColumns.orderByState,
    afuRenewedOrderBy: afuRenewedTableColumns.orderByState,
    afuToRenewOrderBy: afuToRenewTableColumns.orderByState,
    afuProofOfUseToRenewFilters: {
      ...data,
      search: proofOfUseToRenew.searchProofOfUsedToRenew,
      excludingArgentina: proofOfUseToRenew.excludingArgentina,
    },
    afuProofOfUseRenewedFilters: {
      ...data,
      search: proofOfUseRenewed.searchProofOfUsedRenewed,
    },
    afuToRenewFilters: {
      ...data,
      search: afuToRenew.searchAfuToRenew,
      firstAffidavitEvent: afuToRenew.firstAffidavitEvent,
      lastDeadlineAffidavit: afuToRenew.lastDeadlineAffidavit,
      excludingArgentina: afuToRenew.excludingArgentina,
    },
    afuRenewedFilters: {
      ...data,
      search: afuRenewed.searchAfuRenewed,
      affidavitAr: afuRenewed.affidavitAr,
    },
  });
  return {
    afuToRenew: {
      data: afuToRenew,
      columns: afuToRenewTableColumns,
    },
    afuRenewed: {
      data: afuRenewed,
      columns: afuRenewedTableColumns,
    },
    proofOfUseRenewed: {
      data: proofOfUseRenewed,
      columns: proofOfUseRenewedTableColumns,
    },
    proofOfUseToRenew: {
      data: proofOfUseToRenew,
      columns: proofOfUseToRenewTableColumns,
    },
    generateExcel,
  };
};
