import EventTable from 'business/resources/shared/components/event/eventTable';
import { useGetDesignEvents } from 'business/design/services/design.service';

const DesignEvents = ({ id }: { id: number }) => {
  const { data: events, tableProps: eventTableProps } = useGetDesignEvents(id);

  return (
    <EventTable
      dataSource={events}
      {...eventTableProps}
      searchBarEnabled={false}
      editButtonEnabled={false}
    />
  );
};

export default DesignEvents;
