import { UserWithPrivateInfos } from 'business/user/types/user';
import {
  useGetAllKeywordsQuery,
  useGetArchiveDataQuery,
} from 'generated/graphql';
import { useMemo } from 'react';
import { formatBytes } from 'technical/fileManagement';

export const hasUserArchiveAccess = (user: UserWithPrivateInfos) => {
  return user.privateInfos.archivePageEnabled;
};

export const useGetArchiveSubscriptionInformation = () => {
  const { data, loading, error } = useGetArchiveDataQuery();

  return {
    total:
      data?.archive_archiveDocumentMetadata_aggregate.aggregate?.count ?? 0,
    nbDeletions: data?.archive_deletionRequest_aggregate?.aggregate?.count ?? 0,
    volume: formatBytes(
      data?.archive_archiveDocumentMetadata_aggregate.aggregate?.sum
        ?.fileSize ?? 0,
    ),
    loading,
    error,
  };
};

export const useGetAllKeywords = () => {
  const { data, ...rest } = useGetAllKeywordsQuery();

  const allKeywords: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          data?.archive_documentType.flatMap(({ options }) => options) ?? [],
        ),
      ),
    [data],
  );

  return {
    allKeywords,
    ...rest,
  };
};
