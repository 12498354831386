import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { resourcesToMapData } from 'business/common/services';
import { useResourceBreadcrumbs } from 'business/common/services/breadcrumbs';
import Routes from 'business/router/routes';
import { format } from 'date-fns';
import {
  DownloadTrademarksDto,
  GetTrademarksQueryVariables,
  useDownloadTrademarksLazyQuery,
  useGetTrademarkFamilyBreadcrumbQuery,
  useGetTrademarkFamilyMapQuery,
  useGetTrademarksQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import errorReporting from 'technical/error-reporting';
import { convertAndDownloadFile } from 'technical/fileManagement';
import logger from 'technical/logger';

const useGetTrademarkFamilyInfos = (familyId: string) => {
  const { data } = useGetTrademarkFamilyBreadcrumbQuery({
    variables: { familyId: familyId ?? '' },
    skip: !familyId,
  });
  return data?.family?.[0];
};

const useGetTrademarkFamilyMap = (familyId: string) => {
  const { data, loading } = useGetTrademarkFamilyMapQuery({
    variables: { id: familyId },
  });

  return { dataMap: resourcesToMapData(data?.trademark), loading };
};

export const useGetTrademarks = (params: GetTrademarksQueryVariables) => {
  const { data, error, loading } = useGetTrademarksQuery({
    fetchPolicy: 'no-cache',
    variables: params,
  });
  const { dataMap, loading: mapLoading } = useGetTrademarkFamilyMap(
    params.filters?.familyId ?? '',
  );

  const { getFamilyBreadcrumbs } = useResourceBreadcrumbs({
    type: 'trademark',
    familyRoute: Routes.TrademarkFamily,
    rootRoute: Routes.Trademark,
  });

  const familyInfo = useGetTrademarkFamilyInfos(params.filters?.familyId ?? '');

  return {
    data: data,
    map: {
      data: dataMap,
      loading: mapLoading,
    },
    getFamilyBreadcrumbs,
    familyInfo,
    error,
    loading,
  };
};

export const useDownloadTrademarks = (
  initialColumns: ColumnsType,
  visibleColumns: string[],
  filters: DownloadTrademarksDto['filters'],
  orderBy: DownloadTrademarksDto['orderBy'],
) => {
  const { t } = useTranslation();
  const [downloadTrademarks, { loading }] = useDownloadTrademarksLazyQuery();

  const columns = initialColumns
    .filter((c) => {
      return visibleColumns.includes(c.key?.toString() ?? '');
    })
    .map((c) => ({
      key: c.key?.toString() ?? '',
      value: c.title?.toString() ?? '',
    }));

  const download = async () => {
    try {
      const { data } = await downloadTrademarks({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: columns,
            filters: filters,
            orderBy: orderBy,
          },
        },
      });
      return await convertAndDownloadFile(
        `export_trademarkFamilies_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadTrademarks?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download patent families',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };
  return { download, loading };
};
