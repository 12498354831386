import { Checkbox } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import ArchiveEditionModal from 'business/archive/components/archiveEditionModal';
import ArchiveUploadForm from 'business/archive/components/archiveUploadForm';
import { useGetUserArchivesDocument } from 'business/archive/hooks/useGetUserArchivesDocument';
import {
  hasUserArchiveAccess,
  useGetAllKeywords,
} from 'business/archive/services';
import { UserArchivesDocuments } from 'business/archive/services/types';
import DateRangeTableFilter from 'business/common/components/dateRangeTableFilter';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useGetNbUserArchivesDocumentQuery } from 'generated/graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatBytes } from 'technical/fileManagement';
import useTableColumn from 'technical/table/useTableColumn';
import Flex from 'ui/flex';
import Select from 'ui/select';
import Spacer from 'ui/spacer';
import SearchInput from 'ui/v2/input/searchInput';
import Table from 'ui/v2/table/table';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import getArchiveColumns from 'business/archive/components/constant';

const ArchivePageContainer = () => {
  const { user } = useLoggedInAppContext();
  const { t } = useTranslation();
  const userArchiveAccess = hasUserArchiveAccess(user);

  const [editingArchive, setEditingArchive] = useState<
    UserArchivesDocuments | undefined
  >(undefined);

  const {
    data,
    tableProps,
    refetch,
    filters: { filtersValues, setFilterValue, setFiltersValues },
  } = useGetUserArchivesDocument();

  const columns = getArchiveColumns(refetch, setEditingArchive, t);
  const archiveColumns = useTableColumn(columns);

  const { allKeywords } = useGetAllKeywords();

  const { data: nbArchiveData } = useGetNbUserArchivesDocumentQuery();

  const totalNbFiles =
    Number(nbArchiveData?.getNbUserArchives.totalNbFiles) ?? 0;
  const totalFileSizeGo = formatBytes(
    Number(nbArchiveData?.getNbUserArchives.totalFileSize) ?? 0,
  );

  return (
    <ResourceNotFound resourceExists={userArchiveAccess}>
      <ConnectedPage breadcrumbs={[{ label: t('pages.archive.title') }]}>
        {editingArchive && (
          <ArchiveEditionModal
            archive={editingArchive}
            isOpen={!!editingArchive}
            refetch={refetch}
            onClose={() => setEditingArchive(undefined)}
          />
        )}

        <Flex>
          <h1 className="headerTitle">{t('pages.archive.title')}</h1>
        </Flex>
        <ArchiveUploadForm refetchArchives={refetch} user={user} />
        <Flex>
          <h3>
            {t('archive.information.nbTotalArchiveAndSize', {
              totalNbFiles,
              totalFileSize: totalFileSizeGo,
            })}
          </h3>
        </Flex>
        <Flex>
          <Table
            columns={archiveColumns.tableColumns}
            dataTestId="archiveDocumentTable"
            dataSource={data ?? []}
            onChange={archiveColumns.handlePagination}
            loading={tableProps.loading}
            pagination={{
              current: archiveColumns.paginationState.current,
              pageSize: archiveColumns.paginationState.pageSize,
              total: tableProps.pagination?.total,
            }}
            actions={
              <Spacer size="small" align="center" justify="end">
                <SearchInput
                  value={filtersValues.search}
                  onDebouncedChange={(e) =>
                    setFiltersValues({ ...filtersValues, search: e })
                  }
                  placeholder={t('common.search', { context: 'table' })}
                  style={{ width: '30%' }}
                />

                <EditColumnsButton
                  initialColumns={archiveColumns.filtrableColumns}
                  visibleColumns={archiveColumns.visibleColumns}
                  onChange={archiveColumns.toggleColumnVisibility}
                />

                <Checkbox
                  checked={filtersValues.myArchives}
                  onChange={({ target: { checked: myArchives } }) =>
                    setFilterValue('myArchives', myArchives)
                  }
                >
                  {t('table.common.filters.myArchives')}
                </Checkbox>
                <DateRangeTableFilter
                  filtersValues={filtersValues}
                  setFiltersValues={setFiltersValues}
                />
                <Select<string>
                  options={allKeywords.map((keyword) => ({
                    code: keyword,
                    label: keyword,
                  }))}
                  placeholder={t('table.common.filters.allTypes')}
                  allowClear
                  onClear={() =>
                    setFiltersValues({ ...filtersValues, type: undefined })
                  }
                  onSelect={(type: string | undefined) =>
                    setFilterValue('type', type)
                  }
                  value={filtersValues.type}
                  style={{ width: 230 }}
                />
              </Spacer>
            }
          />
        </Flex>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default ArchivePageContainer;
