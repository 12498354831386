/* eslint-disable import/no-extraneous-dependencies */
import { Layout, Menu } from 'antd';
import { PatentDocument } from 'business/patent/services/types';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useEffect, useState } from 'react';
import { useOfficialDocument } from 'business/patent/services/patentOfficialDocument.service';
import { useTranslation } from 'react-i18next';
import PdfViewer from 'ui/v2/pdf/pdfViewer';

interface OfficialDocumentsProps {
  documents: PatentDocument[];
  loading?: boolean;
}

const OfficialDocuments: React.FC<OfficialDocumentsProps> = ({ documents }) => {
  const { t } = useTranslation();
  const [currentDocument, setCurrentDocument] = useState('');
  const { getOfficialDocumentUrl } = useOfficialDocument();

  useEffect(() => {
    getOfficialDocumentUrl(documents[0]?.id).then((url) => {
      if (url) {
        setCurrentDocument(url);
      }
    });
  }, []);

  return (
    <Layout hasSider style={{ backgroundColor: 'FBFBFB' }}>
      <Layout.Sider
        style={{
          width: 'max-content',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Menu
          color="#FBFBFB"
          mode="vertical"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
          items={documents.map((d) => {
            return {
              label: t('patent.officialDocuments.type', {
                context:
                  d.publicationNumber.includes('US') && d.type === 'B2'
                    ? `${d.type}US`
                    : d.type,
              }),
              type: d.id,
              key: d.id,
            };
          })}
          onClick={({ key }) => {
            getOfficialDocumentUrl(key).then((url) => {
              if (url) {
                setCurrentDocument(url);
              }
            });
          }}
        />
      </Layout.Sider>
      <Layout>
        <Layout.Content>
          {currentDocument ? (
            <PdfViewer key={currentDocument} fileUrl={currentDocument} />
          ) : undefined}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default OfficialDocuments;
