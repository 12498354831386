import { UserOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

import { UserWithPrivateInfos } from 'business/user/types/user';
import Section from 'ui/layout/logoSection';
import styles from './index.module.scss';
import useMenuDropDownItems from 'ui/layout/menu.service';
import Flex from 'ui/flex';
import { useState } from 'react';

interface MenuDropdownProps {
  user: UserWithPrivateInfos;
}

const MenuDropdown = ({ user }: MenuDropdownProps) => {
  const items = useMenuDropDownItems(user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <Dropdown open={dropdownOpen} menu={{ items }}>
      <Flex column justify="center" className={styles.menuDropdownButton}>
        <Section
          onClick={() => setDropdownOpen(!dropdownOpen)}
          label={`${user.firstName} ${user.lastName}`}
          labelClassName={styles.userName}
          icon={
            <UserOutlined
              size={20}
              data-test-id="menu_icon"
              className={styles.userIcon}
            />
          }
        />
      </Flex>
    </Dropdown>
  );
};

export default MenuDropdown;
