import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { OpsDocument } from 'business/admin/services/types';
import StatusBadge from 'business/common/components/statusBadge';
import { generatePathByAssetType } from 'business/common/services';
import { useOfficialDocument } from 'business/patent/services/patentOfficialDocument.service';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getCountryColumn } from 'technical/countriesTranslation/constants';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';

type OpsDocumentTableProps = Omit<TableProps<OpsDocument>, 'defaultColumns'>;

const defaultColumns = (
  t: TFunction,
  openOfficalDocument: (id: string) => Promise<void>,
): ColumnGenerationData<OpsDocument> => [
  {
    key: 'title',
    width: 300,
    alwaysVisible: true,
    render: (title: string, { patentId }: OpsDocument) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType('patent', patentId)}
      >
        {title}
      </Link>
    ),
  },
  { key: 'documentType', alwaysVisible: true },
  { ...getCountryColumn(), alwaysVisible: true },
  { key: 'bdlRef', alwaysVisible: true, ellipsis: true },
  { key: 'clientRef', alwaysVisible: true, ellipsis: true },
  { key: 'holder' },

  { key: 'depositNumber', checked: false, ellipsis: true },
  { key: 'depositDate', ellipsis: true },
  { key: 'issueNumber', ellipsis: true },
  { key: 'issueDate', ellipsis: true },
  { key: 'publicationNumber', checked: false, ellipsis: true },
  { key: 'publicationDate', ellipsis: true },
  { key: 'lastFetched', ellipsis: true },
  {
    key: 'status',
    width: 100,
    render: (status: string) => <StatusBadge status={status} />,
  },
  {
    key: 'id',
    alwaysVisible: true,
    title: '',
    width: 80,
    sorter: false,
    render: (id) => (
      <Tooltip title={t('common.openFile')}>
        <Button
          onClick={() => openOfficalDocument(id)}
          type="primary"
          icon={<FilePdfOutlined />}
        ></Button>
      </Tooltip>
    ),
  },
];

const OpsDocumentsTable: React.FC<OpsDocumentTableProps> = (props) => {
  const { t } = useTranslation();
  const { openOfficalDocument } = useOfficialDocument();

  return (
    <Table
      id="opsDocumentsTable"
      defaultColumns={defaultColumns(t, openOfficalDocument)}
      {...props}
    />
  );
};

export default OpsDocumentsTable;
