import { Tabs } from 'antd';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useParams } from 'react-router';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import PatentInformation from 'business/patent/components/patentInformation';
import Timeline from 'ui/timeline';
import PatentNote from 'business/patent/components/patentNote';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import EventTab from 'business/patent/components/eventTab';
import OfficialDocuments from 'business/patent/components/officialDocuments';
import Claims from 'business/patent/components/claims';
import {
  useGetPatent,
  useGetPatentDrawingsFromDocuments,
  useGetPatentEpoDocument,
  usePatentBreadcrumbs,
} from 'business/patent/services/patent.service';
import { useTranslation } from 'react-i18next';
import ResourceDocuments from 'business/resources/shared/components/documents/resourceDocuments';
import { useGetOfficialLinks } from 'business/resources/shared/services/links/useGetOfficialLink';
import { Patent } from 'business/patent/services/types';
import { getPatentOfficialLink } from 'business/common/services/officialRegister/getPatentOfficialLink';
import ContactBdlByMailButton from 'business/common/components/contactBdlByMailButton';
import OfficalLinkButton from 'business/resources/shared/components/officialLinkButton';
import { isDefined } from 'technical/validation';
import Carousel from 'ui/carousel';

const PatentDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const patentId = Number(id);
  const { patent, timeline } = useGetPatent(patentId);

  const { getResourceBreadcrumbs } = usePatentBreadcrumbs();
  const { patentDocuments, loading: patentDocumentLoading } =
    useGetPatentEpoDocument({
      id: patentId,
      publicationDate: patent?.publicationDate,
    });

  const { images, loading: imgLoading } = useGetPatentDrawingsFromDocuments(
    patentDocuments,
    patentDocumentLoading,
  );

  const links = useGetOfficialLinks<Patent>(getPatentOfficialLink, patent);
  return (
    <ConnectedPage
      translationKey="patentDetails"
      breadcrumbs={getResourceBreadcrumbs(patent)}
    >
      <Spacer direction="vertical">
        <Spacer>
          <HeaderTitles
            subtitle={patent?.title ?? ''}
            title={patent?.country?.labelFr ?? ''}
          />
          {patent ? (
            <ContactBdlByMailButton resource={patent} resourceType="patent" />
          ) : undefined}
        </Spacer>

        {/* CONTENT SECTION  */}
        <Spacer size="small">
          <Spacer style={{ width: '85%' }} direction="vertical">
            <Timeline events={timeline} />
            <Spacer size="small" style={{ width: '100%' }}>
              <Tabs
                style={{ width: '100%' }}
                type="card"
                items={[
                  {
                    key: 'generalInformation',
                    label: t('common.informations'),
                    children: (
                      <PatentInformation
                        patent={patent}
                        images={images}
                        isLoading={imgLoading}
                        officialRegisterLink={links?.[0]}
                      />
                    ),
                  },
                  {
                    key: 'events',
                    label: t('pages.event.title'),
                    children: (
                      <div style={{ height: '100%' }}>
                        <EventTab id={patentId} />
                      </div>
                    ),
                  },
                  {
                    key: 'documents',
                    label: t('pages.document.title'),
                    children: (
                      <ResourceDocuments
                        resource="patent"
                        resourceId={patentId}
                      />
                    ),
                  },
                  {
                    key: 'register',
                    label: t('pages.patent.sections.officialDocuments.header'),
                    children: (
                      <div>
                        <OfficialDocuments
                          documents={patentDocuments}
                          loading={patentDocumentLoading}
                        />
                      </div>
                    ),
                  },
                  {
                    key: 'claims',
                    label: t('pages.patent.sections.claims.claims'),
                    children: (
                      <div style={{ height: '500px', overflowY: 'scroll' }}>
                        <Claims patentId={patentId} />
                      </div>
                    ),
                  },
                ]}
              />
              {/* SECTION IMAGE */}
              <div
                style={{
                  background: '#FFFFFF',
                  width: '30%',
                  height: '100%',
                  borderRadius: '20px',
                  boxShadow: '0px 1px 4.6px 0px #00000038',
                }}
              >
                <Carousel
                  urls={images.filter(isDefined) ?? []}
                  loading={imgLoading}
                />
                {links?.[0] ? (
                  <OfficalLinkButton link={links?.[0]} />
                ) : undefined}
              </div>
            </Spacer>
          </Spacer>

          <Flex className="noteSection" column style={{ height: '733px' }}>
            <PatentNote patentId={patentId} />
          </Flex>
        </Spacer>
      </Spacer>
    </ConnectedPage>
  );
};

export default PatentDetailPage;
