import { Button as AntdButton } from 'antd';
import style from './index.module.scss';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button';

export interface ButtonProps extends AntdButtonProps {
  eventName?: string;
  logEventData?: object;
}

const Button = ({ onClick: originalOnClick, ...props }: ButtonProps) => {
  return (
    <AntdButton
      {...props}
      className={
        props.className
          ? props.className
          : props.type === 'primary'
          ? style.primaryButton
          : style.secondaryButton
      }
      onClick={(...args) => {
        if (originalOnClick) {
          originalOnClick(...args);
        }
      }}
    />
  );
};

export default Button;
