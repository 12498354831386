import { TableProps as AntdTableProps, Button, Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { TabsFamily } from 'business/common/services/types';
import Routes from 'business/router/routes';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AllTableFilter } from 'technical/filters/types';
import { useDebounce } from 'technical/hooks/useDebounce';
import Spacer from 'ui/spacer';
import classes from 'ui/table/index.module.scss';
import ColumnsEditionPopOver from './columnsEditionPopOver';
import SearchInput from 'ui/v2/input/searchInput';

interface TableActionsProps<RecordType>
  extends Omit<AntdTableProps<RecordType>, 'columns' | 'pagination'> {
  customFilters?: React.ReactNode;
  customAction?: React.ReactNode;
  editButtonEnabled?: boolean;
  setFilterValue: <Key extends keyof AllTableFilter<RecordType>>(
    keyFilter: Key,
    value: AllTableFilter<RecordType>[Key],
  ) => void;
  onExport?: () => Promise<void>;
  onClickEffectiveFamilies?: (checked: boolean) => void;
  filtersValues: AllTableFilter<RecordType>;
  showEffectiveCheckbox?: boolean;
  setColumnsVisibility: (visibledColumns: CheckboxValueType[]) => void;
  resetTable?: () => void;
  confirmationReceipt?: {
    value: boolean;
    onChange?: (value: boolean) => void;
  };
  family?: TabsFamily;
  showOwnCheckbox?: boolean;
  showDisplayAnnuitiesCheckbox?: boolean;
  showDisplayAFUCheckbox?: boolean;
  searchBarEnabled?: boolean;
  resetButtonEnabled?: boolean;
  loadingExport: boolean;
  columnsEditionItems: {
    label: string;
    value: boolean;
    key: string;
  }[];
}

const TableActions = <RecordType extends object>({
  setColumnsVisibility,
  columnsEditionItems,
  onExport,
  searchBarEnabled,
  resetTable,
  customFilters,
  showOwnCheckbox = false,
  showDisplayAFUCheckbox,
  showDisplayAnnuitiesCheckbox,
  family,
  confirmationReceipt,
  editButtonEnabled = true,
  showEffectiveCheckbox = false,
  setFilterValue,
  customAction,
  resetButtonEnabled = true,
  filtersValues,
  loadingExport,
}: TableActionsProps<RecordType>) => {
  const { t } = useTranslation();

  const { value, setValue, debouncedValue } = useDebounce({
    defaultValue: filtersValues.search ?? '',
  });

  useEffect(() => {
    setFilterValue('search', debouncedValue);
  }, [debouncedValue]);

  return (
    <Spacer
      className="flex1"
      fullWidth={false}
      size="small"
      align="center"
      justify="end"
    >
      <>
        {searchBarEnabled && (
          <SearchInput
            style={{ width: '30%' }}
            onDebouncedChange={(e) => setValue(e)}
            value={value ?? ''}
            placeholder={t('common.search')}
          />
        )}

        {customFilters}

        {family && (
          <Link
            to={{
              pathname: Routes.Search,
              search: `?family=${family}&filtersOpen=true`,
            }}
          >
            <Button
              data-test-id="filterButton"
              className={classes.filterButton}
              type="primary"
            >
              {t('table.common.advancedSearch')}
            </Button>
          </Link>
        )}

        {onExport && (
          <Button
            loading={loadingExport}
            data-test-id="exportButton"
            type="primary"
            onClick={onExport}
          >
            {t('table.common.exportButton')}
          </Button>
        )}
        {editButtonEnabled && (
          <ColumnsEditionPopOver
            columnsEditionItems={columnsEditionItems}
            setColumnsVisibility={setColumnsVisibility}
            resetButtonEnabled={resetButtonEnabled}
            resetTable={resetTable}
          />
        )}

        {showEffectiveCheckbox && (
          <Checkbox
            checked={filtersValues.isEffective}
            onChange={(e) => setFilterValue('isEffective', e.target.checked)}
          >
            {t('table.common.effective')}
          </Checkbox>
        )}
      </>
      {customAction}

      {showOwnCheckbox && (
        <Checkbox
          checked={filtersValues.isOwn}
          onChange={(e) => setFilterValue('isOwn', e.target.checked)}
        >
          {t('table.common.own')}
        </Checkbox>
      )}

      {confirmationReceipt && confirmationReceipt.onChange && (
        <Checkbox
          checked={confirmationReceipt.value}
          onChange={(e) => {
            confirmationReceipt.onChange?.(e.target.checked);
          }}
        >
          {t('table.common.confirmationReceipt')}
        </Checkbox>
      )}

      {showDisplayAnnuitiesCheckbox && (
        <Checkbox
          checked={filtersValues.displayAnnuities}
          onChange={(e) => setFilterValue('displayAnnuities', e.target.checked)}
        >
          {t('table.common.showAnnuities')}
        </Checkbox>
      )}

      {showDisplayAFUCheckbox && (
        <Checkbox
          checked={filtersValues.displayAFU}
          onChange={(e) => setFilterValue('displayAFU', e.target.checked)}
        >
          {t('table.common.displayAFU')}
        </Checkbox>
      )}
    </Spacer>
  );
};

export default TableActions;
