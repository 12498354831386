import { Layout } from 'antd';
import { useAppContext } from 'business/AppBootstrapper';
import AppLogo from 'ui/appLogo';
import styles from './index.module.scss';
import Flex from 'ui/flex';
import MenuDropdown from 'ui/layout/menuDropdown';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDebounce } from 'technical/hooks/useDebounce';
import Routes from 'business/router/routes';
import AutoCompleteInput from 'ui/v2/input/autoCompleteInput';

const { Content } = Layout;

const Header = (props: { isConnected: boolean }) => {
  const { user } = useAppContext();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [inputValue, setInputValue] = useState(queryParams.get('search') || '');

  const {
    debouncedValue,
    setValue,
    value: dValue,
  } = useDebounce({
    defaultValue: inputValue,
    delay: 150,
  });

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    setInputValue(debouncedValue); // Utilise debouncedValue
  }, [debouncedValue]);

  const onPressEnter = () => {
    const params = new URLSearchParams();
    if (inputValue) {
      params.set('search', inputValue);
    }
    const searchParameter = params.toString();
    if (history.location.pathname !== Routes.Search && searchParameter) {
      history.push(`${Routes.Search}?${searchParameter}`);
    } else {
      history.push({ search: searchParameter });
    }
  };

  return (
    <Layout.Header className={styles.header} style={{ paddingInline: 'Opx' }}>
      <Content className={styles.headerContent}>
        <Flex
          justify="space-between"
          alignItems="center"
          style={{ marginTop: '20px' }}
        >
          <AppLogo height="41px" clickable />
          {props.isConnected && user ? (
            <>
              {history.location.pathname !== Routes.Home ? (
                <AutoCompleteInput
                  style={{ width: '50%' }}
                  options={[]}
                  value={dValue}
                  placeholder={t('common.search', { context: 'advanced' })}
                  onChange={(e) => setValue(e.target.value)}
                  onPressEnter={() => onPressEnter()}
                />
              ) : undefined}

              <MenuDropdown user={user} />
            </>
          ) : (
            <div></div>
          )}
        </Flex>
      </Content>
    </Layout.Header>
  );
};

export default Header;
