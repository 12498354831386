import { useForm } from 'antd/es/form/Form';
import {
  useCreateResourceCommentMutation,
  useDeleteResourceCommentByIdMutation,
  useGetResourceCommentQuery,
  useUpdateResourceCommentMutation,
} from 'generated/graphql';
import { useState } from 'react';
import logger from 'technical/logger';

export const useGetDomainNote = (domainId: number) => {
  const { data, refetch } = useGetResourceCommentQuery({
    fetchPolicy: 'no-cache',
    variables: {
      resourceId: domainId,
      resourceType: 'domain',
    },
  });
  return {
    comments: data?.getResourceComments ?? [],
    refetch,
  };
};

export const useDomainNoteActions = (domainId: number) => {
  const [createForm] = useForm<{ comment: string }>();
  const [updateForm] = useForm();
  const [createComment] = useCreateResourceCommentMutation();
  const [updateComment] = useUpdateResourceCommentMutation();
  const [deleteComment] = useDeleteResourceCommentByIdMutation();

  const [commentDeleteId, setCommentDeleteId] = useState<string | null>(null);
  const [commentUpdateData, setCommentUpdateData] = useState<{
    id: string;
    comment: string;
  } | null>(null);

  const onDelete = async (id: string) => {
    try {
      await deleteComment({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            id,
          },
        },
      });
    } catch (err) {
      logger.error({
        message: 'Error deleting comment',
        commentId: id,
        err,
      });
    }
  };

  const onUpdate = async (data: { id: string; comment: string }) => {
    try {
      await updateComment({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            resourceId: domainId,
            resourceType: 'domain',
            id: data.id,
            comment: data.comment,
          },
        },
      });
    } catch (err) {
      logger.error({
        message: 'Error updating comment ',
        err,
        commentId: data.id,
      });
    }
  };

  const onCreate = async (data: string) => {
    try {
      return await createComment({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            comment: data,
            resourceId: domainId,
            resourceType: 'domain',
          },
        },
      });
    } catch (err) {
      logger.error({ message: 'Error ' });
    }
  };

  return {
    commentDeleteId,
    setCommentDeleteId,
    commentUpdateData,
    setCommentUpdateData,
    onCreate,
    onUpdate,
    onDelete,
    createForm,
    updateForm,
  };
};
