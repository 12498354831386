import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { resourcesToMapData } from 'business/common/services';
import { format } from 'date-fns';
import {
  DownloadDesignByFamilyIdDto,
  useDownloadDesignByFamilyIdLazyQuery,
  useGetDesignFamilyBreadcrumbQuery,
  useGetDesignFamilyMapQuery,
  useGetdesignsQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import errorReporting from 'technical/error-reporting';
import { convertAndDownloadFile } from 'technical/fileManagement';

import logger from 'technical/logger';

export const useGetDesigns = (input: {
  id?: string;
  search?: string;
  limit: number;
  offset: number;
  orderBy?: Record<string, 'asc' | 'desc'> | null;
}) => {
  const { data, error, loading } = useGetdesignsQuery({
    fetchPolicy: 'no-cache',
    skip: !input.id,
    variables: {
      input: {
        limit: input.limit,
        offset: input.offset,
        where: {
          id: input.id,
          search: input.search,
        },
        orderBy: input.orderBy,
      },
    },
  });

  return {
    data: data?.getDesigns?.resources ?? [],
    total: data?.getDesigns?.count ?? 0,
    error,
    loading,
  };
};

export const useGetDesignFamilyInfos = (familyId: string) => {
  const { data } = useGetDesignFamilyBreadcrumbQuery({
    variables: { familyId: familyId ?? '' },
    skip: !familyId,
  });
  return data?.family?.[0] ?? null;
};

export const useGetDesignFamilyMap = (familyId: string) => {
  const { data, loading } = useGetDesignFamilyMapQuery({
    variables: { id: familyId },
  });
  return { dataMap: resourcesToMapData(data?.design), loading };
};

export const useDownloadDesignByFamilyId = (
  initialColumns: ColumnsType,
  visibleColumns: string[],
  filters: DownloadDesignByFamilyIdDto['filters'],
) => {
  const { t } = useTranslation();
  const [downloadDesignByFamilyId, { loading }] =
    useDownloadDesignByFamilyIdLazyQuery();

  const columns = initialColumns
    .filter((c) => {
      return visibleColumns.includes(c.key?.toString() ?? '');
    })
    .map((c) => ({
      key: c.key?.toString() ?? '',
      value: c.title?.toString() ?? '',
    }));

  const download = async () => {
    try {
      const { data } = await downloadDesignByFamilyId({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: columns,
            filters: filters,
          },
        },
      });
      return await convertAndDownloadFile(
        `export_designFamilies_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadDesignByFamilyId?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download patent families',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };
  return { download, loading };
};
