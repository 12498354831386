import {
  isResourceNotEffective,
  resourcesToMapData,
} from 'business/common/services';
import { PatentMap } from 'business/patent/services/types';
import {
  DownloadPatentsDto,
  useDownloadPatentsLazyQuery,
  useGetPatentFamilyBreadcrumbQuery,
  useGetPatentFamilyMapQuery,
  useGetPatentsQuery,
} from 'generated/graphql';
import { isDefined } from 'technical/validation';
import { MapStatus } from 'ui/map/types';
import {
  isAnnuityNotHandledByBdl,
  usePatentBreadcrumbs,
} from './patent.service';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { convertAndDownloadFile } from 'technical/fileManagement';
import { format } from 'date-fns';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { notification } from 'antd';
import { StatusFilter } from 'business/common/services/types';

const patentTestsToMapStatus: {
  cond: (patent: PatentMap) => boolean;
  status: MapStatus;
}[] = [
  { cond: isAnnuityNotHandledByBdl, status: MapStatus.MandatedOut },
  { cond: isResourceNotEffective, status: MapStatus.NotEffective },
  {
    cond: ({ issueNumber }) => isDefined(issueNumber),
    status: MapStatus.Valid,
  },
  {
    cond: ({ publicationDate }) => isDefined(publicationDate),
    status: MapStatus.Published,
  },
  {
    cond: ({ depositDate }) => isDefined(depositDate),
    status: MapStatus.Deposited,
  },
];
export const patentMapToMapStatus = (patent: PatentMap): MapStatus => {
  for (let i = 0; i < patentTestsToMapStatus.length; i += 1) {
    const { cond, status } = patentTestsToMapStatus[i];
    if (cond(patent)) {
      return status;
    }
  }
  return MapStatus.PlannedDeposit;
};

export const useGetPatentFamilyMap = (familyId: string) => {
  const { data, loading } = useGetPatentFamilyMapQuery({
    variables: { id: familyId },
  });

  return {
    dataMap: resourcesToMapData(data?.patent, patentMapToMapStatus),
    loading,
  };
};

export const useGetPatentFamilyInfos = (familyId: string) => {
  const { data } = useGetPatentFamilyBreadcrumbQuery({
    variables: { familyId: familyId ?? '' },
    skip: !familyId,
  });
  return data?.family?.[0];
};

export const useGetPatentFamily = ({
  limit,
  offset,
  orderBy,
  search,
  isEffective,
  familyId,
}: {
  familyId: string;
  limit: number;
  offset: number;
  search?: string;
  isEffective?: boolean;
  orderBy?: Record<string, 'asc' | 'desc'> | null;
}) => {
  const { dataMap, loading: mapLoading } = useGetPatentFamilyMap(familyId);
  const familyInfo = useGetPatentFamilyInfos(familyId);
  const { getFamilyBreadcrumbs } = usePatentBreadcrumbs();

  const { data, error, loading } = useGetPatentsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit,
      offset,
      orderBy,
      filters: {
        search: search,
        status: isEffective
          ? [StatusFilter.Effective]
          : [StatusFilter.NotEffective],
        familyId: familyId,
      },
    },
  });

  return {
    data: data?.getPatents?.resources ?? [],
    total: data?.getPatents?.count ?? 0,
    map: {
      data: dataMap,
      loading: mapLoading,
    },
    error,
    loading,
    familyInfo,
    getFamilyBreadcrumbs,
  };
};

export const useDownloadPatentFamily = (
  initialColumns: ColumnsType,
  visibleColumns: string[],
  filters: DownloadPatentsDto['filters'],
) => {
  const { t } = useTranslation();
  const [downloadPatents, { loading }] = useDownloadPatentsLazyQuery();

  const columns = initialColumns
    .filter((c) => {
      return visibleColumns.includes(c.key?.toString() ?? '');
    })
    .map((c) => ({
      key: c.key?.toString() ?? '',
      value: c.title?.toString() ?? '',
    }));

  const download = async () => {
    try {
      const { data } = await downloadPatents({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: columns,
            filters: filters,
          },
        },
      });
      return await convertAndDownloadFile(
        `export_patentFamilies_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadPatents?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download patent families',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };
  return { download, loading };
};
