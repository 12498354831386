import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import DesignFamiliesTable from 'business/design/components/designFamiliesTable';
import Spacer from 'ui/spacer';
import { useTranslation } from 'react-i18next';

const DesignFamiliesPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ConnectedPage translationKey="designs">
      <Spacer direction="vertical" size="small" style={{ marginTop: '35px' }}>
        <h1 className="headerTitle">{t('pages.header.designFamilies')}</h1>
        <DesignFamiliesTable />
      </Spacer>
    </ConnectedPage>
  );
};

export default DesignFamiliesPage;
