import { Image, Tooltip } from 'antd';
import StatusBadge from 'business/common/components/statusBadge';
import { StatusType, generatePathByAssetType } from 'business/common/services';
import { isAnnuityNotHandledByBdl } from 'business/patent/services/patent.service';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { getCountryKey } from 'technical/countriesTranslation/constants';
import { formatDate } from 'technical/date';
import sanitizeStringList from 'technical/format';
import {
  FAMILIES_TABLE_IMAGE_CELL_WIDTH,
  FAMILIES_TABLE_IMAGE_STYLE,
} from 'ui/imageDisplay/constants';
import Loader from 'ui/loader';

export const searchColumns = (t: TFunction) => ({
  patent: [
    {
      title: t('table.common.column.status'),
      dataIndex: 'status',
      key: 'status',
      width: '70px',
      render: (_: any, patent: any) => (
        <StatusBadge
          status={
            isAnnuityNotHandledByBdl(patent)
              ? StatusType.NotHandledByBdl
              : patent.status
          }
        />
      ),
      sorter: false,
    },
    {
      title: t('table.common.column.holder'),
      dataIndex: 'holder',
      key: 'holder',
      sorter: true,
    },
    {
      title: t('table.common.column.effectiveHolder'),
      dataIndex: 'effectiveHolder',
      key: 'effectiveHolder',
      sorter: true,
    },
    {
      title: t('table.common.column.title'),
      dataIndex: 'title',
      width: '200px',
      key: 'title',
      sorter: true,
      render: (_: any, patent: any) => {
        return (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={generatePathByAssetType('patent', patent.id)}
          >
            {patent.title}
          </Link>
        );
      },
    },
    {
      title: t('table.common.column.countries'),
      sorter: true,
      dataIndex: 'countryFr',
      key: 'countryFr',
      render: (_: any, data: any) => {
        return (
          <Tooltip title={data[getCountryKey()]}>
            {data[getCountryKey()]}
          </Tooltip>
        );
      },
    },
    {
      title: t('table.common.column.depositNumber'),
      dataIndex: 'depositNumber',
      key: 'depositNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.depositDate'),
      dataIndex: 'depositDate',
      key: 'depositDate',
      render: (_: any, data: any) => {
        if (data.depositDate) {
          return formatDate(new Date(data.depositDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.publicationNumber'),
      dataIndex: 'publicationNumber',
      key: 'publicationNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.publicationDate'),
      dataIndex: 'publicationDate',
      key: 'publicationDate',
      render: (_: any, data: any) => {
        if (data.publicationDate) {
          return formatDate(new Date(data.publicationDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.issueNumber'),
      dataIndex: 'issueNumber',
      key: 'issueNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.issueDate'),
      dataIndex: 'issueDate',
      key: 'issueDate',
      render: (_: any, data: any) => {
        if (data.issueDate) {
          return formatDate(new Date(data.issueDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.bdlRef'),
      dataIndex: 'bdlRef',
      key: 'bdlRef',
      sorter: true,
    },
    {
      title: t('table.common.column.misRef'),
      dataIndex: 'misRef',
      key: 'misRef',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.clientRef'),
      dataIndex: 'clientRef',
      key: 'clientRef',
      sorter: true,
    },
    {
      title: t('table.common.column.clientRef2'),
      dataIndex: 'clientRef2',
      key: 'clientRef2',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.bdlOfficer'),
      dataIndex: 'bdlOfficer',
      key: 'bdlOfficer',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.clientOfficer'),
      dataIndex: 'clientOfficer',
      key: 'clientOfficer',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.inventors'),
      dataIndex: 'inventors',
      key: 'inventors',
      render: (_: any, data: any) => sanitizeStringList(data?.inventors),
      sorter: true,
      hidden: true,
    },
  ],
  design: [
    {
      title: t('table.common.column.status'),
      dataIndex: 'status',
      key: 'status',
      width: '70px',
      render: (_: any, patent: any) => (
        <StatusBadge
          status={
            isAnnuityNotHandledByBdl(patent)
              ? StatusType.NotHandledByBdl
              : patent.status
          }
        />
      ),
      sorter: false,
    },
    {
      title: t('table.common.column.holder'),
      dataIndex: 'holder',
      key: 'holder',
      sorter: true,
    },
    {
      title: t('table.common.column.effectiveHolder'),
      dataIndex: 'effectiveHolder',
      key: 'effectiveHolder',
      sorter: true,
    },
    {
      title: t('table.common.column.title'),
      dataIndex: 'title',
      width: '200px',
      key: 'title',
      sorter: true,
      render: (_: any, patent: any) => {
        return (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={generatePathByAssetType('patent', patent.id)}
          >
            {patent.title}
          </Link>
        );
      },
    },
    {
      title: t('table.common.column.image'),
      key: 'image',
      sorter: false,
      dataIndex: 'holder',
      width: FAMILIES_TABLE_IMAGE_CELL_WIDTH,
      render: (_: any, data: any) => {
        if (data.image === 'loading') {
          return <Loader />;
        }
        if (data.image) {
          return (
            <Image src={data.image} style={{ ...FAMILIES_TABLE_IMAGE_STYLE }} />
          );
        }
      },
    },

    {
      title: t('table.common.column.countries'),
      sorter: true,
      dataIndex: 'countriesFr',
      key: 'countriesFr',
      render: (_: any, data: any) => {
        return (
          <Tooltip title={data[getCountryKey()]}>
            {data[getCountryKey()]}
          </Tooltip>
        );
      },
    },
    {
      title: t('table.common.column.depositNumber'),
      dataIndex: 'depositNumber',
      key: 'depositNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.depositDate'),
      dataIndex: 'depositDate',
      key: 'depositDate',
      render: (_: any, data: any) => {
        if (data.depositDate) {
          return formatDate(new Date(data.depositDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.registrationNumber'),
      dataIndex: 'registrationNumber',
      key: 'registrationNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.registrationDate'),
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      render: (_: any, data: any) => {
        if (data.registrationDate) {
          return formatDate(new Date(data.registrationDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.bdlRef'),
      dataIndex: 'bdlRef',
      key: 'bdlRef',
      sorter: true,
    },
    {
      title: t('table.common.column.misRef'),
      dataIndex: 'misRef',
      key: 'misRef',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.clientRef'),
      dataIndex: 'clientRef',
      key: 'clientRef',
      sorter: true,
    },
    {
      title: t('table.common.column.clientRef2'),
      dataIndex: 'clientRef2',
      key: 'clientRef2',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.bdlOfficer'),
      dataIndex: 'bdlOfficer',
      key: 'bdlOfficer',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.clientOfficer'),
      dataIndex: 'clientOfficer',
      key: 'clientOfficer',
      sorter: true,
      hidden: true,
    },
  ],
  trademark: [
    {
      title: t('table.common.column.status'),
      dataIndex: 'status',
      key: 'status',
      width: '70px',
      render: (_: any, patent: any) => (
        <StatusBadge
          status={
            isAnnuityNotHandledByBdl(patent)
              ? StatusType.NotHandledByBdl
              : patent.status
          }
        />
      ),
      sorter: false,
    },
    {
      title: t('table.common.column.holder'),
      dataIndex: 'holder',
      key: 'holder',
      sorter: true,
    },
    {
      title: t('table.common.column.effectiveHolder'),
      dataIndex: 'effectiveHolder',
      key: 'effectiveHolder',
      sorter: true,
    },
    {
      title: t('table.common.column.title'),
      dataIndex: 'title',
      width: '200px',
      key: 'title',
      sorter: true,
      render: (_: any, patent: any) => {
        return (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={generatePathByAssetType('patent', patent.id)}
          >
            {patent.title}
          </Link>
        );
      },
    },
    {
      title: t('table.common.column.image'),
      key: 'image',
      sorter: false,
      dataIndex: 'holder',
      width: FAMILIES_TABLE_IMAGE_CELL_WIDTH,
      render: (_: any, data: any) => {
        if (data.image === 'loading') {
          return <Loader />;
        }
        if (data.image) {
          return (
            <Image src={data.image} style={{ ...FAMILIES_TABLE_IMAGE_STYLE }} />
          );
        }
      },
    },

    {
      title: t('table.common.column.countries'),
      sorter: true,
      dataIndex: 'countryFr',
      key: 'countryFr',
      render: (_: any, data: any) => {
        return (
          <Tooltip title={data[getCountryKey()]}>
            {data[getCountryKey()]}
          </Tooltip>
        );
      },
    },
    {
      title: t('table.common.column.depositNumber'),
      dataIndex: 'depositNumber',
      key: 'depositNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.depositDate'),
      dataIndex: 'depositDate',
      key: 'depositDate',
      render: (_: any, data: any) => {
        if (data.depositDate) {
          return formatDate(new Date(data.depositDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.registrationNumber'),
      dataIndex: 'registrationNumber',
      key: 'registrationNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.registrationDate'),
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      render: (_: any, data: any) => {
        if (data.registrationDate) {
          return formatDate(new Date(data.registrationDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.bdlRef'),
      dataIndex: 'bdlRef',
      key: 'bdlRef',
      sorter: true,
    },
    {
      title: t('table.common.column.misRef'),
      dataIndex: 'misRef',
      key: 'misRef',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.clientRef'),
      dataIndex: 'clientRef',
      key: 'clientRef',
      sorter: true,
    },
    {
      title: t('table.common.column.clientRef2'),
      dataIndex: 'clientRef2',
      key: 'clientRef2',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.bdlOfficer'),
      dataIndex: 'bdlOfficer',
      key: 'bdlOfficer',
      sorter: true,
      hidden: true,
    },
    {
      title: t('table.common.column.clientOfficer'),
      dataIndex: 'clientOfficer',
      key: 'clientOfficer',
      sorter: true,
      hidden: true,
    },
  ],
});

export const test = '';
