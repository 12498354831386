import {
  InfoCircleOutlined,
  PaperClipOutlined,
  TeamOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { Card } from 'antd';
import Status from 'business/common/components/status';
import OfficalLinkButton from 'business/resources/shared/components/officialLinkButton';
import { GetDesignQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Carousel from 'ui/carousel';
import Flex from 'ui/flex';
import InfoSection from 'ui/infoSection/infoSection';

interface IDesignInformation {
  design: GetDesignQuery['design_by_pk'];
  images: string[];
  isLoading: boolean;
  officialRegisterLink?: string;
}

const DesignInformation = ({
  design,
  images,
  isLoading,
  officialRegisterLink,
}: IDesignInformation) => {
  const { t } = useTranslation();
  return (
    <Flex
      style={
        design?.adjournedPublication
          ? {
              padding: '10px',
              position: 'relative',
            }
          : {}
      }
    >
      {design?.adjournedPublication && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'red',
            color: 'white',
            padding: '4px 8px',
            fontWeight: 'bold',
            borderTopLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            zIndex: 1,
          }}
        >
          {t('table.common.column.adjournedPublication')}
        </div>
      )}
      <Card className="caseDetailCard">
        {/* Colonne des informations */}
        <InfoSection
          icon={<InfoCircleOutlined />}
          items={[
            {
              label: t('table.common.column.status').toUpperCase(),
              value: <Status status={design?.status ?? ''} />,
            },
            {
              label: t('table.common.column.country').toUpperCase(),
              value: design?.country?.labelFr,
            },
            {
              label: t('table.common.column.type').toUpperCase(),
              value: design?.protection?.protectionLabel,
            },
          ]}
        />

        <InfoSection
          icon={<UsergroupDeleteOutlined />}
          items={[
            {
              label: t('table.common.column.holder').toUpperCase(),
              value: design?.holder,
            },
            {
              label: t('table.common.column.effectiveHolder').toUpperCase(),
              value: design?.effectiveHolder,
            },
          ]}
        />
        <InfoSection
          icon={<PaperClipOutlined />}
          items={[
            {
              label: t('table.common.column.bdlRef').toUpperCase(),
              value: design?.bdlRef,
            },
            {
              label: t('table.common.column.clientRef').toUpperCase(),
              value: design?.clientRef,
            },
            {
              label: t('table.common.column.clientRef2').toUpperCase(),
              value: design?.clientRef2,
            },
          ]}
        />

        <InfoSection
          icon={<TeamOutlined />}
          items={[
            {
              label: t('table.common.column.clientOfficer').toUpperCase(),
              value: design?.clientOfficer,
            },
            {
              label: t('table.common.column.bdlOfficerName').toUpperCase(),
              value: design?.bdlOfficer?.fullName,
            },
            {
              label: t('table.common.column.bdlSecretaryName').toUpperCase(),
              value: design?.bdlSecretary?.fullName,
            },
          ]}
        />
      </Card>

      {/* SECTION IMAGE */}
      <div
        style={{
          background: '#FFFFFF',
          width: '30%',
          height: '100%',
          borderRadius: '20px',
          boxShadow: '0px 1px 4.6px 0px #00000038',
        }}
      >
        <Carousel urls={images} loading={isLoading} />
        {officialRegisterLink ? (
          <OfficalLinkButton link={officialRegisterLink} />
        ) : undefined}
      </div>
    </Flex>
  );
};

export default DesignInformation;
