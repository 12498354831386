import ConnectedPage from 'business/user/helpers/connectedPage';
import { useParams } from 'react-router';
import Flex from 'ui/flex';
import {
  useDownloadTrademarks,
  useGetTrademarks,
} from 'business/trademark/services/trademarkFamily.service';
import Map from 'ui/map/map';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import { useTranslation } from 'react-i18next';
import { isDefined } from 'technical/validation';
import { useGetTrademarkImagesByFamilyIds } from 'business/trademark/services/trademarkImage.service';
import Table from 'ui/v2/table/table';
import trademarkColumns from 'business/trademark/components/columns.constant';
import useTableColumn from 'technical/table/useTableColumn';
import Button from 'ui/button';
import { DownloadOutlined } from '@ant-design/icons';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Spacer from 'ui/spacer';
import { useState } from 'react';
import { Image } from 'antd';
import SearchInput from 'ui/v2/input/searchInput';

const TrademarkFamilyPage = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const { id: familyId } = useParams<{ id: string }>();
  const { imageList } = useGetTrademarkImagesByFamilyIds([familyId]);

  const columns = trademarkColumns.trademarkFamily(t);
  const familyColumns = useTableColumn(columns);

  const { download, loading: loadingExport } = useDownloadTrademarks(
    columns,
    familyColumns.visibleColumns,
    {
      familyId,
      search,
    },
    familyColumns.orderByState,
  );

  const { data, loading, map, getFamilyBreadcrumbs, familyInfo } =
    useGetTrademarks({
      filters: { familyId, search },
      orderBy: familyColumns.orderByState,
      limit: familyColumns.paginationState.pageSize,
      offset:
        (familyColumns.paginationState.current - 1) *
        familyColumns.paginationState.pageSize,
    });

  return (
    <ConnectedPage
      breadcrumbs={getFamilyBreadcrumbs(familyInfo)}
      translationKey="trademarkFamily"
    >
      <Flex column>
        <HeaderTitles
          title={familyInfo?.title ?? ''}
          subtitle={t('pages.header.trademarkFamily')}
        />

        <Spacer justify="space-around" align="center">
          <Flex style={{ width: '40%' }}>
            <Image.PreviewGroup>
              {imageList
                .map((i) => i.image)
                .filter(isDefined)
                .map((url) => (
                  <Image key={url} src={url} style={{ objectFit: 'contain' }} />
                ))}
            </Image.PreviewGroup>
          </Flex>
          <Map
            resourceType="trademark"
            isDataLoading={map.loading}
            data={map.data}
          />
        </Spacer>
        <Table
          columns={familyColumns.tableColumns}
          dataSource={data?.getTrademarks?.resources ?? []}
          dataTestId="trademarkFamilyTable"
          loading={loading}
          pagination={{
            current: familyColumns.paginationState.current,
            pageSize: familyColumns.paginationState.pageSize,
            total: data?.getTrademarks?.count ?? 0,
          }}
          onChange={familyColumns.handlePagination}
          actions={
            <Flex justify="space-between">
              <Spacer align="center" size="xsmall">
                <SearchInput
                  value={search}
                  onDebouncedChange={(e) => setSearch(e)}
                  placeholder={t('common.search', { context: 'table' })}
                  style={{ width: '30%' }}
                />

                <EditColumnsButton
                  initialColumns={columns}
                  visibleColumns={familyColumns.visibleColumns}
                  onChange={familyColumns.toggleColumnVisibility}
                />
              </Spacer>
              <Button
                loading={loadingExport}
                icon={<DownloadOutlined />}
                data-test-id="exportButton"
                type="primary"
                onClick={() => download()}
              >
                {t('table.common.exportButton')}
              </Button>
            </Flex>
          }
        />
      </Flex>
    </ConnectedPage>
  );
};

export default TrademarkFamilyPage;
