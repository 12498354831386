/* eslint-disable react/destructuring-assignment */

import React from 'react';
import StatusBadge from './statusBadge';
import Spacer from 'ui/spacer';

interface StatusProps {
  status: string;
}

const Status: React.VFC<StatusProps> = ({ status }) => {
  return (
    <Spacer align="center" size="xxsmall">
      <StatusBadge status={status} />
      <div>{status}</div>
    </Spacer>
  );
};

export default Status;
