import ConnectedPage from 'business/user/helpers/connectedPage';
import {
  getTrademarkOfficialLink,
  useGetTrademark,
} from 'business/trademark/services/trademark.service';
import { isDefined } from 'technical/validation';
import { useGetTrademarkImagesByFamilyIds } from 'business/trademark/services/trademarkImage.service';
import { useParams } from 'react-router';
import { useResourceBreadcrumbs } from 'business/common/services/breadcrumbs';
import Routes from 'business/router/routes';
import Spacer from 'ui/spacer';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import Timeline from 'ui/timeline';
import { Tabs } from 'antd';
import TrademarkInformation from 'business/trademark/components/trademarkInformation';
import Flex from 'ui/flex';
import TrademarkNote from 'business/trademark/components/trademarkNote';
import TrademarkEvent from 'business/trademark/components/trademarkEvent';
import ProductAndServices from 'business/trademark/components/classes';
import { useTranslation } from 'react-i18next';
import TrademarkAffidavit from 'business/trademark/components/trademarkAffidavit';
import TrademarkObjections from 'business/trademark/components/trademarkObjections';
import { isBdlAdmin, isBdlUser } from 'business/admin/services/permission';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import ResourceDocuments from 'business/resources/shared/components/documents/resourceDocuments';
import { useGetOfficialLinks } from 'business/resources/shared/services/links/useGetOfficialLink';
import { Trademark } from 'business/trademark/services/trademark.types';
import ContactBdlByMailButton from 'business/common/components/contactBdlByMailButton';

const TrademarkPage = () => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { id } = useParams<{ id: string }>();
  const trademarkId = Number(id);

  const { trademark, timeline } = useGetTrademark(trademarkId);
  const { imageList, loading: loadingImage } = useGetTrademarkImagesByFamilyIds(
    [trademark?.familyId].filter(isDefined),
  );
  const { getResourceBreadcrumbs } = useResourceBreadcrumbs({
    type: 'trademark',
    familyRoute: Routes.TrademarkFamily,
    rootRoute: Routes.Trademark,
  });

  // const { trademarkLinks } = useGetTrademarkLink(trademark);
  const links = useGetOfficialLinks<Trademark>(
    getTrademarkOfficialLink,
    trademark,
  );

  const tabsItems = [
    {
      key: 'generalInformation',
      label: t('common.informations'),
      children: (
        <TrademarkInformation
          trademark={trademark}
          images={imageList.map((i) => i.image).filter(isDefined)}
          isLoading={loadingImage}
          officialRegisterLink={links?.[0]}
        />
      ),
    },
    {
      key: 'events',
      label: t('pages.event.title'),
      children: (
        <div style={{ height: '100%' }}>
          <TrademarkEvent id={trademarkId} />
        </div>
      ),
    },
    {
      key: 'classes',
      label: t('pages.trademark.productAndServices'),
      children: (
        <ProductAndServices
          loading={false}
          classes={trademark?.trademarkClasses ?? []}
        />
      ),
    },

    {
      key: 'documents',
      label: t('pages.document.title'),
      children: (
        <ResourceDocuments resource="trademark" resourceId={trademarkId} />
      ),
    },
    {
      key: 'objections',
      label: 'Litiges',
      children: (
        <TrademarkObjections
          objections={[]}
          isBdlUser={isBdlAdmin(user) || isBdlUser(user)}
        />
      ),
    },

    // TODO : Manque les AFU et les certificats !
    // {
    //   key: 'register',
    //   label: 'Titres officiels',
    //   children: (
    //     <div>
    //       <OfficialDocuments
    //         documents={patentDocuments}
    //         loading={patentDocumentLoading}
    //       />
    //     </div>
    //   ),
    // },
  ];

  const afuCountries = ['US', 'MX', 'PH', 'KH', 'AR', 'MZ', 'PE', 'JE', 'PR'];

  if (afuCountries.includes(trademark?.country?.code ?? '')) {
    tabsItems.push({
      key: 'afu',
      label: "Preuves d'usage",
      children: <TrademarkAffidavit trademarkId={trademark?.id ?? 0} />,
    });
  }
  return (
    <ConnectedPage
      breadcrumbs={getResourceBreadcrumbs(trademark)}
      translationKey="trademarkDetails"
    >
      <Spacer direction="vertical">
        <Spacer>
          <HeaderTitles
            title={trademark?.country?.labelFr ?? ''}
            subtitle={trademark?.title ?? ''}
          />
          {trademark ? (
            <ContactBdlByMailButton
              resource={trademark}
              resourceType="trademark"
            />
          ) : undefined}
        </Spacer>
        <Spacer size="small">
          <Spacer style={{ width: '85%' }} direction="vertical">
            <Timeline events={timeline} />

            <Tabs type="card" items={tabsItems} />
          </Spacer>

          <Flex className="noteSection" column style={{ height: '733px' }}>
            <TrademarkNote trademarkId={trademarkId} />
          </Flex>
        </Spacer>
      </Spacer>
    </ConnectedPage>
  );
};

export default TrademarkPage;
