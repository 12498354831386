import { Checkbox, Drawer, Form, Tabs } from 'antd';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import Spacer from 'ui/spacer';
import Table from 'ui/v2/table/table';
import styles from 'business/search/search.module.scss';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import { useGetObjectionsData } from 'business/objection/services/objection.service';
import { DownloadOutlined, FilterFilled } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { RangePicker } from 'ui/date/datePicker';
import SearchInput from 'ui/v2/input/searchInput';

const ObjectionPage = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [activeTab, setActiveTab] = useState('trademarkObjections');
  const [filtersOpen, setFiltersOpen] = useState(false);

  const resources = useGetObjectionsData();

  const currentResource = resources[activeTab as keyof typeof resources];

  return (
    <ConnectedPage translationKey="objectionPage">
      <Spacer style={{ marginTop: '50px' }} direction="vertical" size="small">
        <Spacer>
          <HeaderTitles title={t('pages.objections.mainTitle')} subtitle="" />
        </Spacer>
        <Spacer size="small" justify="end">
          <Button
            className={styles.filtersButton}
            onClick={() => setFiltersOpen(!filtersOpen)}
            icon={<FilterFilled color="black" />}
          >
            {t('pages.advancedSearch.advancedFiltersButton')}
          </Button>
          <SearchInput
            value={currentResource.search}
            onDebouncedChange={(e) => currentResource.setSearch(e)}
            placeholder={t('common.search', { context: 'table' })}
            style={{ width: '30%' }}
          />
          <EditColumnsButton
            className={styles.editColumnsButton}
            initialColumns={currentResource.tableColumns.defaultColumns}
            visibleColumns={
              currentResource.tableColumns.visibleColumns.visibleColumns
            }
            onChange={
              currentResource.tableColumns.visibleColumns.toggleColumnVisibility
            }
          />
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            loading={currentResource.export.loading}
            onClick={() => currentResource.export.download()}
          >
            {t('table.common.exportButton')}
          </Button>
        </Spacer>
      </Spacer>
      <Spacer direction="vertical" size="small" style={{ marginTop: '20px' }}>
        <Tabs
          type="card"
          size="large"
          animated
          onChange={(key) => {
            setActiveTab(key);
          }}
          items={[
            {
              label: t('ressources.trademark', {
                total: resources.trademarkObjections.data?.count ?? 0,
              }),
              key: 'trademarkObjections',
              children: (
                <Table
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            {
              label: t('ressources.patent', {
                total: resources.patentObjections.data?.count ?? 0,
              }),
              key: 'patentObjections',
              children: (
                <Table
                  key="patentObjections"
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            {
              label: t('ressources.design', {
                total: resources.designObjections.data?.count ?? 0,
              }),
              key: 'designObjections',
              children: (
                <Table
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            {
              label: t('ressources.domain', {
                total: resources.domainObjections.data?.count ?? 0,
              }),
              key: 'domainObjections',
              children: (
                <Table
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
          ]}
        />
        <Drawer
          closable
          open={filtersOpen}
          width={600}
          title={t('pages.advancedSearch.filtersDrawer.title', {
            context: activeTab,
          })}
          onClose={() => setFiltersOpen(!filtersOpen)}
        >
          <Spacer size="middle" direction="vertical">
            <Form form={form} layout="vertical">
              <Spacer direction="vertical" size="small">
                <Form.Item name="objectionType" label="Type de litige">
                  <Checkbox.Group
                    options={[
                      { label: 'Subi', value: 'SUBI' },
                      {
                        label: 'Déclenché',
                        value: 'DECLENCHE',
                      },
                    ]}
                  />
                </Form.Item>
              </Spacer>
              <Spacer direction="vertical" size="small">
                <Form.Item
                  name="objectionDate"
                  label="Date d'ouverture du dossier"
                >
                  <RangePicker
                    picker="date"
                    allowClear
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Spacer>
            </Form>
          </Spacer>
        </Drawer>
      </Spacer>
    </ConnectedPage>
  );
};

export default ObjectionPage;
