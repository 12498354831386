import { StatusFilter } from 'business/common/services/types';

export const COMMON_STATUS_FILTERS: StatusFilter[] = [
  StatusFilter.Effective,
  StatusFilter.NotEffective,
];

export const ALL_STATUS_FILTERS: StatusFilter[] = [
  ...COMMON_STATUS_FILTERS,
  StatusFilter.Deposited,
  StatusFilter.NotDeposited,
  StatusFilter.Issued,
  StatusFilter.NotIssued,
];

export const RESOURCES_STATUS_FILTERS: Record<string, StatusFilter[]> = {
  patent: ALL_STATUS_FILTERS,
  trademark: COMMON_STATUS_FILTERS,
  design: COMMON_STATUS_FILTERS,
  domain: COMMON_STATUS_FILTERS,
  all: COMMON_STATUS_FILTERS,
};
