import { GetOfficialLinkFunction } from 'business/common/services/officialRegister/types';
import { DesignWithImages } from 'business/design/services/types';
import { Patent } from 'business/patent/services/types';
import { Trademark } from 'business/trademark/services/trademark.types';

type ResourcesWithOfficialLinks = Patent | DesignWithImages | Trademark;

export const useGetOfficialLinks = <T extends ResourcesWithOfficialLinks>(
  getOfficialLink: GetOfficialLinkFunction<T>,
  asset: T | null | undefined,
) => {
  if (!asset || !asset?.country?.code) {
    return null;
  }
  const link = getOfficialLink(asset);
  if (!link) {
    return null;
  }

  return Array.isArray(link) ? link : [link];
};
