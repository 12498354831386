import { Patent } from 'business/patent/services/types';
import {
  onlyNumbers,
  pipe,
  removeDashes,
  removeSlashAndDot,
  removeSpaces,
  takeBeforeDot,
} from 'technical/format/string';
import {
  formatUS,
  getOfficialLink,
  isAlreadyPublished,
  isAlreadyPublishedAndDeposited,
} from './helpers';
import { links } from './constants';
import { OfficialLinkMethods } from './types';

const EUROPEAN_PROTECTION_CODES = ['ABEPVA', 'ABQTVA'];

const PATENT_OFFICIAL_LINKS: OfficialLinkMethods<Patent> = {
  FR: getOfficialLink<Patent>(isAlreadyPublished)('publicationNumber')(
    links.patent.FR,
  ),
  FR_EP: getOfficialLink<Patent>(isAlreadyPublished)(
    'publicationNumber',
    onlyNumbers,
  )(links.patent.FR_BE),
  EP: getOfficialLink<Patent>(isAlreadyPublished)(
    'depositNumber',
    takeBeforeDot,
  )(links.patent.EP),

  DE: getOfficialLink<Patent>(isAlreadyPublishedAndDeposited)(
    'depositNumber',
    takeBeforeDot,
  )(links.patent.DE),

  DE_EP: getOfficialLink<Patent>(isAlreadyPublishedAndDeposited)(
    'depositNumber',
    onlyNumbers,
  )(links.patent.DE),

  US: getOfficialLink<Patent>(isAlreadyPublishedAndDeposited)(
    'depositNumber',
    formatUS,
  )(links.patent.US),

  JP: getOfficialLink<Patent>(isAlreadyPublishedAndDeposited)(
    'depositNumber',
    pipe(removeDashes, removeSpaces),
  )(links.patent.JP),

  CN: getOfficialLink<Patent>(isAlreadyPublishedAndDeposited)(
    'depositNumber',
    removeSlashAndDot,
  )(links.patent.CN),

  GB: getOfficialLink<Patent>(isAlreadyPublished)(
    'publicationNumber',
    pipe(removeSpaces, (publicationNumber) =>
      publicationNumber.replace(/^E/g, ''),
    ),
  )(links.patent.GB),
  GB_EP: getOfficialLink<Patent>(isAlreadyPublished)(
    'publicationNumber',
    pipe(removeSpaces, (publicationNumber) =>
      publicationNumber.replace(/^E/g, ''),
    ),
  )(links.patent.GB_EP),
  QT: getOfficialLink<Patent>(isAlreadyPublished)(
    'publicationNumber',
    pipe(onlyNumbers, (publicationNumber) =>
      publicationNumber.replace(/^E/g, ''),
    ),
  )(links.patent.QT),
};

export const getPatentOfficialLinkCode = (
  countryCode: string,
  protectionCode = '',
) => {
  const isEuropeanProtection =
    EUROPEAN_PROTECTION_CODES.includes(protectionCode);

  switch (countryCode) {
    case 'FR':
      return isEuropeanProtection ? 'FR_EP' : countryCode;
    case 'DE':
      return isEuropeanProtection ? 'DE_EP' : countryCode;
    case 'GB':
      return isEuropeanProtection ? 'GB_EP' : countryCode;
    default:
      return countryCode;
  }
};

export const getPatentOfficialLink = (patent: Patent) => {
  const countryCode = patent.country?.code;
  if (!countryCode) {
    return null;
  }

  const method =
    PATENT_OFFICIAL_LINKS[
      getPatentOfficialLinkCode(countryCode, patent.protection?.protectionCode)
    ];
  return method ? method(patent) : null;
};
