import appLogo from 'config/appLogo.png';
import styles from './index.module.scss';
import { useHistory } from 'react-router';

interface AppLogoProps {
  height?: string;
  path?: string;
  connected?: boolean;
  clickable?: boolean;
}

const AppLogo = ({ clickable, height }: AppLogoProps) => {
  const history = useHistory();
  return (
    <img
      className={styles.appLogo}
      style={{ height }}
      src={appLogo}
      alt="App logo"
      onClick={() => (clickable ? history.push('/') : {})}
    />
  );
};

export default AppLogo;
