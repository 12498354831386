import { useTranslation } from 'react-i18next';
import { useGetPrivateDns } from 'business/domain/services/domain.service';
import Spacer from 'ui/spacer';
import { Table } from 'antd';
import ErrorOverlay from 'ui/errorOverlay';
import Card from 'ui/card';

const PrivateDns = (props: { domainId: number; skip: boolean }) => {
  const { t } = useTranslation();

  const { servers, privateDomainDNSDataState, zones } = useGetPrivateDns({
    domainId: props.domainId,
    skip: !props.skip,
  });

  return (
    <Spacer size="small" direction="vertical">
      <ErrorOverlay {...privateDomainDNSDataState}>
        <Card
          title={t('pages.domain.dnsPublicData.servers.title')}
          className="fullHeight"
        >
          <Table
            className="marginTop20"
            columns={[
              { key: 'serverName', dataIndex: 'serverName' },
              { key: 'server', dataIndex: 'server' },
            ]}
            showHeader={false}
            dataSource={servers}
          />
        </Card>
        <Card
          title={t('pages.domain.dnsPublicData.records.title')}
          className="fullHeight"
        >
          <Table
            className="marginTop20"
            columns={[
              { key: 'name', title: 'Nom', dataIndex: 'name' },
              { key: 'type', title: 'type', dataIndex: 'type' },
              {
                key: 'value',
                ellipsis: true,
                title: 'valeur',
                dataIndex: 'value',
              },
              {
                key: 'ttl',
                title: 'TTL',
                dataIndex: 'ttl',
                render: (_: any, data: any) =>
                  data.ttl ? `${data.ttl} sec.` : null,
              },
            ]}
            dataSource={zones}
          />
        </Card>
      </ErrorOverlay>
    </Spacer>
  );
};

export default PrivateDns;
