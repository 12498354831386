/* eslint-disable import/no-extraneous-dependencies */
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import Flex from 'ui/flex';

const PdfViewer = (props: { fileUrl: string }) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const getFilePluginInstance = getFilePlugin();
  const { DownloadButton } = getFilePluginInstance;

  return (
    <div
      style={{
        height: '450px',
      }}
    >
      <Flex alignItems="center" justify="center">
        <ZoomInButton />
        <ZoomOutButton />
        <ZoomPopover />
        <DownloadButton />
      </Flex>
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
      >
        <Viewer
          fileUrl={props.fileUrl}
          defaultScale={0.9}
          plugins={[zoomPluginInstance, getFilePluginInstance]}
        />
      </Worker>
    </div>
  );
};

export default PdfViewer;
