import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import React from 'react';
import InputError from 'ui/form/inputError';
import styles from './index.module.scss';

interface Props {
  label?: React.ReactNode;
  validateStatus?: ValidateStatus;
  help?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  error?: string;
  id?: string;
  'data-test-id'?: string;
  className?: string;
  passwordHint?: React.ReactNode;
  autoComplete: 'current-password' | 'new-password';
}

const EyeIconRender = (visible: boolean) =>
  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

const PasswordInput = ({
  label,
  validateStatus,
  help,
  error,
  passwordHint,
  ...rest
}: Props) => {
  return (
    <Form.Item label={label} validateStatus={validateStatus} help={help}>
      <Input.Password
        iconRender={EyeIconRender}
        {...rest}
        style={{ background: '#FFFFFF' }}
      />
      <InputError error={error} />
      <p className={styles.passwordHint}>{passwordHint}</p>
    </Form.Item>
  );
};

export default PasswordInput;
