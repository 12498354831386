import { ColumnsType } from 'antd/es/table';
import { generatePathByAssetType } from 'business/common/services';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { getCountryKey } from 'technical/countriesTranslation/constants';
import { formatDate } from 'technical/date';

const eventsColumns = (t: TFunction): Record<string, ColumnsType<any>> => {
  return {
    patentEvents: [
      {
        key: 'type',
        dataIndex: 'type',
        title: t('table.common.column.eventType'),
      },
      {
        key: 'holder',
        dataIndex: 'holder',
        title: t('table.common.column.holder'),
      },
      {
        key: 'effectiveHolder',
        dataIndex: 'effectiveHolder',
        title: t('table.common.column.effectiveHolder'),
      },
      {
        key: 'title',
        dataIndex: 'title',
        title: t('table.common.column.title'),
        render: (_, record) => (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={generatePathByAssetType('patent', record.assetId)}
          >
            {record.title}
          </Link>
        ),
      },
      {
        key: 'countryFr',
        dataIndex: 'countryFr',
        title: t('table.common.column.country'),
        sorter: true,
        render: (_: any, data: any) => {
          return data[getCountryKey()];
        },
      },
      {
        key: 'dueDate',
        dataIndex: 'dueDate',
        title: t('table.common.column.dueDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.dueDate) {
            return formatDate(new Date(data.dueDate));
          }
        },
      },
      {
        key: 'depositNumber',
        dataIndex: 'depositNumber',
        title: t('table.common.column.depositNumber'),
        sorter: true,
      },
      {
        key: 'depositDate',
        dataIndex: 'depositDate',
        title: t('table.common.column.depositDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.depositDate) {
            return formatDate(new Date(data.depositDate));
          }
        },
      },
      {
        key: 'issueNumber',
        dataIndex: 'issueNumber',
        title: t('table.common.column.issueNumber'),
        sorter: true,
      },
      {
        key: 'issueDate',
        dataIndex: 'issueDate',
        title: t('table.common.column.issueDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.issueDate) {
            return formatDate(new Date(data.issueDate));
          }
        },
      },
      {
        key: 'bdlRef',
        dataIndex: 'bdlRef',
        title: t('table.common.column.bdlRef'),
        sorter: true,
      },
      {
        key: 'clientRef',
        dataIndex: 'clientRef',
        title: t('table.common.column.clientRef'),
        sorter: true,
      },
      {
        key: 'clientRef2',
        dataIndex: 'clientRef2',
        title: t('table.common.column.clientRef2'),
        sorter: true,
      },
      {
        key: 'bdlOfficer',
        dataIndex: 'bdlOfficer',
        title: t('table.common.column.bdlOfficer'),
        sorter: true,
      },
      {
        key: 'clientOfficer',
        dataIndex: 'clientOfficer',
        title: t('table.common.column.clientOfficer'),
        sorter: true,
      },
      {
        key: 'inventors',
        dataIndex: 'inventors',
        title: t('table.common.column.inventors'),
        sorter: true,
      },
    ],
    domainEvents: [
      {
        key: 'type',
        dataIndex: 'type',
        title: t('table.common.column.eventType'),
      },
      {
        key: 'holder',
        dataIndex: 'holder',
        title: t('table.common.column.holder'),
      },
      {
        key: 'title',
        dataIndex: 'title',
        title: t('table.common.column.title', { context: 'domain' }),
        render: (_, record) => (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={generatePathByAssetType('domain', record.assetId)}
          >
            {record.title}
          </Link>
        ),
      },
      {
        key: 'extension',
        dataIndex: 'extension',
        title: t('table.common.column.extension'),
        sorter: true,
      },
      {
        key: 'countryFr',
        dataIndex: 'countryFr',
        title: t('table.common.column.country'),
        sorter: true,
        render: (_: any, data: any) => {
          return data[getCountryKey()];
        },
      },
      {
        key: 'dueDate',
        dataIndex: 'dueDate',
        title: t('table.common.column.dueDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.dueDate) {
            return formatDate(new Date(data.dueDate));
          }
        },
      },
      {
        key: 'depositDate',
        dataIndex: 'depositDate',
        title: t('table.common.column.depositDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.depositDate) {
            return formatDate(new Date(data.depositDate));
          }
        },
      },
      {
        key: 'bdlRef',
        dataIndex: 'bdlRef',
        title: t('table.common.column.bdlRef'),
        sorter: true,
      },
      {
        key: 'clientRef',
        dataIndex: 'clientRef',
        title: t('table.common.column.clientRef'),
        sorter: true,
      },
      {
        key: 'clientRef2',
        dataIndex: 'clientRef2',
        title: t('table.common.column.clientRef2'),
        sorter: true,
      },
      {
        key: 'bdlOfficer',
        dataIndex: 'bdlOfficer',
        title: t('table.common.column.bdlOfficer'),
        sorter: true,
      },
      {
        key: 'clientOfficer',
        dataIndex: 'clientOfficer',
        title: t('table.common.column.clientOfficer'),
        sorter: true,
      },
    ],
    designEvents: [
      {
        key: 'type',
        dataIndex: 'type',
        title: t('table.common.column.eventType'),
      },
      {
        key: 'holder',
        dataIndex: 'holder',
        title: t('table.common.column.holder'),
      },
      {
        key: 'effectiveHolder',
        dataIndex: 'effectiveHolder',
        title: t('table.common.column.effectiveHolder'),
      },
      {
        key: 'title',
        dataIndex: 'title',
        title: t('table.common.column.title'),
        render: (_, record) => (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={generatePathByAssetType('patent', record.assetId)}
          >
            {record.title}
          </Link>
        ),
      },
      {
        key: 'countryFr',
        dataIndex: 'countryFr',
        title: t('table.common.column.country'),
        sorter: true,
        render: (_: any, data: any) => {
          return data[getCountryKey()];
        },
      },
      {
        key: 'dueDate',
        dataIndex: 'dueDate',
        title: t('table.common.column.dueDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.dueDate) {
            return formatDate(new Date(data.dueDate));
          }
        },
      },
      {
        key: 'depositNumber',
        dataIndex: 'depositNumber',
        title: t('table.common.column.depositNumber'),
        sorter: true,
      },
      {
        key: 'depositDate',
        dataIndex: 'depositDate',
        title: t('table.common.column.depositDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.depositDate) {
            return formatDate(new Date(data.depositDate));
          }
        },
      },
      {
        key: 'bdlRef',
        dataIndex: 'bdlRef',
        title: t('table.common.column.bdlRef'),
        sorter: true,
      },
      {
        key: 'clientRef',
        dataIndex: 'clientRef',
        title: t('table.common.column.clientRef'),
        sorter: true,
      },
      {
        key: 'clientRef2',
        dataIndex: 'clientRef2',
        title: t('table.common.column.clientRef2'),
        sorter: true,
      },
      {
        key: 'bdlOfficer',
        dataIndex: 'bdlOfficer',
        title: t('table.common.column.bdlOfficer'),
        sorter: true,
      },
      {
        key: 'clientOfficer',
        dataIndex: 'clientOfficer',
        title: t('table.common.column.clientOfficer'),
        sorter: true,
      },
      {
        key: 'adjournedPublication',
        dataIndex: 'adjournedPublication',
        title: t('table.common.column.adjournedPublication'),
        sorter: true,
      },
    ],
    trademarkEvents: [
      {
        key: 'type',
        dataIndex: 'type',
        title: t('table.common.column.eventType'),
      },
      {
        key: 'holder',
        dataIndex: 'holder',
        title: t('table.common.column.holder'),
      },
      {
        key: 'effectiveHolder',
        dataIndex: 'effectiveHolder',
        title: t('table.common.column.effectiveHolder'),
      },
      {
        key: 'title',
        dataIndex: 'title',
        title: t('table.common.column.title'),
        render: (_, record) => (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={generatePathByAssetType('trademark', record.assetId)}
          >
            {record.title}
          </Link>
        ),
      },
      {
        key: 'classes',
        dataIndex: 'classes',
        title: t('table.common.column.classes'),
        render: (_, record) => {
          return record.classes?.map((c: any) => c.classCode).join(' - ');
        },
      },
      {
        key: 'countryFr',
        dataIndex: 'countryFr',
        title: t('table.common.column.country'),
        sorter: true,
        render: (_: any, data: any) => {
          return data[getCountryKey()];
        },
      },
      {
        key: 'dueDate',
        dataIndex: 'dueDate',
        title: t('table.common.column.dueDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.dueDate) {
            return formatDate(new Date(data.dueDate));
          }
        },
      },
      {
        key: 'depositNumber',
        dataIndex: 'depositNumber',
        title: t('table.common.column.depositNumber'),
        sorter: true,
      },
      {
        key: 'depositDate',
        dataIndex: 'depositDate',
        title: t('table.common.column.depositDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.depositDate) {
            return formatDate(new Date(data.depositDate));
          }
        },
      },
      {
        key: 'registrationNumber',
        dataIndex: 'registrationNumber',
        title: t('table.common.column.registrationNumber'),
        sorter: true,
      },
      {
        key: 'firstRegistrationDate',
        dataIndex: 'firstRegistrationDate',
        title: t('table.common.column.firstRegistrationDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.firstRegistrationDate) {
            return formatDate(new Date(data.firstRegistrationDate));
          }
        },
      },
      {
        key: 'lastRegistrationDate',
        dataIndex: 'lastRegistrationDate',
        title: t('table.common.column.lastRegistrationDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.lastRegistrationDate) {
            return formatDate(new Date(data.lastRegistrationDate));
          }
        },
      },
      {
        key: 'bdlRef',
        dataIndex: 'bdlRef',
        title: t('table.common.column.bdlRef'),
        sorter: true,
      },
      {
        key: 'clientRef',
        dataIndex: 'clientRef',
        title: t('table.common.column.clientRef'),
        sorter: true,
      },
      {
        key: 'clientRef2',
        dataIndex: 'clientRef2',
        title: t('table.common.column.clientRef2'),
        sorter: true,
      },
      {
        key: 'bdlOfficer',
        dataIndex: 'bdlOfficer',
        title: t('table.common.column.bdlOfficer'),
        sorter: true,
      },
      {
        key: 'clientOfficer',
        dataIndex: 'clientOfficer',
        title: t('table.common.column.clientOfficer'),
        sorter: true,
      },
    ],
    allEvents: [
      {
        key: 'asset',
        dataIndex: 'asset',
        title: t('table.common.column.asset'),
        render: (_, record) => {
          return t('table.common.column.asset', { context: record.asset });
        },
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: t('table.common.column.eventType'),
      },
      {
        key: 'holder',
        dataIndex: 'holder',
        title: t('table.common.column.holder'),
      },
      {
        key: 'effectiveHolder',
        dataIndex: 'effectiveHolder',
        title: t('table.common.column.effectiveHolder'),
      },
      {
        key: 'title',
        dataIndex: 'title',
        title: t('table.common.column.title'),
        render: (_, record) => (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={generatePathByAssetType(record.asset, record.assetId)}
          >
            {record.title}
          </Link>
        ),
      },
      {
        key: 'dueDate',
        dataIndex: 'dueDate',
        title: t('table.common.column.dueDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.dueDate) {
            return formatDate(new Date(data.dueDate));
          }
        },
      },
      {
        key: 'depositNumber',
        dataIndex: 'depositNumber',
        title: t('table.common.column.depositNumber'),
        sorter: true,
      },
      {
        key: 'depositDate',
        dataIndex: 'depositDate',
        title: t('table.common.column.depositDate'),
        sorter: true,
        render: (_: any, data: any) => {
          if (data.depositDate) {
            return formatDate(new Date(data.depositDate));
          }
        },
      },
      {
        key: 'bdlRef',
        dataIndex: 'bdlRef',
        title: t('table.common.column.bdlRef'),
        sorter: true,
      },
      {
        key: 'clientRef',
        dataIndex: 'clientRef',
        title: t('table.common.column.clientRef'),
        sorter: true,
      },
      {
        key: 'clientRef2',
        dataIndex: 'clientRef2',
        title: t('table.common.column.clientRef2'),
        sorter: true,
      },
      {
        key: 'bdlOfficer',
        dataIndex: 'bdlOfficer',
        title: t('table.common.column.bdlOfficer'),
        sorter: true,
      },
      {
        key: 'clientOfficer',
        dataIndex: 'clientOfficer',
        title: t('table.common.column.clientOfficer'),
        sorter: true,
      },
    ],
  };
};

export default eventsColumns;
