import { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Flex from 'ui/flex';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import Map from 'ui/map/map';
import Spacer from 'ui/spacer';
import useTableColumn from 'technical/table/useTableColumn';
import Table from 'ui/v2/table/table';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Button from 'ui/button';
import domainColumns from 'business/domain/services/columns.constant';
import { useGetDomainFamilyData } from 'business/domain/services/domainFamily.service';
import SearchInput from 'ui/v2/input/searchInput';

const DomainFamilyPage = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const { id: familyId } = useParams<{ id: string }>();

  const columns = domainColumns(t).domainFamily;
  const domainFamilyColumns = useTableColumn(columns);

  const {
    data,
    total,
    loading,
    map,
    domainFamilyTitle,
    getFamilyBreadcrumbs,
    familyInfo,
  } = useGetDomainFamilyData({
    filters: {
      search,
      familyId,
    },
    limit: domainFamilyColumns.paginationState.pageSize,
    offset:
      (domainFamilyColumns.paginationState.current - 1) *
      domainFamilyColumns.paginationState.pageSize,
    orderBy: domainFamilyColumns.orderByState,
  });

  return (
    <ConnectedPage
      breadcrumbs={getFamilyBreadcrumbs(familyInfo)}
      translationKey="domainFamily"
    >
      <Flex justify="space-between">
        <HeaderTitles
          title={domainFamilyTitle}
          subtitle={t('pages.header.patentFamily')}
        />

        <div style={{ width: '50%', height: '300px' }}>
          <Map
            resourceType="domain"
            isDataLoading={map.loading}
            data={map.data}
          />
        </div>
      </Flex>

      <Table
        columns={domainFamilyColumns.tableColumns}
        dataTestId="domainFamilyTable"
        dataSource={data ?? []}
        loading={loading}
        onChange={domainFamilyColumns.handlePagination}
        pagination={{
          current: domainFamilyColumns.paginationState.current,
          pageSize: domainFamilyColumns.paginationState.pageSize,
          total,
        }}
        actions={
          <Flex justify="space-between">
            <Spacer align="center" size="xsmall">
              <SearchInput
                value={search}
                onDebouncedChange={(e) => setSearch(e)}
                placeholder={t('common.search', { context: 'table' })}
                style={{ width: '30%' }}
              />

              <EditColumnsButton
                initialColumns={columns}
                visibleColumns={domainFamilyColumns.visibleColumns}
                onChange={domainFamilyColumns.toggleColumnVisibility}
              />
            </Spacer>
            <Button
              loading={false}
              icon={<DownloadOutlined />}
              data-test-id="exportButton"
              type="primary"
              onClick={() => {}}
            >
              {t('table.common.exportButton')}
            </Button>
          </Flex>
        }
      />
    </ConnectedPage>
  );
};

export default DomainFamilyPage;
