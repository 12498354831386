import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { ExtractRouteParams, useParams } from 'react-router';

import Routes from 'business/router/routes';
import { resourceExists } from 'business/common/services';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import Spacer from 'ui/spacer';
import { Tabs } from 'antd';
import Timeline from 'ui/timeline';
import {
  getDesignOfficialLinks,
  useDesignBreadcrumbs,
  useGetDesign,
} from 'business/design/services/design.service';
import Flex from 'ui/flex';
import DesignNote from 'business/design/components/designNote';
import DesignInformation from 'business/design/components/designInformation';
import { isDefined } from 'technical/validation';
import DesignEvents from 'business/design/components/designEvent';
import ResourceDocuments from 'business/resources/shared/components/documents/resourceDocuments';
import { useTranslation } from 'react-i18next';
import { useGetOfficialLinks } from 'business/resources/shared/services/links/useGetOfficialLink';
import { DesignWithImages } from 'business/design/services/types';
import ContactBdlByMailButton from 'business/common/components/contactBdlByMailButton';

const DesignPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<ExtractRouteParams<Routes.DesignPage, string>>();
  const designId = Number(id);

  const { design, images, designDataState, timeline, imageLoading } =
    useGetDesign(designId);
  // const { designLinks } = useGetDesignLinks(design);
  const { getResourceBreadcrumbs } = useDesignBreadcrumbs();
  const links = useGetOfficialLinks<DesignWithImages>(
    getDesignOfficialLinks,
    design,
  );

  // const { user } = useLoggedInAppContext();

  return (
    <ResourceNotFound resourceExists={resourceExists(design, designDataState)}>
      <ConnectedPage
        translationKey="designDetails"
        breadcrumbs={getResourceBreadcrumbs(design)}
      >
        <Spacer direction="vertical">
          <Spacer>
            <HeaderTitles
              title={design?.country?.labelFr ?? ''}
              subtitle={design?.title ?? ''}
            />
            {design ? (
              <ContactBdlByMailButton resource={design} resourceType="design" />
            ) : undefined}
          </Spacer>
          <Spacer size="small">
            <Spacer style={{ width: '85%' }} direction="vertical">
              <Timeline events={timeline} />

              <Tabs
                type="card"
                items={[
                  {
                    key: 'generalInformation',
                    label: t('common.informations'),
                    children: (
                      <DesignInformation
                        design={design}
                        images={images.map((i) => i.image).filter(isDefined)}
                        isLoading={imageLoading}
                        officialRegisterLink={links?.[0]}
                      />
                    ),
                  },
                  {
                    key: 'events',
                    label: t('pages.event.title'),
                    children: (
                      <div style={{ height: '100%' }}>
                        <DesignEvents id={designId} />
                      </div>
                    ),
                  },
                  {
                    key: 'documents',
                    label: t('pages.document.title'),
                    children: (
                      <ResourceDocuments
                        resource="design"
                        resourceId={designId}
                      />
                    ),
                  },
                ]}
              />
            </Spacer>

            <Flex className="noteSection" column style={{ height: '733px' }}>
              <DesignNote designId={designId} />
            </Flex>
          </Spacer>
        </Spacer>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default DesignPage;
