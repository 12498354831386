import { AutoComplete, AutoCompleteProps, Input, InputProps } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

interface IAutoCompleteInput extends AutoCompleteProps {
  placeholder?: string;
  onChange?: InputProps['onChange'];
  onPressEnter?: InputProps['onPressEnter'];
}

const AutoCompleteInput = (props: IAutoCompleteInput) => {
  return (
    <AutoComplete
      placeholder={undefined}
      options={props.options}
      value={props.value}
      style={props.style}
      className="searchInput"
    >
      <Input
        data-test-id="headerSearchBarInput"
        prefix={<SearchOutlined />}
        onChange={props.onChange}
        value={props.value}
        size="large"
        addonAfter={undefined}
        placeholder={props.placeholder}
        onPressEnter={props.onPressEnter}
      />
    </AutoComplete>
  );
};

export default AutoCompleteInput;
