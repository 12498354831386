import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EyeOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { generatePathByAssetType } from 'business/common/services';
import {
  getCountriesKey,
  getCountryKey,
} from 'technical/countriesTranslation/constants';
import { Link } from 'react-router-dom';
import { FAMILIES_TABLE_IMAGE_STYLE } from 'ui/imageDisplay/constants';
import { formatDate } from 'technical/date';
import { Button, Image, Tooltip } from 'antd';
import Loader from 'ui/loader';
import Spacer from 'ui/spacer';
import { TFunction } from 'i18next';
import { getAlissiaResourceUrl } from 'business/resources/shared/services/constants';

const trademarkColumns = {
  trademarkFamilies: (t: TFunction) => {
    return [
      {
        title: t('table.common.column.holder'),
        dataIndex: 'holder',
        sorter: true,
        key: 'holder',
      },
      {
        title: t('table.common.column.title', { context: 'design' }),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        render: (_: any, record: any) => (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={`/trademark/family/${record.id}`}
          >
            {record.title}
          </Link>
        ),
      },
      {
        title: 'image',
        dataIndex: 'image',
        key: 'image',
        sorter: false,
        render: (_: any, record: any) => {
          if (record.image === 'loading') {
            return <Loader />;
          }
          if (record.image) {
            return (
              <Image
                src={record.image}
                style={{ ...FAMILIES_TABLE_IMAGE_STYLE, objectFit: 'contain' }}
              />
            );
          }
        },
      },
      {
        title: t('table.common.column.countries'),
        sorter: true,
        dataIndex: 'countriesFr',
        key: 'countriesFr',
        render: (_: any, data: any) => {
          return (
            <Tooltip title={data[getCountriesKey()]}>
              {data[getCountriesKey()]}
            </Tooltip>
          );
        },
      },
      {
        title: t('table.common.column.depositNumber'),
        dataIndex: 'firstDepositNumber',
        key: 'firstDepositNumber',
        sorter: true,
      },
      {
        title: t('table.common.column.firstDepositDate'),
        dataIndex: 'firstDepositDate',
        key: 'firstDepositDate',
        render: (_: any, data: any) => {
          if (data.firstDepositDate) {
            return formatDate(new Date(data.firstDepositDate));
          }
        },
        sorter: true,
      },
      {
        title: t('table.common.column.registrationNumber'),
        dataIndex: 'registrationNumber',
        key: 'registrationNumber',
        sorter: true,
      },
      {
        title: t('table.common.column.lastRegistrationDate'),
        dataIndex: 'lastRegistrationDate',
        key: 'lastRegistrationDate',
        render: (_: any, data: any) => {
          if (data.lastRegistrationDate) {
            return formatDate(new Date(data.lastRegistrationDate));
          }
        },
        sorter: true,
      },
      {
        title: t('table.common.column.clientRef'),
        dataIndex: 'clientRef',
        key: 'clientRef',
        sorter: true,
      },
      {
        title: t('table.common.column.bdlRef'),
        dataIndex: 'bdlRef',
        key: 'bdlRef',
        sorter: true,
      },
      {
        title: t('table.common.column.clientOfficer'),
        dataIndex: 'clientOfficer',
        key: 'clientOfficer',
        sorter: true,
      },
      {
        title: t('table.common.column.bdlOfficerName'),
        dataIndex: 'bdlOfficerName',
        key: 'bdlOfficerName',
        sorter: true,
      },
    ];
  },
  trademarkFamily: (t: TFunction) => {
    return [
      {
        title: t('table.common.column.countryFr'),
        sorter: true,
        dataIndex: 'countryFr',
        key: 'countryFr',
        render: (_: any, data: any) => {
          return (
            <Link
              className="titleRedirect"
              to={generatePathByAssetType('trademark', data.id)}
            >
              {data[getCountryKey()]}
            </Link>
          );
        },
      },
      {
        title: t('table.common.column.classes'),
        sorter: true,
        dataIndex: 'classes',
        key: 'classes',
        render: (_: any, data: any) => {
          return data.classes.map((c: any) => Number(c.classCode)).join('-');
        },
      },
      {
        title: t('table.common.column.holder'),
        dataIndex: 'holder',
        sorter: true,
        key: 'holder',
      },
      {
        title: t('table.common.column.title', { context: 'design' }),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
      },
      {
        title: t('table.common.column.depositNumber'),
        dataIndex: 'depositNumber',
        key: 'depositNumber',
        sorter: true,
      },
      {
        title: t('table.common.column.depositDate'),
        dataIndex: 'depositDate',
        key: 'depositDate',
        render: (_: any, data: any) => {
          if (data.depositDate) {
            return formatDate(new Date(data.depositDate));
          }
        },
        sorter: true,
      },
      {
        title: t('table.common.column.registrationNumber'),
        dataIndex: 'registrationNumber',
        key: 'registrationNumber',
        sorter: true,
      },
      {
        title: t('table.common.column.lastRegistrationDate'),
        dataIndex: 'lastRegistrationDate',
        key: 'lastRegistrationDate',
        render: (_: any, data: any) => {
          if (data.firstRegistrationDate) {
            return formatDate(new Date(data.firstRegistrationDate));
          }
          if (data.lastRegistrationDate) {
            return formatDate(new Date(data.lastRegistrationDate));
          }
        },
        sorter: true,
      },
      {
        title: t('table.common.column.nextRenewDate'),
        dataIndex: 'nextRenewDate',
        key: 'nextRenewDate',
        sorter: true,
        render: (_: any, data: any) => {
          if (data.nextRenewDate) {
            return formatDate(new Date(data.nextRenewDate));
          }
        },
      },
      {
        title: t('table.common.column.bdlRef'),
        dataIndex: 'bdlRef',
        key: 'bdlRef',
        sorter: true,
      },
      {
        title: t('table.common.column.misRef'),
        dataIndex: 'misRef',
        key: 'misRef',
        sorter: true,
      },
      {
        title: t('table.common.column.clientRef'),
        dataIndex: 'clientRef',
        key: 'clientRef',
        sorter: true,
      },
      {
        title: t('table.common.column.clientRef2'),
        dataIndex: 'clientRef2',
        key: 'clientRef2',
        sorter: true,
      },
      {
        title: t('table.common.column.clientOfficer'),
        dataIndex: 'clientOfficer',
        key: 'clientOfficer',
        sorter: true,
      },
      {
        title: t('table.common.column.bdlOfficerName'),
        dataIndex: 'bdlOfficerName',
        key: 'bdlOfficerName',
        sorter: true,
      },
    ];
  },
  affidavits: ({
    onOpen,
    onDelete,
    onDownload,
    isBdlUser,
  }: {
    onOpen: (id: string) => Promise<void>;
    onDownload: (id: string, fileName: string) => Promise<void>;
    onDelete: (id: string) => void;
    isBdlUser: boolean;
  }) => {
    return [
      {
        title: 'Nom',
        dataIndex: 'title',
        sorter: false,
        key: 'title',
      },
      {
        title: 'Ajouté le',
        dataIndex: 'bucketPath',
        key: 'fullName',
        render: (_: any, data: any) => {
          return formatDate(new Date(data.bucketPath.createdAt));
        },
      },
      {
        title: 'Ajouté par',
        dataIndex: 'firstName',
        key: 'fullName',
        render: (_: any, data: any) => {
          return data.userFirstName + ' ' + data.userLastName;
        },
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (_: any, data: any) => {
          return (
            <Spacer size="xxsmall">
              <Button
                type="primary"
                size="middle"
                icon={<EyeOutlined />}
                onClick={() => {
                  onOpen(data.bucketPathId);
                }}
              />
              <Button
                type="primary"
                size="middle"
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  onDownload(data.bucketPathId, data.title);
                }}
              />
              {!isBdlUser ? (
                <Button
                  type="primary"
                  size="middle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    onDelete(data.bucketPathId);
                  }}
                />
              ) : null}
            </Spacer>
          );
        },
      },
    ];
  },
  trademarkObjections: (t: TFunction, isBdlUser: boolean) => {
    const columnsData: any[] = [
      {
        title: t('table.common.column.status'),
        dataIndex: 'status',
        width: '80px',
        sorter: false,
        key: 'status',
      },
      {
        title: t('table.common.column.title'),
        dataIndex: 'title',
        sorter: false,
        key: 'title',
      },
      {
        title: t('table.common.column.type', { context: 'opposition' }),
        dataIndex: 'protectionLabel',
        width: '150px',
        sorter: false,
        key: 'protectionLabel',
      },
      {
        title: t('table.common.column.opposingParty'),
        dataIndex: 'opposingParty',
        sorter: false,
        key: 'opposingParty',
      },
    ];
    if (isBdlUser) {
      columnsData.push({
        title: t('common.link'),
        dataIndex: 'alissiaId',
        width: '70px',
        sorter: false,
        key: 'alissiaId',
        render: (_: any, data: any) => {
          return (
            <Link
              className="configLink"
              to={{ pathname: getAlissiaResourceUrl(data.alissiaId) }}
            >
              <LinkOutlined />
            </Link>
          );
        },
      });
    }
    return columnsData;
  },
};

export default trademarkColumns;
