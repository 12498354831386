export const useLocalStorage = () => {
  const get = (key: string): any => {
    const item = localStorage.getItem(key);
    try {
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error('Failed to parse localStorage item:', error);
      return null;
    }
  };

  const add = (key: string, value: any): void => {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error('Failed to serialize value for localStorage:', error);
    }
  };

  return {
    get,
    add,
  };
};
