import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { memo, useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { setCountry, translateCountry } from 'technical/countriesTranslation';
import Flex from 'ui/flex';
import IsLoading from 'ui/isLoading';
import Spacer from 'ui/spacer';
import { handleMapStyle, processMapData } from './mapUtils';
import { MapData, NotShowCountry } from './types';
import styles from './index.module.scss';
import geoData from './countries-geoData.json';
import { ResourceType } from 'business/common/services/types';
import Button from 'ui/button';
import { MAP_HEIGHT, MAP_WIDTH } from './styles';
import MapStatus from './mapStatus';
import { useTranslation } from 'react-i18next';
import NotShownCountries from './notShowCountries';
import { NOT_SHOWN_COUNTRIES } from './constants';

interface MapProps {
  data: MapData;
  isDataLoading: boolean;
  resourceType: ResourceType;
  overrideNotShowCountries?: NotShowCountry[];
}

type Position = {
  coordinates: [number, number];
  zoom: number;
};

const MIN_ZOOM = 1;
const MAX_ZOOM = 4;

const Map = ({ data: rawData, isDataLoading, resourceType }: MapProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [position, setPosition] = useState<Position>({
    coordinates: [0, 45], // Centre France
    zoom: 1,
  });

  const data = useMemo(() => processMapData(rawData), [rawData]);

  useEffect(() => {
    setCountry();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isModalOpen]);

  const notShownCountries = useMemo(
    () =>
      Object.keys(data)
        .filter((countryCode) => NOT_SHOWN_COUNTRIES.includes(countryCode))
        .map((countryCode) => ({
          code: countryCode,
          tooltip: translateCountry(countryCode),
          status: data[countryCode],
        })),
    [data],
  );

  function handleMoveEnd(positionMove: Position) {
    setPosition(positionMove);
  }

  const handleZoomIn = () => {
    setPosition((prev) => ({
      ...prev,
      zoom: Math.min(prev.zoom + 0.5, MAX_ZOOM),
    }));
  };

  const handleZoomOut = () => {
    setPosition((prev) => ({
      ...prev,
      zoom: Math.max(prev.zoom - 0.5, MIN_ZOOM),
    }));
  };

  return (
    <Spacer direction="vertical" size="xxsmall" style={{ width: '50%' }}>
      <Flex className={classNames(styles.parent)}>
        <Flex
          column
          alignContent="flex-end"
          className={styles.mapLegendContainer}
        >
          {/* Boutons de zoom */}
          <Flex justify="flex-end" style={{ gap: 8, marginBottom: 8 }}>
            <Button onClick={handleZoomIn} className={styles.zoomBtn}>
              +
            </Button>
            <Button onClick={handleZoomOut} className={styles.zoomBtn}>
              −
            </Button>
          </Flex>

          <Flex
            column
            alignContent="flex-end"
            className={classNames(styles.mapContainer, 'innerShadow1')}
            style={{ cursor: 'pointer' }}
          >
            <IsLoading loading={isDataLoading}>
              <ComposableMap
                onClick={() => setIsModalOpen(true)} // Ouvre la modal au clic
                projection="geoMercator"
                projectionConfig={{ scale: 120, center: [0, 30] }}
                height={MAP_HEIGHT}
                width={MAP_WIDTH}
              >
                <ZoomableGroup
                  zoom={position.zoom}
                  center={position.coordinates}
                >
                  <Geographies data-tip="" geography={geoData}>
                    {({ geographies }) =>
                      geographies.map((geo) => (
                        <Geography
                          data-tip=""
                          key={geo.rsmKey}
                          geography={geo}
                          style={handleMapStyle(geo, data)}
                          onMouseEnter={() => {
                            setTooltipContent(
                              translateCountry(geo.properties['Alpha-2']),
                            );
                          }}
                          onMouseLeave={() => {
                            setTooltipContent('');
                          }}
                        />
                      ))
                    }
                  </Geographies>
                </ZoomableGroup>
              </ComposableMap>
              <ReactTooltip>
                <div className={styles.tooltip}>{tooltipContent}</div>
              </ReactTooltip>
            </IsLoading>
          </Flex>
        </Flex>
      </Flex>

      {/* Modal avec la carte en grand */}
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={1200}
        className={styles.modal}
      >
        <Flex>
          <Flex column>
            <NotShownCountries
              data={notShownCountries}
              resourceType={resourceType}
            />
            <MapStatus resourceType={resourceType} />
          </Flex>

          <Flex column className={styles.mapContainer}>
            <Flex justify="flex-start" style={{ gap: 8, marginBottom: 12 }}>
              <Button onClick={handleZoomIn} className={styles.zoomBtn}>
                +
              </Button>
              <Button onClick={handleZoomOut} className={styles.zoomBtn}>
                −
              </Button>
            </Flex>

            <ComposableMap
              projection="geoMercator"
              projectionConfig={{ scale: 200, center: [0, 30] }}
              height={600}
              width={900}
            >
              <ZoomableGroup
                zoom={position.zoom}
                center={position.coordinates}
                onMoveEnd={handleMoveEnd}
              >
                <Geographies data-tip="" geography={geoData}>
                  {({ geographies }) =>
                    geographies.map((geo) => (
                      <Geography
                        data-tip={translateCountry(geo.properties['Alpha-2'])}
                        key={geo.rsmKey}
                        geography={geo}
                        style={handleMapStyle(geo, data)}
                        onMouseEnter={() => {
                          setTooltipContent(
                            translateCountry(geo.properties['Alpha-2']),
                          );
                        }}
                        onMouseLeave={() => {
                          setTooltipContent('');
                        }}
                      />
                    ))
                  }
                </Geographies>
              </ZoomableGroup>
            </ComposableMap>

            <div className={styles.description}>
              {t('map.description', { context: resourceType })
                .split('\n')
                .map((paragraph) => (
                  <p key={paragraph}>{paragraph}</p>
                ))}
            </div>
          </Flex>
        </Flex>

        <ReactTooltip
          globalEventOff="click"
          place="top"
          wrapper="div"
          effect="solid"
        />
      </Modal>
    </Spacer>
  );
};

export default memo(Map);
