import { Checkbox, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GetPatentsSearchDto } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import Select from 'ui/select';
import Spacer from 'ui/spacer';
import { useGetFiltersData } from 'business/search/services/filters.service';
import { RangePicker } from 'ui/date/datePicker';
import styles from 'business/search/search.module.scss';

interface IPatentSearchFilters {
  initialValues: GetPatentsSearchDto;
  onFinish: (values: GetPatentsSearchDto) => void;
}
const PatentSearchFilters = (props: IPatentSearchFilters) => {
  const { t } = useTranslation();
  const [form] = useForm<GetPatentsSearchDto>();
  const { countryCode, protectionCode, statuses } = useGetFiltersData(
    t,
    'patent',
  );
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={props.initialValues}
      onFinish={(values) => {
        console.log('values = ', values);
        props.onFinish(values);
      }}
    >
      <Spacer size="small" direction="vertical">
        <Spacer size="small" direction="vertical">
          <Form.Item
            name="title"
            label={t('table.common.column.title', { context: 'patent' })}
          >
            <Input placeholder={t('table.common.column.title')} />
          </Form.Item>
          <Spacer>
            <Form.Item
              name="clientRef"
              label={t('table.common.column.clientRef')}
            >
              <Input placeholder={t('table.common.column.clientRef')} />
            </Form.Item>
            <Form.Item
              name="clientRef2"
              label={t('table.common.column.clientRef2')}
            >
              <Input placeholder={t('table.common.column.clientRef2')} />
            </Form.Item>
          </Spacer>

          <Spacer>
            <Form.Item name="holder" label={t('table.common.column.holder')}>
              <Input placeholder={t('table.common.column.holder')} />
            </Form.Item>

            <Form.Item
              name="effectiveHolder"
              label={t('table.common.column.effectiveHolder')}
            >
              <Input placeholder={t('table.common.column.effectiveHolder')} />
            </Form.Item>
          </Spacer>

          <Spacer>
            <Form.Item
              name="bdlOfficer"
              label={t('table.common.column.bdlOfficer')}
            >
              <Input placeholder={t('table.common.column.bdlOfficer')} />
            </Form.Item>

            <Form.Item
              name="clientOfficer"
              label={t('table.common.column.clientOfficer')}
            >
              <Input placeholder={t('table.common.column.clientOfficer')} />
            </Form.Item>
          </Spacer>

          <Form.Item
            name="status"
            label={t('table.common.column.status')}
            style={{ width: '100%' }}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={t('table.common.column.status')}
              options={statuses}
            />
          </Form.Item>

          <Form.Item
            name="countryCode"
            label={t('table.common.column.country')}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={t('table.common.column.country')}
              options={countryCode}
            />
          </Form.Item>

          <Form.Item
            name="protectionCode"
            label={t('table.common.column.protection')}
            style={{ width: '100%' }}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={t('table.common.column.protection')}
              options={protectionCode}
            />
          </Form.Item>

          <Form.Item
            name="depositDate"
            label={t('table.common.column.depositDate')}
          >
            <RangePicker picker="date" allowClear style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="publicationDate"
            label={t('table.common.column.publicationDate')}
          >
            <RangePicker picker="date" allowClear style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="issueDate"
            label={t('table.common.column.issueDate')}
          >
            <RangePicker picker="date" allowClear style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="inventors"
            label={t('table.common.column.inventors')}
          >
            <Input placeholder={t('table.common.column.inventors')} />
          </Form.Item>

          <Form.Item
            name="ownershipType"
            label={t('table.common.column.ownershipType')}
          >
            <Checkbox.Group
              options={[
                {
                  label: t('pages.advancedSearch.filtersDrawer.ownershipType', {
                    context: 'co',
                  }),
                  value: 'co',
                },
                {
                  label: t('pages.advancedSearch.filtersDrawer.ownershipType', {
                    context: 'mono',
                  }),
                  value: 'mono',
                },
              ]}
            />
          </Form.Item>
        </Spacer>

        <Spacer
          className={styles.filtersButtonContainer}
          direction="vertical"
          size="xxsmall"
        >
          <Button
            data-test-id="searchFilterSubmit"
            type="primary"
            htmlType="submit"
          >
            {t('pages.advancedSearch.filtersDrawer.applyFilters')}
          </Button>
        </Spacer>
      </Spacer>
    </Form>
  );
};

export default PatentSearchFilters;
