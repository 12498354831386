import { links } from 'business/common/services/officialRegister/constants';
import {
  getOfficialLink,
  isProtectionException,
  isProtectionMadridProtocol,
  isProtectionNational,
  replaceLink,
} from 'business/common/services/officialRegister/helpers';
import { OfficialLinkMethods } from 'business/common/services/officialRegister/types';
import { Event } from 'business/resources/events/services/types';
import { differenceInYears, parseISO } from 'date-fns';
import {
  GetTrademarkEventsQuery,
  GetTrademarkEventsQueryVariables,
  GetTrademarkQuery,
  Order_By,
  useGetRelatedTrademarksQuery,
  useGetTrademarkEventsQuery,
  useGetTrademarkLinkQuery,
  useGetTrademarkQuery,
} from 'generated/graphql';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { isBeforeToday } from 'technical/date';
import {
  onlyNumbers,
  pad,
  removeSpaces,
  takeBeforeDot,
} from 'technical/format/string';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { TimelineEvent, createTimelineEvents } from 'technical/timeline';
import { isDefined } from 'technical/validation';

const internationalCountryCode = ['WO', 'MO'];
const isInternational = (countryCode: string) => {
  return internationalCountryCode.includes(countryCode);
};

export const getGrantDate = (trademark: GetTrademarkQuery['trademark_by_pk']) =>
  trademark?.registrationDateMX ||
  trademark?.registrationDateUS ||
  trademark?.registrationDateJP ||
  trademark?.grantDate;

export const getTrademarkTimelineEvents = (
  t: TFunction,
  trademark?: GetTrademarkQuery['trademark_by_pk'],
): TimelineEvent[] => {
  if (!trademark) {
    return [];
  }

  // Liste des évenements Affidavit à afficher
  const trademarkDate = trademark.trademarkDate.filter((d) =>
    [
      'Affidavit',
      'Echeance affidavit',
      'Echéance aff. grace',
      'Borne échéance Affidavit',
      'Borne échéance DIU',
      'Echéance DIU',
      'Echéance P. Usage',
      'Instruction affidavit',
    ].includes(d.title ?? ''),
  );
  const events: TimelineEvent[] = [
    {
      title: t('table.common.column.firstDeposit'),
      date: trademark.depositDate,
      reference: trademark.depositNumber,
    },
    {
      title: t('table.common.column.firstRegistration'),
      date: trademark.firstRegistrationDate,
      reference: trademark.registrationNumber,
    },
    {
      title: t('table.common.column.registration'),
      date: trademark.lastRegistrationDate,
      reference: trademark.registrationNumber,
    },
    {
      title: t('table.common.column.grantDate'),
      date: getGrantDate(trademark),
      reference: trademark.grantNumber,
    },
    ...trademark.priorities.map((p) => ({
      title: t('table.common.column.priority'),
      date: p.priorityDate,
      reference: p.priorityNumber,
    })),
    ...trademarkDate.map((td) => {
      return {
        title: td.title === 'Affidavit' ? 'Affidavit déposé' : td.title ?? '',
        date: td.date,
      };
    }),
  ];

  if (
    trademark.abandonmentDate &&
    isBeforeToday(new Date(trademark.abandonmentDate))
  ) {
    events.push({
      title: t('table.common.column.abandonmentDate'),
      date: trademark.abandonmentDate,
    });
  } else {
    if (
      trademark.nextRenewDate &&
      differenceInYears(new Date(), parseISO(trademark.nextRenewDate)) < 1
    ) {
      events.push({
        title: t('table.common.column.nextAnnuity', { context: 'trademark' }),
        date: trademark.nextRenewDate,
      });
    }
  }
  return createTimelineEvents(events);
};

export const useGetTrademark = (id: number) => {
  const { t } = useTranslation();
  const { data, ...trademarkDataState } = useGetTrademarkQuery({
    variables: { id },
  });

  const trademark = data?.trademark_by_pk ?? null;

  const { data: relatedTrademark } = useGetRelatedTrademarksQuery({
    variables: {
      id: trademark?.id,
      familyId: trademark?.familyId,
      countryCode: trademark?.country?.code,
      depositNumber: trademark?.depositNumber,
    },
    skip: !trademark?.id,
  });

  // récupérer les certificats de la fiche et les certificats des fiches renouvelés
  const relatedTrademarkCertificate = relatedTrademark?.related_trademarks.map(
    (certif) => certif.trademarkCertificate,
  );

  const trademarkCertificate = [
    ...(trademark?.trademarkCertificate ?? []),
    ...(relatedTrademarkCertificate ?? []),
  ].flat();

  return {
    trademark,
    trademarkDataState,
    trademarkCertificate: trademarkCertificate,
    timeline: getTrademarkTimelineEvents(t, trademark),
  };
};

export const useGetTrademarkEvents = (trademarkId: number) =>
  useQueryForTable<
    Event,
    GetTrademarkEventsQuery,
    GetTrademarkEventsQueryVariables
  >(
    {
      subStorageKey: 'trademarkEvents',
      useQuery: ({ limit, offset, orderBy }) =>
        useGetTrademarkEventsQuery({
          variables: { limit, offset, trademarkId, orderBy },
        }),
      getTotal: (res) =>
        res?.event_trademarkEvent_aggregate.aggregate?.count ?? 0,
      map: (res) => res?.event_trademarkEvent ?? [],
    },
    {
      orderBy: {
        dueDate: Order_By.Asc,
      },
    },
  );

export const useGetTrademarkLink = (
  trademark: NonNullable<GetTrademarkQuery['trademark_by_pk']> | null,
) => {
  const { data: trademarkLinks } = useGetTrademarkLinkQuery({
    variables: {
      depositNumber: trademark?.depositNumber ?? '',
      familyId: trademark?.familyId ?? '',
      isInternational: isInternational(trademark?.country?.code ?? ''),
      id: trademark?.id ?? 0,
    },
    skip: trademark === null,
  });

  return {
    trademarkLinks: [
      ...(trademarkLinks?.internationalTrademark ?? []),
      ...(trademarkLinks?.trademarks ?? []),
    ],
  };
};

const TRADEMARK_OFFICIAL_LINKS: OfficialLinkMethods<
  NonNullable<GetTrademarkQuery['trademark_by_pk']>
> = {
  EM: getOfficialLink(
    ({
      depositNumber,
      protection,
    }: NonNullable<GetTrademarkQuery['trademark_by_pk']>) =>
      isDefined(depositNumber) && !isProtectionException(protection),
  )('depositNumber', (depositNumber, td) =>
    isProtectionException(td?.protection)
      ? `W${pad(8)(depositNumber)}`
      : depositNumber,
  )(links.trademark.EM),

  FR: getOfficialLink(
    ({
      lastRegistrationDate,
      registrationNumber,
    }: NonNullable<GetTrademarkQuery['trademark_by_pk']>) =>
      isDefined(lastRegistrationDate) && isDefined(registrationNumber),
  )('registrationNumber', removeSpaces, takeBeforeDot, (registrationNumber) =>
    registrationNumber.length === 9
      ? registrationNumber.substring(2)
      : registrationNumber,
  )(links.trademark.FR),

  WO: getOfficialLink(
    ({
      lastRegistrationDate,
      registrationNumber,
    }: NonNullable<GetTrademarkQuery['trademark_by_pk']>) =>
      isDefined(lastRegistrationDate) &&
      isDefined(registrationNumber) &&
      !Number.isNaN(+removeSpaces(registrationNumber)),
  )(
    'registrationNumber',
    removeSpaces,
    pad(7),
  )(links.trademark.WO),

  US: getOfficialLink(
    ({ depositNumber }: NonNullable<GetTrademarkQuery['trademark_by_pk']>) =>
      isDefined(depositNumber),
  )(
    'depositNumber',
    onlyNumbers,
    pad(7),
  )(({ protection }) => {
    if (isProtectionNational(protection)) {
      return links.trademark.US_NAT;
    }
    if (isProtectionMadridProtocol(protection)) {
      return links.trademark.US_MP;
    }
    return null;
  }),

  // Due to weird conditions and formatting, we need to use a traditionnal function here
  // instead of getOfficialLink
  GB: ({
    depositNumber,
    protection,
  }: NonNullable<GetTrademarkQuery['trademark_by_pk']>) => {
    if (isDefined(depositNumber)) {
      if (isProtectionNational(protection)) {
        return replaceLink(
          links.trademark.GB_NAT,
          pad(11)(removeSpaces(depositNumber.replace(/^(UK)/, ''))),
        );
      }
      if (isProtectionMadridProtocol(protection)) {
        return replaceLink(links.trademark.GB_MP, pad(13)(depositNumber));
      }
    }
    return null;
  },
};

export const getTrademarkOfficialLink = (
  trademark: NonNullable<GetTrademarkQuery['trademark_by_pk']>,
) => {
  if (!trademark.country?.code) {
    return null;
  }
  const method = TRADEMARK_OFFICIAL_LINKS[trademark.country.code];
  return method ? method(trademark) : null;
};
