import { TableProps } from 'antd/lib/table';
import { useMemo, useState } from 'react';

const useTableColumn = (
  initialColumns: Array<
    NonNullable<TableProps<any>['columns']>[number] & { hidden?: boolean }
  >,
) => {
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 30,
    total: 0,
  });

  const [orderByState, setOrderByState] = useState<
    Record<string, 'asc' | 'desc'> | undefined
  >(undefined);

  const [visibleColumns, setVisibleColumns] = useState(
    initialColumns
      .filter((col) => !col.hidden === true)
      .filter((col) => ('checked' in col ? col.checked === true : true))
      .map((col) => col.key)
      .filter((key) => key !== undefined) as string[],
  );

  const filtrableColumns = initialColumns.filter((col) =>
    'alwaysVisible' in col ? col.alwaysVisible === false : true,
  );

  const toggleColumnVisibility = (columnKey: string) => {
    setVisibleColumns(
      (prev) =>
        prev.includes(columnKey)
          ? prev.filter((col) => col !== columnKey) // Retirer la colonne
          : [...prev, columnKey], // Ajouter la colonne
    );
  };
  const handlePagination: TableProps<any>['onChange'] = (
    pagination,
    _filters,
    sorter,
    _extra,
  ) => {
    setPaginationState((prev) => ({
      current: pagination.current || prev.current || 1,
      pageSize: pagination.pageSize || prev.pageSize || 10,
      total: pagination.total || prev.total || 0,
    }));

    let newOrderByState: Record<string, 'asc' | 'desc'> | undefined = undefined;

    if (!Array.isArray(sorter)) {
      const field = Array.isArray(sorter.field)
        ? sorter.field[0]?.toString() || ''
        : sorter.field?.toString() || '';

      if (field) {
        newOrderByState = {
          [field]: sorter.order === 'ascend' ? 'asc' : 'desc',
        };
      }
    } else if (sorter.length > 0) {
      const primarySorter = sorter[0];
      const field = Array.isArray(primarySorter.field)
        ? primarySorter.field[0]?.toString() || ''
        : primarySorter.field?.toString() || '';

      newOrderByState = {
        [field]: primarySorter.order === 'ascend' ? 'asc' : 'desc',
      };
    }

    setOrderByState((prevOrderByState) => {
      const isSortingChanged =
        JSON.stringify(prevOrderByState) !== JSON.stringify(newOrderByState);

      setPaginationState((prev) => ({
        current: isSortingChanged ? 1 : pagination.current || prev.current,
        pageSize: pagination.pageSize || prev.pageSize,
        total: pagination.total || prev.total,
      }));

      return newOrderByState;
    });
  };

  return {
    handlePagination,
    toggleColumnVisibility,
    tableColumns: useMemo(
      () =>
        initialColumns.filter(
          (col) =>
            visibleColumns.includes(col.key as string) ||
            ('alwaysVisible' in col ? col.alwaysVisible === true : false),
        ),
      [visibleColumns, initialColumns],
    ),
    visibleColumns,
    filtrableColumns,
    paginationState,
    orderByState,
  };
};

export default useTableColumn;
