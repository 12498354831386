import Routes from 'business/router/routes';
import { useTranslation } from 'react-i18next';
import history from 'technical/history';
import { useEffect, useState } from 'react';
import { useQuickSearchDataLazyQuery } from 'generated/graphql';
import logger from 'technical/logger';
import Flex from 'ui/flex';
import Loader from 'ui/loader';
import { AutoCompleteProps, Divider, Typography } from 'antd';
import Spacer from 'ui/spacer';
import { useHistory } from 'react-router';

export const useSetDocumentTitle = ({
  translationKey,
}: {
  translationKey?: string;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `BDL-IP Access ${
      translationKey ? `- ${t(`metadata.${translationKey}`)}` : ''
    }`;
  }, [translationKey]);
};

export const useQuickSearch = () => {
  const redirect = useHistory();
  const [searchQuery, { loading }] = useQuickSearchDataLazyQuery();
  const [quickSearchData, setQuickSearchData] = useState<
    AutoCompleteProps['options']
  >([]);

  const renderItem = (data: {
    title?: string | null;
    bdlRef?: string | null;
    clientRef?: string | null;
    id?: any;
    familyId?: string | null;
    type: string;
  }) => {
    return {
      value: data.title,
      key: data.id,
      label: (
        <Flex key={data.id} justify="space-between">
          <Flex style={{ width: '50%' }}>
            <Typography.Paragraph ellipsis>{data.title}</Typography.Paragraph>
          </Flex>
          <Spacer size="small" justify="end">
            <Typography.Link
              onClick={() =>
                history.push(`/${data.type}/family/${data.familyId}`)
              }
            >
              Vue famille
            </Typography.Link>
          </Spacer>
        </Flex>
      ),
    };
  };

  const Title: React.FC<Readonly<{ title?: string; search: string }>> = (
    props,
  ) => (
    <Spacer direction="vertical" size="xxsmall">
      <Divider />
      <Spacer align="center" justify="space-between">
        <Typography.Title style={{ margin: 0 }} level={5}>
          {props.title}
        </Typography.Title>
        <Typography.Link
          onClick={() => redirect.push(`${Routes.Search}?${props.search}`)}
        >
          Voir tous les résultats
        </Typography.Link>
      </Spacer>
      <Divider />
    </Spacer>
  );

  const quickSearch = async (search: string) => {
    try {
      if (!search) {
        return;
      }
      setQuickSearchData(undefined);
      const { data } = await searchQuery({
        fetchPolicy: 'no-cache',
        variables: {
          data: { search },
        },
      });
      const quickSearchResultOptions = [];
      if (data?.quickSearch?.trademark?.length) {
        quickSearchResultOptions.push({
          label: <Title title="Marques" search={search} />,
          options: data?.quickSearch?.trademark?.map((t) =>
            renderItem({
              title: t?.title,
              clientRef: t?.clientRef,
              bdlRef: t?.bdlRef,
              id: t?.id,
              familyId: t?.familyId,
              type: 'trademark',
            }),
          ),
        });
      }
      if (data?.quickSearch?.patent?.length) {
        quickSearchResultOptions.push({
          label: <Title title="Brevets" search={search} />,
          options: data?.quickSearch?.patent?.map((t) =>
            renderItem({
              title: t?.title,
              clientRef: t?.clientRef,
              bdlRef: t?.bdlRef,
              id: t?.id,
              familyId: t?.familyId,
              type: 'patent',
            }),
          ),
        });
      }
      if (data?.quickSearch?.design?.length) {
        quickSearchResultOptions.push({
          label: <Title title="Modèles" search={search} />,
          options: data?.quickSearch?.design?.map((t) =>
            renderItem({
              title: t?.title,
              clientRef: t?.clientRef,
              bdlRef: t?.bdlRef,
              id: t?.id,
              familyId: t?.familyId,
              type: 'design',
            }),
          ),
        });
      }
      if (data?.quickSearch?.domain?.length) {
        quickSearchResultOptions.push({
          label: <Title title="NDD" search={search} />,
          options: data?.quickSearch?.domain?.map((t) =>
            renderItem({
              title: t?.title,
              clientRef: t?.clientRef,
              bdlRef: t?.bdlRef,
              id: t?.id,
              familyId: t?.familyId,
              type: 'domain',
            }),
          ),
        });
      }
      setQuickSearchData(quickSearchResultOptions);
    } catch (err) {
      logger.error({
        mesage: 'Error quick search',
        err,
      });
    }
  };

  return {
    quickSearch,
    loading,
    options: loading
      ? [
          {
            label: <Loader />,
            options: [],
          },
        ]
      : quickSearchData,
  };
};
