import { Input as AntdInput } from 'antd';
import { SearchProps } from 'antd/es/input';
import { SearchOutlined } from '@ant-design/icons';
import { useDebounce } from 'technical/hooks/useDebounce';
import { useEffect } from 'react';

interface ISearchInput extends SearchProps {
  onDebouncedChange: (value: string) => void; // Appelé après le debounce
  debounceDelay?: number; // Temps de debounce en ms
  value?: string;
}

const SearchInput = (props: ISearchInput) => {
  const {
    debouncedValue,
    setValue,
    value: dValue,
  } = useDebounce({
    defaultValue: props.value,
    delay: props.debounceDelay,
  });

  useEffect(() => {
    props.onDebouncedChange(debouncedValue ?? ''); // Utilise debouncedValue
  }, [debouncedValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <AntdInput
      {...props}
      className={'searchInput'}
      prefix={<SearchOutlined />}
      size="large"
      placeholder={props.placeholder}
      onChange={handleChange}
      value={dValue}
    />
  );
};

export default SearchInput;
