import {
  GetDesignEventsQuery,
  GetDesignEventsQueryVariables,
  GetDesignQuery,
  Order_By,
  useGetDesignEventsQuery,
  useGetDesignLinkQuery,
  useGetDesignQuery,
} from 'generated/graphql';
import {
  pad,
  pipe,
  removeDashAndAfter,
  takeAfterSlash,
} from 'technical/format/string';
import { isDefined } from 'technical/validation';

import { useTranslation } from 'react-i18next';
import { useGetDesignImagesByIds } from './designImage.service';
import { TFunction } from 'i18next';
import { TimelineEvent, createTimelineEvents } from 'technical/timeline';
import { isBeforeToday } from 'technical/date';
import { isResourceNotEffective } from 'business/common/services';
import { Design, DesignWithImages } from './types';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';
import { Event } from 'business/resources/events/services/types';
import { useResourceBreadcrumbs } from 'business/common/services/breadcrumbs';
import Routes from 'business/router/routes';
import { OfficialLinkMethods } from 'business/common/services/officialRegister/types';
import {
  getOfficialLink,
  replaceLink,
} from 'business/common/services/officialRegister/helpers';
import { links } from 'business/common/services/officialRegister/constants';

export const getDesignTimeline = (
  t: TFunction,
  design: GetDesignQuery['design_by_pk'],
) => {
  if (!design) {
    return [];
  }
  const nextRegistration = design?.events ?? [];
  const timelineEvents: TimelineEvent[] = [
    {
      title: t('table.common.column.deposit'),
      date: design?.depositDate,
      reference: design?.depositNumber,
    },
    {
      title: t('table.common.column.registration'),
      date: design?.registrationDate,
      reference: design?.registrationNumber,
    },
  ];

  if (
    design?.abandonmentDate &&
    isBeforeToday(new Date(design.abandonmentDate)) &&
    isResourceNotEffective(design)
  ) {
    // If the design is abandonned. No need to display next events but we display the abandoment date instead
    timelineEvents.push({
      title: t('table.common.column.abandonment'),
      date: design.abandonmentDate,
      type: 'error',
    });
  } else if (
    design.expirationDate &&
    isBeforeToday(new Date(design.expirationDate))
  ) {
    timelineEvents.push({
      title: t('table.common.column.expiration'),
      date: design.expirationDate,
      type: 'error',
    });
  } else {
    timelineEvents.push(
      ...nextRegistration.map((n) => ({
        title: t('table.common.column.nextAnnuity', { context: 'design' }),
        date: n.dueDate,
      })),
    );
  }
  return createTimelineEvents(timelineEvents);
};

export const useGetDesign = (id: number) => {
  const { t } = useTranslation();
  const { data, ...designDataState } = useGetDesignQuery({
    variables: { id },
  });

  const { imageList: imageData, loading } = useGetDesignImagesByIds([id]);

  const design = data?.design_by_pk;

  return {
    design: design
      ? {
          ...design,
          images: imageData.map((i) => i.image).filter(isDefined),
        }
      : null,
    images: imageData,
    imageLoading: loading,
    timeline: getDesignTimeline(t, design),
    designDataState,
  };
};

const internationalCountryCode = ['WO', 'MO'];
const isInternational = (countryCode: string) => {
  return internationalCountryCode.includes(countryCode);
};

export const useGetDesignLinks = (design: Design | null) => {
  const { data: designLinks } = useGetDesignLinkQuery({
    variables: {
      depositNumber: design?.depositNumber ?? '',
      familyId: design?.familyId ?? '',
      isInternational: isInternational(design?.country?.code ?? ''),
      id: design?.id ?? 0,
    },
    skip: design === null,
  });

  return {
    designLinks: [
      ...(designLinks?.internationalDesign ?? []),
      ...(designLinks?.designs ?? []),
    ],
  };
};

export const useGetDesignEvents = (designId: number) =>
  useQueryForTable<Event, GetDesignEventsQuery, GetDesignEventsQueryVariables>(
    {
      subStorageKey: storageKeys.designEvents,
      useQuery: ({ limit, offset, orderBy }) =>
        useGetDesignEventsQuery({
          variables: { limit, offset, designId, orderBy },
        }),
      getTotal: (res) => res?.event_designEvent_aggregate.aggregate?.count ?? 0,
      map: (res) => res?.event_designEvent ?? [],
    },
    {
      orderBy: {
        dueDate: Order_By.Asc,
      },
    },
  );

export const useDesignBreadcrumbs = () =>
  useResourceBreadcrumbs({
    type: 'design',
    familyRoute: Routes.DesignFamily,
    rootRoute: Routes.Design,
  });

const createEuropeanLink = (design: DesignWithImages) => {
  const { depositNumber, images } = design;
  if (!isDefined(depositNumber)) {
    return null;
  }
  // Rule extracted from V1
  if (depositNumber.includes('-') && !depositNumber.includes('/')) {
    return replaceLink(links.design.EM, depositNumber);
  }
  const modelId = pipe(removeDashAndAfter, pad(9))(depositNumber, design);
  const models = images.length ? images : [null];
  return models.map((_, i) =>
    replaceLink(links.design.EM, `${modelId}-${pad(4)((i + 1).toString())}`),
  );
};

const DESIGN_OFFICIAL_LINKS: OfficialLinkMethods<DesignWithImages> = {
  WO: getOfficialLink<DesignWithImages>()('registrationNumber', takeAfterSlash)(
    links.design.WO,
  ),

  // For GB and EM (European Union) countryCode,  we need to create a link that direct to the european office website
  EM: (design: DesignWithImages) => createEuropeanLink(design),
  GB: (design: DesignWithImages) => {
    if (!isDefined(design.depositNumber)) {
      return null;
    }
    return replaceLink(links.design.GB, design.depositNumber);
  },

  FR: ({ depositNumber, images }: DesignWithImages) => {
    if (!isDefined(depositNumber)) {
      return null;
    }
    if (depositNumber.includes('-')) {
      return replaceLink(links.design.FR, depositNumber);
    }
    const models = images.length ? images : [null];
    return models.map((_, i) =>
      replaceLink(
        links.design.FR,
        `${depositNumber}-${pad(3)((i + 1).toString())}`,
      ),
    );
  },

  CN: ({ depositNumber }: DesignWithImages) => {
    if (!isDefined(depositNumber)) {
      return null;
    }
    return replaceLink(links.design.CN, depositNumber.replace(',', '.'));
  },

  RU: getOfficialLink<DesignWithImages>()('registrationNumber')(
    links.design.RU,
  ),
};

export const getDesignOfficialLinks = (design: DesignWithImages) => {
  let countryCode = design.country?.code;
  if (!countryCode) {
    return null;
  }
  if (design.protection?.protectionLabel === 'Modèle international') {
    countryCode = 'WO';
  }

  const method = DESIGN_OFFICIAL_LINKS[countryCode];
  return method ? method(design) : null;
};
