import { Button, ButtonProps } from 'antd';
import { MailProps, formatMailTo } from 'technical/mailto';
import styles from './index.module.scss';

type MailButtonProps = ButtonProps & MailProps;

const MailButton: React.FC<MailButtonProps> = ({
  to,
  cc,
  bcc,
  subject,
  body,
  children,
  ...props
}) => {
  return (
    <Button
      className={styles.mailButton}
      {...props}
      onClick={(e) => {
        window.location.href = formatMailTo({ to, cc, bcc, subject, body });
        e.preventDefault();
      }}
    >
      {children}
    </Button>
  );
};

export default MailButton;
