import { Checkbox, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GetDesignEventsFilters } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import Select from 'ui/select';
import Spacer from 'ui/spacer';
import { useGetFiltersData } from 'business/search/services/filters.service';
import { RangePicker } from 'ui/date/datePicker';

interface IDesignEventsFilters {
  initialValues: GetDesignEventsFilters;
  onFinish: (values: GetDesignEventsFilters) => void;
}
const DesignEventsFilters = (props: IDesignEventsFilters) => {
  const { t } = useTranslation();
  const [form] = useForm<GetDesignEventsFilters>();
  const { countryCode, eventTypes } = useGetFiltersData(t, 'design', true);
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={props.initialValues}
      onFinish={(values) => {
        props.onFinish(values);
      }}
    >
      <Spacer size="small" direction="vertical">
        <Spacer>
          <Form.Item
            style={{ display: 'flex' }}
            valuePropName="checked"
            name="adjournedPublication"
            label={t('table.common.column.adjournedPublication')}
          >
            <Checkbox />
          </Form.Item>
        </Spacer>
        <Form.Item name="dueDate" label={t('table.common.column.dueDate')}>
          <RangePicker picker="date" allowClear style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="completionDate"
          label={t('table.common.column.completionDate')}
        >
          <RangePicker picker="date" allowClear style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="type"
          label={t('table.common.column.eventType')}
          style={{ width: '100%' }}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder={t('table.common.column.eventType')}
            options={eventTypes ?? []}
          />
        </Form.Item>

        <Form.Item name="countryCode" label={t('table.common.column.country')}>
          <Select
            mode="multiple"
            allowClear
            placeholder={t('table.common.column.country')}
            options={countryCode}
          />
        </Form.Item>

        <Form.Item
          name="depositDate"
          label={t('table.common.column.depositDate')}
        >
          <RangePicker picker="date" allowClear style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="publicationDate"
          label={t('table.common.column.publicationDate')}
        >
          <RangePicker picker="date" allowClear style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name="issueDate" label={t('table.common.column.issueDate')}>
          <RangePicker picker="date" allowClear style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="title"
          label={t('table.common.column.title', { context: 'design' })}
        >
          <Input placeholder={t('table.common.column.title')} />
        </Form.Item>

        <Form.Item name="holder" label={t('table.common.column.holder')}>
          <Input placeholder={t('table.common.column.holder')} />
        </Form.Item>

        <Form.Item
          name="bdlOfficer"
          label={t('table.common.column.bdlOfficer')}
        >
          <Input placeholder={t('table.common.column.bdlOfficer')} />
        </Form.Item>

        <Form.Item
          name="clientOfficer"
          label={t('table.common.column.clientOfficer')}
        >
          <Input placeholder={t('table.common.column.clientOfficer')} />
        </Form.Item>
      </Spacer>

      <Button type="primary" htmlType="submit">
        Sauvegarder
      </Button>
    </Form>
  );
};

export default DesignEventsFilters;
