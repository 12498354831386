import {
  BreadcrumbResource,
  useResourceBreadcrumbs,
} from 'business/common/services/breadcrumbs';
import Routes from 'business/router/routes';
import {
  GetDomainEventsQuery,
  GetDomainEventsQueryVariables,
  GetDomainQuery,
  useGetDomainDnsDataQuery,
  useGetDomainEventsQuery,
  useGetDomainQuery,
  useGetPrivateDnsDataQuery,
} from 'generated/graphql';
import { DomainOfFamily } from './domain.types';
import { TFunction } from 'i18next';
import { isDefined } from 'technical/validation';
import { createTimelineEvents } from 'technical/timeline';
import { useTranslation } from 'react-i18next';
import { OrderBy } from 'technical/filters/parser/commonParser/orderByParser';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { Event } from 'business/resources/events/services/types';

const GANDI_COMPANY_REF_NO_SPACES = 'GANDICORPORATE/0';
const DOMAIN_EXTENSION_REGEX = /\..+/;

export const isGandiDomain = (registrar?: string | null) =>
  registrar?.replace(/\s/g, '') === GANDI_COMPANY_REF_NO_SPACES;

const removeExtension = (domain: string) =>
  domain.replace(DOMAIN_EXTENSION_REGEX, '');

export const getDomainTimelineEvents = (
  t: TFunction,
  domain: GetDomainQuery['domain_by_pk'],
) => {
  if (!domain) {
    return [];
  }
  const operations =
    domain.timelineEvents
      .map(({ gandiOperation }) => {
        if (!gandiOperation) {
          return null;
        }
        const { type, operationDate } = gandiOperation;
        return {
          title: t('table.common.column.domainType', {
            context: type,
          }),
          date: operationDate,
        };
      })
      .filter(isDefined) ?? [];

  if (domain.expirationDate) {
    operations.push({
      title: 'expiration',
      date: domain.expirationDate,
    });
  }

  if (domain.reservationDate) {
    operations.push({
      title: 'reservation',
      date: domain.reservationDate,
    });
  }
  console.log('op ==', operations);
  return createTimelineEvents(operations);
};

export const useDomainBreadcrumbs = () => {
  const { getResourceBreadcrumbs, getFamilyBreadcrumbs } =
    useResourceBreadcrumbs({
      type: 'domain',
      familyRoute: Routes.DomainFamily,
      rootRoute: Routes.Domain,
    });

  return {
    getFamilyBreadcrumbs: (domain: Pick<DomainOfFamily, 'title'> | null) =>
      getFamilyBreadcrumbs(
        domain
          ? {
              ...domain,
              title: removeExtension(domain.title ?? ''),
            }
          : null,
      ),
    getResourceBreadcrumbs: (
      domain: null | Pick<
        NonNullable<GetDomainQuery['domain_by_pk']>,
        keyof BreadcrumbResource
      >,
    ) =>
      getResourceBreadcrumbs(
        domain
          ? {
              ...domain,
              title: removeExtension(domain.title),
              country: {
                code: domain.title,
                labelEn: domain.title,
                labelFr: domain.title,
              },
            }
          : null,
      ),
  };
};

export const useGetDomainData = (id: number) => {
  const { t } = useTranslation();
  const { data, error, loading } = useGetDomainQuery({ variables: { id } });
  const { getResourceBreadcrumbs } = useDomainBreadcrumbs();

  const domain = data?.domain_by_pk ?? null;

  return {
    data: data?.domain_by_pk,
    error,
    loading,
    timeline: getDomainTimelineEvents(t, domain),
    isGandiDomain: isGandiDomain(domain?.registrar),
    breadcrumbs: getResourceBreadcrumbs(domain),
  };
};

const generateOrderBy = (orderBy: OrderBy<Event>) => {
  if ('dueDate' in orderBy) {
    return { gandiOperation: { operationDate: orderBy.dueDate } };
  }
  if ('type' in orderBy) {
    return { gandiOperation: { operationDate: orderBy.type } };
  }
  return orderBy;
};

export const useGetDomainEvents = (domainId: number) => {
  const { t } = useTranslation();

  return useQueryForTable<
    Event,
    GetDomainEventsQuery,
    GetDomainEventsQueryVariables
  >({
    subStorageKey: 'domainEvents',
    useQuery: ({ limit, offset, orderBy }) =>
      useGetDomainEventsQuery({
        variables: {
          limit,
          offset,
          domainId,
          orderBy: generateOrderBy(orderBy),
        },
      }),
    getTotal: (res) => res?.event_domainEvent_aggregate.aggregate?.count ?? 0,
    map: (res) =>
      res?.event_domainEvent.map((event) => {
        return {
          id: event.id,
          dueDate: event.gandiOperation?.operationDate,
          completionDate: event.completionDate,
          reminderDate: event.reminderDate,
          type: t('table.common.column.domainType', {
            context: event.gandiOperation?.type ?? '',
          }),
        };
      }) ?? [],
  });
};

export const useGetPublicDns = (domainId: number) => {
  const { data, ...publicDomainDNSDataState } = useGetDomainDnsDataQuery({
    variables: { id: domainId },
    fetchPolicy: 'no-cache',
  });

  const dns = data?.domain_by_pk?.dns ?? null;

  const zones = (dns?.records ?? []).map(
    ({ address: value, domainName: name, ...rest }) => ({
      ...rest,
      name,
      value,
    }),
  );

  const servers =
    dns?.servers?.map((server, i) => ({
      serverName: `DNS ${i + 1}`,
      server: server.address,
    })) ?? [];

  return {
    zones,
    publicDomainDNSDataState,
    servers,
    certificate: dns?.certificate,
  };
};

export const useGetPrivateDns = ({
  domainId,
  skip,
}: {
  domainId: number;
  skip: boolean;
}) => {
  const { data, ...privateDomainDNSDataState } = useGetPrivateDnsDataQuery({
    variables: { id: domainId },
    fetchPolicy: 'no-cache',
    skip,
  });

  const servers =
    data?.domain_by_pk?.privateDns?.nameservers?.map((server, i) => ({
      serverName: `DNS ${i + 1}`,
      server,
    })) ?? [];

  const zones = data?.domain_by_pk?.privateDns?.zones ?? [];

  return {
    servers,
    privateDomainDNSDataState,
    zones,
  };
};

export const useGetDomainInvoicesById = (domainId: number) => {
  console.log(domainId);

  return {
    domainInvoices: [],
  };
};
