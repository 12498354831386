import { useGetDomainFamiliesQuery } from 'generated/graphql';

export const useGetDomainFamilies = (variables: {
  limit: number;
  offset: number;
  search?: string;
  isEffective?: boolean;
  isOwn?: boolean;
  orderBy?: Record<string, 'asc' | 'desc'> | null;
}) => {
  const { data, error, loading } = useGetDomainFamiliesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        filters: {
          isEffective: variables.isEffective,
          isOwn: variables.isOwn,
          search: variables.search,
        },
        limit: variables.limit,
        offset: variables.offset,
        orderBy: variables.orderBy ? variables.orderBy : { domain: 'asc' },
      },
    },
  });

  return {
    data: data?.getDomainFamilies?.resources ?? [],
    total: data?.getDomainFamilies?.count ?? 0,
    error,
    loading,
  };
};
