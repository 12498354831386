import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderBy } from 'technical/filters/parser/commonParser/orderByParser';
import {
  formatCheckedColumnsToVisibleKey,
  generateColumns,
  generateColumnsEditionItems,
  generateDefaultVisibleColumnsKey,
} from './columns';
import { ColumnGenerationData } from './types';

interface UseTableProps<T> {
  defaultColumns: ColumnGenerationData<T>;
  storageColumnsKeys: (keyof T)[] | null;
  setStorageVisibleColumnsKey: (columnsKeys: (keyof T)[]) => void;
  defaultSort?: OrderBy<T>;
}
const useTable = <T>({
  defaultColumns,
  storageColumnsKeys,
  setStorageVisibleColumnsKey,
  defaultSort = {},
}: UseTableProps<T>) => {
  const { t } = useTranslation();

  const defaultVisibleColumnsKey = useMemo(
    () => generateDefaultVisibleColumnsKey(defaultColumns),
    [],
  );

  const [visibleColumnsKey, setVisibleColumnsKey] = useState(
    storageColumnsKeys ?? defaultVisibleColumnsKey,
  );

  const formattedDefaultColumns = useMemo(
    () => generateColumns(defaultColumns, t, defaultSort),
    [],
  );

  const columnsProperties = useMemo(
    () => defaultColumns.map((actionColumn) => actionColumn.key),
    [],
  );

  const columnsEditionItems = useMemo(
    () => generateColumnsEditionItems(formattedDefaultColumns),
    [],
  );

  const columnsAndColumnsEditionItems = useMemo(
    () => ({
      columns: formattedDefaultColumns.filter(
        (col) => visibleColumnsKey.includes(col.key) || col.alwaysVisible,
      ),
      columnsEditionItems: columnsEditionItems.map((item) => ({
        ...item,
        value: visibleColumnsKey.includes(item.key as keyof T),
      })),
    }),
    [visibleColumnsKey],
  );

  return {
    onChangeColumnsVisibility: (checkedColumnsKey: CheckboxValueType[]) => {
      const visibleKeys = formatCheckedColumnsToVisibleKey(
        checkedColumnsKey,
        columnsProperties,
      );
      setVisibleColumnsKey(visibleKeys);
      setStorageVisibleColumnsKey(visibleKeys);
    },
    resetTable: () => {
      setVisibleColumnsKey(defaultVisibleColumnsKey);
      setStorageVisibleColumnsKey(defaultVisibleColumnsKey);
    },
    ...columnsAndColumnsEditionItems,
  };
};

export default useTable;
