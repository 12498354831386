import {
  InfoCircleOutlined,
  PaperClipOutlined,
  TeamOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { Card, Col, Divider, Row } from 'antd';
import { GetDomainQuery } from 'generated/graphql';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import WhoIsButton from './whoIs';
import SimpleLink from 'ui/link/SimpleLink';
import Status from 'business/common/components/status';

interface IDomainInformation {
  domain: GetDomainQuery['domain_by_pk'];
}

const DomainInformation = ({ domain }: IDomainInformation) => {
  return (
    <Flex>
      <Card className="caseDetailCardFullWidth">
        {/* Colonne des informations */}
        <Row>
          <Col span={2}>
            <InfoCircleOutlined />
          </Col>
          <Col span={6}>
            <Spacer size="xxsmall" direction="vertical">
              <span className="generalInformationLabel">STATUT</span>
              <span className="generalInformationValue">
                <Status status={domain?.status ?? ''} />
              </span>
            </Spacer>
          </Col>
          <Col span={6}>
            <Spacer size="xxsmall" direction="vertical">
              <span className="generalInformationLabel">PAYS</span>
              <span className="generalInformationValue">
                {domain?.country?.labelFr}
              </span>
            </Spacer>
          </Col>
          <Col span={6}>
            <span className="generalInformationLabel">WhoIs</span>
            <WhoIsButton domain={domain?.title ?? ''} />
          </Col>
        </Row>

        <Divider />
        <Row>
          <Col span={2}>
            <UsergroupDeleteOutlined />
          </Col>
          <Col span={6}>
            <Spacer size="xxsmall" direction="vertical">
              <span className="generalInformationLabel">TITULAIRE</span>
              <span className="generalInformationValue">{domain?.holder}</span>
            </Spacer>
          </Col>
          <Col span={6}>
            <span className="generalInformationLabel">Lien vers WWW</span>
            <Spacer size="xxsmall" direction="vertical">
              <SimpleLink
                link={`https://${domain?.title}`}
                label={`https://${domain?.title}`}
                displayIcon={false}
                className="whiteSpaceNoBreak"
              />
              <SimpleLink
                link={`https://www.${domain?.title}`}
                label={`https://www.${domain?.title}`}
                displayIcon={false}
                className="whiteSpaceNoBreak"
              />
            </Spacer>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={2}>
            <PaperClipOutlined />
          </Col>
          <Col span={6}>
            <Spacer size="xxsmall" direction="vertical">
              <span className="generalInformationLabel">REF. BDL</span>
              <span className="generalInformationValue">{domain?.bdlRef}</span>
            </Spacer>
          </Col>
          <Col span={6}>
            {/* <Spacer size="xxsmall" direction="vertical">
              <span className="generalInformationLabel">REF. CLIENT</span>
              <span className="generalInformationValue">
                {domain?.clientRef}
              </span>
            </Spacer> */}
          </Col>
          <Col span={6}>
            {/* <Spacer size="xxsmall" direction="vertical">
              <span className="generalInformationLabel">REF. CLIENT 2</span>
              <span className="generalInformationValue">
                {domain?.clientRef2}
              </span>
            </Spacer> */}
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={2}>
            <TeamOutlined />
          </Col>
          <Col span={6}>
            <Spacer size="xxsmall" direction="vertical">
              <span className="generalInformationLabel">RESP. CLIENT</span>
              <span className="generalInformationValue">
                {domain?.clientOfficer}
              </span>
            </Spacer>
          </Col>
          <Col span={6}>
            <Spacer size="xxsmall" direction="vertical">
              <span className="generalInformationLabel">RESP. BDL</span>
              <span className="generalInformationValue">
                {domain?.bdlOfficer?.fullName}
              </span>
            </Spacer>
          </Col>
          <Col span={6}>
            <Spacer size="xxsmall" direction="vertical">
              <span className="generalInformationLabel">SECR. BDL</span>
              <span className="generalInformationValue">
                {domain?.bdlSecretary?.fullName}
              </span>
            </Spacer>
          </Col>
        </Row>
      </Card>
    </Flex>
  );
};

export default DomainInformation;
