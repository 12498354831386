import { useGetClaims } from 'business/patent/services/patentClaims.service';
import { useTranslation } from 'react-i18next';
import List from 'ui/list';
import Spacer from 'ui/spacer';

interface IClaims {
  patentId: number;
}

const Claims = ({ patentId }: IClaims) => {
  const { claims, type, loading, error, refetch } = useGetClaims(patentId);
  const { t } = useTranslation();
  const title = type
    ? t('pages.patent.sections.claims.claims', { context: type })
    : t('pages.patent.sections.claims.claims');
  return (
    <Spacer direction="vertical">
      <h1 className="caseClaimTitle">{title}</h1>
      <List loading={loading} error={error} data={claims} refetch={refetch} />
    </Spacer>
  );
};

export default Claims;
