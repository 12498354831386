import React, { useState } from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import useTableColumn from 'technical/table/useTableColumn';
import trademarkColumns from 'business/trademark/components/columns.constant';
import {
  useDownloadTrademarkFamilies,
  useGetTrademarkFamilies,
} from 'business/trademark/services/trademarkFamilies.service';
import { Checkbox, Tabs } from 'antd';
import Routes from 'business/router/routes';
import { Link } from 'react-router-dom';
import Flex from 'ui/flex';
import TagResults from 'ui/tag/tagResults';
import SearchInput from 'ui/v2/input/searchInput';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import MosaicList from 'business/trademark/components/mosaicList';
import Button from 'ui/button';
import Table from 'ui/v2/table/table';

const TrademarkFamiliesPage: React.FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isEffective, setIsEffective] = useState(true);
  const columns = trademarkColumns.trademarkFamilies(t);
  const trademaekFamiliesColumns = useTableColumn(columns);

  const { download, loading: loadingExport } = useDownloadTrademarkFamilies(
    columns,
    trademaekFamiliesColumns.visibleColumns,
    {
      isEffective,
      search,
    },
  );

  const { trademarkWithImages, loading, total } = useGetTrademarkFamilies({
    isEffective: isEffective,
    search: search,
    limit: trademaekFamiliesColumns.paginationState.pageSize,
    offset:
      (trademaekFamiliesColumns.paginationState.current - 1) *
      trademaekFamiliesColumns.paginationState.pageSize,
    orderBy: trademaekFamiliesColumns.orderByState,
  });

  return (
    <ConnectedPage translationKey="trademarks">
      <Spacer direction="vertical" size="small" style={{ marginTop: '35px' }}>
        <Spacer align="center" size="xxsmall">
          <h1 className="headerTitle">{t('pages.header.trademarkFamilies')}</h1>
          <TagResults total={total} dataTestId="trademarkTotal" />
        </Spacer>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: 'Tableau',
              children: (
                <Table
                  columns={trademaekFamiliesColumns.tableColumns}
                  dataTestId="designFamiliesTable"
                  dataSource={trademarkWithImages ?? []}
                  onChange={trademaekFamiliesColumns.handlePagination}
                  loading={loading}
                  pagination={{
                    current: trademaekFamiliesColumns.paginationState.current,
                    pageSize: trademaekFamiliesColumns.paginationState.pageSize,
                    total,
                  }}
                  actions={
                    <Flex alignItems="center" justify="space-between">
                      <Spacer size="xsmall" align="center" justify="start">
                        <SearchInput
                          value={search}
                          onDebouncedChange={(e) => setSearch(e)}
                          placeholder={t('common.search', { context: 'table' })}
                          style={{ width: '30%' }}
                        />
                        <EditColumnsButton
                          initialColumns={columns}
                          visibleColumns={
                            trademaekFamiliesColumns.visibleColumns
                          }
                          onChange={
                            trademaekFamiliesColumns.toggleColumnVisibility
                          }
                        />
                        <Checkbox
                          checked={isEffective}
                          onChange={(e) => setIsEffective(e.target.checked)}
                        >
                          {t('table.common.effective')}
                        </Checkbox>
                      </Spacer>
                      <Button
                        loading={loadingExport}
                        data-test-id="exportButton"
                        type="primary"
                        onClick={() => download()}
                      >
                        {t('table.common.exportButton')}
                      </Button>
                    </Flex>
                  }
                />
              ),
            },
            {
              key: '2',
              label: 'Mosaique',
              children: (
                <MosaicList<any>
                  actions={
                    <>
                      <TagResults total={total} dataTestId="trademarkTotal" />
                      <SearchInput
                        value={search}
                        onDebouncedChange={(e) => setSearch(e)}
                        placeholder={t('common.search', { context: 'table' })}
                        style={{ width: '30%' }}
                      />

                      <Link
                        to={{
                          pathname: Routes.Search,
                          search: `?family=trademark&filtersOpen=true`,
                        }}
                      >
                        <Button
                          data-test-id="filterButton"
                          className="searchButton"
                          type="primary"
                        >
                          {t('table.common.advancedSearch')}
                        </Button>
                      </Link>
                      <Button
                        loading={loadingExport}
                        data-test-id="exportButton"
                        type="primary"
                        onClick={() => download()}
                      >
                        {t('table.common.exportButton')}
                      </Button>
                      <Checkbox
                        checked={isEffective}
                        onChange={(e) => setIsEffective(e.target.checked)}
                      >
                        {t('table.common.effective')}
                      </Checkbox>
                    </>
                  }
                  data={trademarkWithImages}
                  config={{
                    pagination: {
                      current: trademaekFamiliesColumns.paginationState.current,
                      pageSize:
                        trademaekFamiliesColumns.paginationState.pageSize,
                      total,
                      onChange: (current, pageSize) =>
                        trademaekFamiliesColumns.handlePagination(
                          {
                            current: current,
                            pageSize: pageSize,
                          },
                          {},
                          {},
                          {
                            currentDataSource: [],
                            action: 'paginate',
                          },
                        ),
                    },
                    loading: loading,
                  }}
                />
              ),
            },
          ]}
        ></Tabs>
      </Spacer>
    </ConnectedPage>
  );
};

export default TrademarkFamiliesPage;
