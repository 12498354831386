import DatePicker, { RangePicker } from 'ui/date/datePicker';

interface Filter {
  from: Date | null;
  to: Date | null;
}

type DatePickerProps = Omit<
  (typeof DatePicker)['propTypes'],
  'onChange' | 'value'
>;

interface DateRangeTableFilterProps<T extends Filter> {
  filtersValues: T;
  setFiltersValues: (value: T) => void;

  fromDateFilterProps?: DatePickerProps;
  toDateFilterProps?: DatePickerProps;
}

const DateRangeTableFilter = <T extends Filter>({
  filtersValues,
  setFiltersValues,
}: DateRangeTableFilterProps<T>) => {
  return (
    <RangePicker
      allowClear
      defaultValue={[filtersValues.from, filtersValues.to]}
      onChange={(e) => {
        setFiltersValues({
          ...filtersValues,
          from: e && e[0] ? e[0] : undefined,
          to: e && e[1] ? e[1] : undefined,
        });
      }}
    />
  );
};

export default DateRangeTableFilter;
