import { message } from 'antd';
import notifyPromise from 'business/common/services/notifyPromise';
import { useGetDocumentSignedUrlLazyQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

export const useOfficialDocument = () => {
  const { t } = useTranslation();
  const [getDocumentLink] = useGetDocumentSignedUrlLazyQuery();

  const getOfficialDocumentUrl = async (documentId: string) => {
    try {
      const { data } = await getDocumentLink({ variables: { documentId } });
      return data?.getSignedDownloadUrlForPatentDocument ?? '';
    } catch (err) {
      logger.error({
        message: 'Error fetching document',
        err,
        documentId,
      });
      errorReporting.error(err);
    }
  };

  const openOfficalDocument = async (id: string) => {
    return notifyPromise(
      t,
      async () => {
        const url = await getOfficialDocumentUrl(id);

        if (!url) {
          message.warning(t('patent.officialDocuments.notifications.noUrl'));
          return;
        }
        window.open(url);
      },
      { successNotification: null, reportError: 'error' },
    );
  };

  return {
    openOfficalDocument,
    getOfficialDocumentUrl,
  };
};
