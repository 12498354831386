import {
  GetOsrDataListQuery,
  GetOsrDataListQueryVariables,
  useGetAbandonedTrademarkListQuery,
  useGetOsrDataListQuery,
  useGetTrademarkRenewedQuery,
  useGetTrademarkToRenewQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { GetOsrTrademarkQueryResponse, OsrTrademarkTableType } from './types';
import { useState } from 'react';

export const useGetTrademarkOsrData = (
  type: OsrTrademarkTableType,
  filters: any,
  defaultSort?: any,
) => {
  return useQueryForTable<
    GetOsrTrademarkQueryResponse,
    GetOsrDataListQuery,
    GetOsrDataListQueryVariables
  >({
    subStorageKey: `trademark_${type}_table`,
    useQuery: ({ limit, offset, orderBy, searchText }) => {
      return useGetOsrDataListQuery({
        fetchPolicy: 'no-cache',
        variables: {
          limit,
          offset,
          where: {
            dueDate: filters.dueDate,
            countryCode: filters.countryCode,
            office: filters.office,
            search: searchText,
            companyRef: filters.companyRef,
            firstAffidavitEvent: filters.firstAffidavitEvent,
            lastDeadlineAffidavit: filters.lastDeadlineAffidavit,
            affidavitAr: filters.affidavitAr,
            type,
          },
          orderBy: Object.keys(orderBy).length
            ? orderBy
            : ['RENEWED', 'TO_RENEW', 'AFFIDAVIT', 'AFFIDAVIT_DONE'].includes(
                type,
              )
            ? defaultSort
              ? defaultSort
              : {}
            : {},
        },
      });
    },
    map: (res) => res?.getTrademarkOsrData?.resources ?? [],

    getTotal: (res) => res?.getTrademarkOsrData?.count ?? 0,
  });
};

export const useGetRenewedData = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    office?: string;
    search?: string;
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { depositDate: 'asc' },
) => {
  const { data, loading, error, refetch } = useGetTrademarkRenewedQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: filters.limit,
      offset: filters.offset,
      where: {
        dueDate: filters.dueDate,
        countryCode: filters.countryCode,
        office: filters.office,
        search: filters.search,
        companyRef: filters.companyRef,
      },
      orderBy,
    },
  });

  return {
    loading,
    error,
    refetch,
    data: data?.GetTrademarkRenewed?.resources ?? [],
    total: data?.GetTrademarkRenewed?.count ?? 0,
  };
};

export const useGetToRenewData = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    office?: string;
    search?: string;
    registered?: boolean;
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { nextRenewDate: 'asc' },
) => {
  const { data, loading, error, refetch } = useGetTrademarkToRenewQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: filters.limit,
      offset: filters.offset,
      where: {
        dueDate: filters.dueDate,
        countryCode: filters.countryCode,
        office: filters.office,
        companyRef: filters.companyRef,
        search: filters.search,
        registered: filters.registered,
      },
      orderBy,
    },
  });

  const removeDuplicates = <T extends { id: string | number }>(
    dataWithDuplicates: T[],
  ): T[] => {
    const uniqueMap = new Map<string | number, T>();

    dataWithDuplicates.forEach((item) => {
      if (!uniqueMap.has(item.id)) {
        uniqueMap.set(item.id, item);
      }
    });

    return Array.from(uniqueMap.values());
  };

  return {
    loading,
    error,
    refetch,
    data:
      removeDuplicates<any>(data?.GetTrademarkToRenew?.resources ?? []) ?? [],
    total: data?.GetTrademarkToRenew?.count ?? 0,
  };
};

export const useGetAbandonedTrademark = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    search?: string;
    companyRef?: string;
    affidavitAr?: boolean;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { abandonmentDate: 'asc' },
) => {
  const [searchAbandoned, setSearchAbandoned] = useState<string>();
  const { data, loading, error, refetch } = useGetAbandonedTrademarkListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: filters.limit,
      offset: filters.offset,
      where: {
        dueDate: filters.dueDate,
        countryCode: filters.countryCode,
        search: searchAbandoned,
        companyRef: filters.companyRef,
      },
      orderBy,
    },
  });

  return {
    loading,
    error,
    refetch,
    setSearchAbandoned,
    searchAbandoned,
    data: data?.GetAbandonedTrademark?.resources ?? [],
    total: data?.GetAbandonedTrademark?.count ?? 0,
  };
};
