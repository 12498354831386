import ConnectedPage from 'business/user/helpers/connectedPage';

import Flex from 'ui/flex';
import { useHistory } from 'react-router';
import Routes from 'business/router/routes';
import HomeLogo from 'ui/appLogo/homeLogo';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import AutoCompleteInput from 'ui/v2/input/autoCompleteInput';

const HomePage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const onPressEnter = (str: string) => {
    history.push({
      pathname: Routes.Search,
      search: '?' + new URLSearchParams({ search: str }),
    });
  };

  return (
    <ConnectedPage>
      <Flex
        className="testici"
        justify="center"
        column
        alignItems="center"
        style={{
          height: '100%',
        }}
      >
        <Flex
          column
          className={styles.logoContainer}
          alignItems="center"
          justify="center"
        >
          <AutoCompleteInput
            style={{ width: '100%', zIndex: '2' }}
            onPressEnter={(e) =>
              onPressEnter((e.target as HTMLInputElement).value)
            }
            placeholder={t('common.search', { context: 'advanced' })}
          />
          <HomeLogo className={styles.homeLogo} />
        </Flex>
      </Flex>
    </ConnectedPage>
  );
};

export default HomePage;
