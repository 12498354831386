import { useGetObjectionsQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import getObjectionColumns from './objectionColumns';
import useTableColumn from 'technical/table/useTableColumn';
import { useState } from 'react';

const useGetTrademarkObjections = () => {
  const { t } = useTranslation();
  const defaultColumns = getObjectionColumns(t);
  const [search, setSearch] = useState('');
  const objectionColumns = useTableColumn(defaultColumns);

  const { data, error, loading } = useGetObjectionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filters: { caseType: 'TRADEMARK', search },
        limit: objectionColumns.paginationState.pageSize,
        orderBy: objectionColumns.orderByState,
        offset:
          (objectionColumns.paginationState.current - 1) *
          objectionColumns.paginationState.pageSize,
      },
    },
  });
  return {
    data: data?.getObjections,
    tableColumns: {
      visibleColumns: objectionColumns,
      defaultColumns,
    },
    loading,
    export: {
      download: () => {},
      loading: false,
    },
    error,
    search,
    setSearch,
  };
};

const useGetPatentObjections = () => {
  const { t } = useTranslation();
  const defaultColumns = getObjectionColumns(t);
  const [search, setSearch] = useState('');
  const objectionColumns = useTableColumn(defaultColumns);

  const { data, error, loading } = useGetObjectionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filters: { caseType: 'PATENT', search },
        orderBy: objectionColumns.orderByState,
        limit: objectionColumns.paginationState.pageSize,
        offset:
          (objectionColumns.paginationState.current - 1) *
          objectionColumns.paginationState.pageSize,
      },
    },
  });
  return {
    data: data?.getObjections,
    tableColumns: {
      visibleColumns: objectionColumns,
      defaultColumns,
    },
    loading,
    export: {
      download: () => {},
      loading: false,
    },
    error,
    search,
    setSearch,
  };
};

const useGetDesignObjections = () => {
  const { t } = useTranslation();
  const defaultColumns = getObjectionColumns(t);
  const [search, setSearch] = useState('');
  const objectionColumns = useTableColumn(defaultColumns);

  const { data, error, loading } = useGetObjectionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filters: { caseType: 'DESIGN', search },
        orderBy: objectionColumns.orderByState,
        limit: objectionColumns.paginationState.pageSize,
        offset:
          (objectionColumns.paginationState.current - 1) *
          objectionColumns.paginationState.pageSize,
      },
    },
  });
  return {
    data: data?.getObjections,
    tableColumns: {
      visibleColumns: objectionColumns,
      defaultColumns,
    },
    loading,
    export: {
      download: () => {},
      loading: false,
    },
    error,
    search,
    setSearch,
  };
};

const useGetDomainObjections = () => {
  const { t } = useTranslation();
  const defaultColumns = getObjectionColumns(t);
  const [search, setSearch] = useState('');
  const objectionColumns = useTableColumn(defaultColumns);

  const { data, error, loading } = useGetObjectionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filters: { caseType: 'DOMAIN', search },
        orderBy: objectionColumns.orderByState,
        limit: objectionColumns.paginationState.pageSize,
        offset:
          (objectionColumns.paginationState.current - 1) *
          objectionColumns.paginationState.pageSize,
      },
    },
  });
  return {
    data: data?.getObjections,
    tableColumns: {
      visibleColumns: objectionColumns,
      defaultColumns,
    },
    loading,
    export: {
      download: () => {},
      loading: false,
    },
    error,
    search,
    setSearch,
  };
};

export const useGetObjectionsData = () => {
  const trademarkObjections = useGetTrademarkObjections();
  const patentObjections = useGetPatentObjections();
  const designObjections = useGetDesignObjections();
  const domainObjections = useGetDomainObjections();

  return {
    trademarkObjections,
    patentObjections,
    designObjections,
    domainObjections,
  };
};
