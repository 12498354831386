import React, { useState } from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import patentColumns from 'business/patent/components/constant';
import useTableColumn from 'technical/table/useTableColumn';
import {
  useDownloadPatentFamilies,
  useGetPatentFamilies,
} from 'business/patent/services/patentFamilies.service';
import Table from 'ui/v2/table/table';
import Flex from 'ui/flex';
import TagResults from 'ui/tag/tagResults';
import { Checkbox } from 'antd';
import SearchInput from 'ui/v2/input/searchInput';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Button from 'ui/button';
import { DownloadOutlined } from '@ant-design/icons';

const PatentFamiliesPage: React.FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isEffective, setIsEffective] = useState(true);

  const columns = patentColumns(t).patentFamilies;
  const patentFamiliesColumns = useTableColumn(columns);

  const { download, loading: loadingExport } = useDownloadPatentFamilies(
    columns,
    patentFamiliesColumns.visibleColumns,
    {
      isEffective,
      search,
    },
  );

  const { data, loading, total } = useGetPatentFamilies({
    isEffective,
    search,
    limit: patentFamiliesColumns.paginationState.pageSize,
    offset:
      (patentFamiliesColumns.paginationState.current - 1) *
      patentFamiliesColumns.paginationState.pageSize,
    orderBy: patentFamiliesColumns.orderByState,
  });

  return (
    <ConnectedPage translationKey="patents">
      <Spacer direction="vertical" size="small" style={{ marginTop: '35px' }}>
        <Spacer align="center" size="xxsmall">
          <h1 className="headerTitle">
            {t('pages.header.patentFamilies', { total: total })}
          </h1>
          <TagResults total={total} dataTestId="patentTotal" />
        </Spacer>
        <Table
          columns={patentFamiliesColumns.tableColumns}
          dataTestId="patentFamiliesTable"
          dataSource={data ?? []}
          onChange={patentFamiliesColumns.handlePagination}
          loading={loading}
          pagination={{
            current: patentFamiliesColumns.paginationState.current,
            pageSize: patentFamiliesColumns.paginationState.pageSize,
            total,
          }}
          actions={
            <Flex justify="space-between" alignItems="center">
              <Spacer size="xsmall" justify="start" align="center">
                <SearchInput
                  value={search}
                  onDebouncedChange={(e) => setSearch(e)}
                  placeholder={t('common.search', { context: 'table' })}
                  style={{ width: '30%' }}
                />

                <EditColumnsButton
                  initialColumns={columns}
                  visibleColumns={patentFamiliesColumns.visibleColumns}
                  onChange={patentFamiliesColumns.toggleColumnVisibility}
                />
                <Checkbox
                  checked={isEffective}
                  onChange={(e) => setIsEffective(e.target.checked)}
                >
                  {t('table.common.effective')}
                </Checkbox>
              </Spacer>
              <Button
                icon={<DownloadOutlined />}
                loading={loadingExport}
                data-test-id="exportButton"
                type="primary"
                onClick={() => download()}
              >
                {t('table.common.exportButton')}
              </Button>
            </Flex>
          }
        />
      </Spacer>
    </ConnectedPage>
  );
};

export default PatentFamiliesPage;
