import ConnectedPage from 'business/user/helpers/connectedPage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEventsData } from 'business/event/services/event.service';
import Spacer from 'ui/spacer';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import Button from 'ui/button';
import styles from 'business/search/search.module.scss';
import { DownloadOutlined, FilterFilled } from '@ant-design/icons';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import { Checkbox, Drawer, Tabs } from 'antd';
import Table from 'ui/v2/table/table';
import PatentEventsFilters from 'business/event/components/patentEventsFilters';
import {
  GetDesignEventsFilters,
  GetDomainEventsFilters,
  GetPatentEventsFilters,
  GetTrademarkEventsFilters,
} from 'generated/graphql';
import DesignEventsFilters from 'business/event/components/designEventsFilters';

import DomainEventsFilters from 'business/event/components/domainEventsFilters';
import TrademarkEventsFilters from 'business/event/components/trademarkEventsFilters';
import SearchInput from 'ui/v2/input/searchInput';

const EventPage = () => {
  const { t } = useTranslation();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('patentEvents');
  const resources = useGetEventsData();

  const currentResource = resources[activeTab as keyof typeof resources];

  return (
    <ConnectedPage>
      <Spacer style={{ marginTop: '50px' }} direction="vertical" size="small">
        <HeaderTitles title="Recherche avancée des évènements" subtitle="" />
        <Spacer justify="end" align="center">
          <Spacer justify="end" size="xsmall" align="center">
            <Checkbox
              checked={!!currentResource.filters?.displayAnnuities}
              onChange={() =>
                currentResource.setFilters({
                  ...currentResource.filters,
                  displayAnnuities: !currentResource.filters?.displayAnnuities,
                })
              }
            >
              {t('table.common.showAnnuities')}
            </Checkbox>
            <SearchInput
              onDebouncedChange={(value) =>
                currentResource.setFilters({
                  ...currentResource.filters,
                  search: value,
                })
              }
              placeholder={t('common.search', { context: 'table' })}
              style={{ width: '30%' }}
            />

            <Button
              className={styles.filtersButton}
              onClick={() => setFiltersOpen(!filtersOpen)}
              icon={<FilterFilled color="black" />}
            >
              {t('pages.advancedSearch.advancedFiltersButton')}
            </Button>
            <EditColumnsButton
              className={styles.editColumnsButton}
              initialColumns={currentResource.tableColumns.defaultColumns}
              visibleColumns={
                currentResource.tableColumns.visibleColumns.visibleColumns
              }
              onChange={
                currentResource.tableColumns.visibleColumns
                  .toggleColumnVisibility
              }
            />
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              loading={currentResource.export.loading}
              onClick={() => currentResource.export.download()}
            >
              {t('table.common.exportButton')}
            </Button>
          </Spacer>
        </Spacer>
        <Tabs
          type="card"
          size="large"
          animated
          onChange={(key) => {
            setActiveTab(key);
          }}
          items={[
            {
              label: t('ressources.patent', {
                total: resources.patentEvents.data?.count ?? 0,
              }),
              key: 'patentEvents',
              children: (
                <Table
                  loading={currentResource.data.loading}
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            {
              label: t('ressources.trademark', {
                total: resources.trademarkEvents.data?.count ?? 0,
              }),
              key: 'trademarkEvents',
              children: (
                <Table
                  loading={currentResource.data.loading}
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            {
              label: t('ressources.design', {
                total: resources.designEvents.data?.count ?? 0,
              }),
              key: 'designEvents',
              children: (
                <Table
                  loading={currentResource.data.loading}
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            {
              label: t('ressources.domain', {
                total: resources.domainEvents.data?.count ?? 0,
              }),
              key: 'domainEvents',
              children: (
                <Table
                  loading={currentResource.data.loading}
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            // On commente cette feature pour le moment car non utilisé
            // {
            //   label: t('ressources.allResources', {
            //     total: resources.allEvents.data?.count ?? 0,
            //   }),
            //   key: 'allEvents',
            //   children: (
            //     <Table
            //       dataSource={currentResource.data?.resources ?? []}
            //       columns={
            //         currentResource.tableColumns.visibleColumns.tableColumns
            //       }
            //       onChange={
            //         currentResource.tableColumns.visibleColumns.handlePagination
            //       }
            //       pagination={{
            //         ...currentResource.tableColumns.visibleColumns
            //           .paginationState,
            //         total: currentResource.data?.count ?? 0,
            //       }}
            //     />
            //   ),
            // },
          ]}
        />
        <Drawer
          closable
          open={filtersOpen}
          width={600}
          title={t('pages.advancedSearch.filtersDrawer.title', {
            context: activeTab,
          })}
          onClose={() => setFiltersOpen(!filtersOpen)}
        >
          {activeTab === 'patentEvents' ? (
            <PatentEventsFilters
              initialValues={currentResource.filters as GetPatentEventsFilters}
              onFinish={(values) => {
                currentResource.setFilters(values);
              }}
            />
          ) : undefined}

          {activeTab === 'designEvents' ? (
            <DesignEventsFilters
              initialValues={currentResource.filters as GetDesignEventsFilters}
              onFinish={(values) => {
                currentResource.setFilters(values);
              }}
            />
          ) : undefined}
          {activeTab === 'domainEvents' ? (
            <DomainEventsFilters
              initialValues={currentResource.filters as GetDomainEventsFilters}
              onFinish={(values) => {
                currentResource.setFilters(values);
              }}
            />
          ) : undefined}
          {activeTab === 'trademarkEvents' ? (
            <TrademarkEventsFilters
              initialValues={
                currentResource.filters as GetTrademarkEventsFilters
              }
              onFinish={(values) => {
                currentResource.setFilters(values);
              }}
            />
          ) : undefined}
        </Drawer>
      </Spacer>
    </ConnectedPage>
  );
};

export default EventPage;
