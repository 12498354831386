import { FloatButton, Layout } from 'antd';
import classNames from 'classnames';
import React from 'react';
import ScrollToTop from 'technical/router/scroll-to-top';
import styles from './index.module.scss';
import Sidebar from './sidebar';
import Header from './header';
import { useSetDocumentTitle } from './layout.service';

const { Content } = Layout;

export interface PageProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  scrollToTop?: boolean;
  isConnected: boolean;
  translationKey?: string;
}

const Page = ({
  children = null,
  scrollToTop = true,
  isConnected,
  translationKey,
}: PageProps) => {
  useSetDocumentTitle({ translationKey });

  return (
    <Layout hasSider className={styles.mainLayout}>
      <Sidebar />
      <Layout>
        <Header isConnected={isConnected} />

        <ScrollToTop enabled={scrollToTop} />

        <Content
          className={classNames(styles.content, {
            [styles.connected]: isConnected,
          })}
        >
          {children}
          <FloatButton.BackTop />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Page;
