import { Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GetDomainsDto } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import Select from 'ui/select';
import Spacer from 'ui/spacer';
import { useGetFiltersData } from 'business/search/services/filters.service';
import { RangePicker } from 'ui/date/datePicker';
import styles from 'business/search/search.module.scss';

interface IDomainSearchFilters {
  initialValues: GetDomainsDto['filters'];
  onFinish: (values: GetDomainsDto['filters']) => void;
}
const DomainSearchFilters = (props: IDomainSearchFilters) => {
  const { t } = useTranslation();
  const [form] = useForm<GetDomainsDto['filters']>();
  const { statuses } = useGetFiltersData(t, 'domain');
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={props.initialValues ?? {}}
      onFinish={(values) => {
        props.onFinish(values);
      }}
    >
      <Spacer size="small" direction="vertical">
        <Spacer size="small" direction="vertical">
          <Form.Item name="holder" label={t('table.common.column.holder')}>
            <Input placeholder={t('table.common.column.holder')} />
          </Form.Item>
          <Form.Item
            name="title"
            label={t('table.common.column.title', { context: 'domain' })}
          >
            <Input placeholder={t('table.common.column.title')} />
          </Form.Item>
          <Form.Item name="title" label={t('table.common.column.extension')}>
            <Input placeholder={t('table.common.column.extension')} />
          </Form.Item>
          <Form.Item
            name="status"
            label={t('table.common.column.status')}
            style={{ width: '100%' }}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={t('table.common.column.status')}
              options={statuses}
            />
          </Form.Item>
          <Spacer>
            <Form.Item
              name="clientRef"
              label={t('table.common.column.clientRef')}
            >
              <Input placeholder={t('table.common.column.clientRef')} />
            </Form.Item>
            <Form.Item
              name="clientRef2"
              label={t('table.common.column.clientRef2')}
            >
              <Input placeholder={t('table.common.column.clientRef2')} />
            </Form.Item>
          </Spacer>

          <Spacer>
            <Form.Item
              name="bdlOfficer"
              label={t('table.common.column.bdlOfficer')}
            >
              <Input placeholder={t('table.common.column.bdlOfficer')} />
            </Form.Item>

            <Form.Item
              name="clientOfficer"
              label={t('table.common.column.clientOfficer')}
            >
              <Input placeholder={t('table.common.column.clientOfficer')} />
            </Form.Item>
          </Spacer>

          <Form.Item
            name="reservationDate"
            label={t('table.common.column.reservationDate')}
          >
            <RangePicker picker="date" allowClear style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="expirationDate"
            label={t('table.common.column.expirationDate')}
          >
            <RangePicker picker="date" allowClear style={{ width: '100%' }} />
          </Form.Item>
        </Spacer>

        <Spacer
          className={styles.filtersButtonContainer}
          direction="vertical"
          size="xxsmall"
        >
          <Button
            data-test-id="searchFilterSubmit"
            type="primary"
            htmlType="submit"
          >
            {t('pages.advancedSearch.filtersDrawer.applyFilters')}
          </Button>
        </Spacer>
      </Spacer>
    </Form>
  );
};

export default DomainSearchFilters;
