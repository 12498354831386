import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  GetDesignsDto,
  GetPatentsSearchDto,
  GetTrademarksDto,
  useDownloadPatentsLazyQuery,
  useDownloadTrademarksLazyQuery,
  useGetAllResourcesQuery,
  useGetDomainsQuery,
  useGetPatentsQuery,
  useGetTrademarksQuery,
  useGetdesignsQuery,
} from 'generated/graphql';
import { searchColumns } from 'business/search/constants/searchColumns.constant';
import useTableColumn from 'technical/table/useTableColumn';
import { useTranslation } from 'react-i18next';
import { convertAndDownloadFile } from 'technical/fileManagement';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { notification } from 'antd';
import { format } from 'date-fns';

/**
 *
 * Ce service écoute le changement de paramètre dans l'url
 */
export const useSearch = () => {
  const location = useLocation();
  const [search, setSearch] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setSearch(searchParams.get('search') || '');
  }, [location.search]); // Ecoute le changement de query param

  return {
    search,
  };
};

/**
 * Service qui fetch la données, gère les colonnes des tableaux
 * et gère les différents filtres liés au tableau Brevet
 */
export const useSearchPatentTable = (search: string) => {
  const { t } = useTranslation();
  const defaultColumns = searchColumns(t).patent;
  const patentColumns = useTableColumn(defaultColumns);
  const [downloadPatents, { loading: loadingExport }] =
    useDownloadPatentsLazyQuery();

  const [filters, setFilters] = useState<GetPatentsSearchDto>({
    clientRef: undefined,
    clientRef2: undefined,
    title: undefined,
    holder: undefined,
    effectiveHolder: undefined,
    inventors: undefined,
    bdlOfficer: undefined,
    clientOfficer: undefined,
    status: undefined,
    protectionCode: undefined,
    depositDate: undefined,
    countryCode: undefined,
    publicationDate: undefined,
    issueDate: undefined,
    ownershipType: undefined,
  });

  const download = async () => {
    try {
      const { data } = await downloadPatents({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: defaultColumns
              .filter((c) => {
                return patentColumns.visibleColumns.includes(
                  c.key?.toString() ?? '',
                );
              })
              .map((c) => ({
                key: c.key?.toString() ?? '',
                value: c.title?.toString() ?? '',
              })),
            filters: { ...filters, search },
          },
        },
      });
      return await convertAndDownloadFile(
        `export_patent_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadPatents?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download patents',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };

  const { data, error, loading } = useGetPatentsQuery({
    variables: {
      filters: {
        ...filters,
        search,
      },
      orderBy: patentColumns.orderByState,
      limit: patentColumns.paginationState.pageSize,
      offset:
        (patentColumns.paginationState.current - 1) *
        patentColumns.paginationState.pageSize,
    },
  });
  const onChangeFilter = (values: any) => {
    setFilters(values);
  };
  return {
    data: data?.getPatents,
    error,
    loading,
    tableColumns: {
      visibleColumns: patentColumns,
      defaultColumns,
    },
    filters,
    export: {
      download: download,
      loading: loadingExport,
    },
    onChangeFilter,
  };
};

/**
 * Service qui fetch la données, gère les colonnes des tableaux
 * et gère les différents filtres liés au tableau Marques
 */
export const useSearchTrademarkTable = (search: string) => {
  const { t } = useTranslation();
  const defaultColumns = searchColumns(t).trademark;
  const trademarkColumns = useTableColumn(defaultColumns);
  const [downloadTrademarks, { loading: loadingExport }] =
    useDownloadTrademarksLazyQuery();

  const [filters, setFilters] = useState<GetTrademarksDto>({
    clientRef: undefined,
    clientRef2: undefined,
    title: undefined,
    holder: undefined,
    effectiveHolder: undefined,
    bdlOfficer: undefined,
    clientOfficer: undefined,
    status: undefined,
    protectionCode: undefined,
    depositDate: undefined,
    ownershipType: undefined,
  });

  const download = async () => {
    try {
      const { data } = await downloadTrademarks({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: defaultColumns
              .filter((c) => {
                return trademarkColumns.visibleColumns.includes(
                  c.key?.toString() ?? '',
                );
              })
              .map((c) => ({
                key: c.key?.toString() ?? '',
                value: c.title?.toString() ?? '',
              })),
            filters: { ...filters, search },
          },
        },
      });
      return await convertAndDownloadFile(
        `export_trademarks_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadTrademarks?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download patents',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };

  const { data, error, loading } = useGetTrademarksQuery({
    variables: {
      filters: {
        ...filters,
        search,
      },
      orderBy: trademarkColumns.orderByState,
      limit: trademarkColumns.paginationState.pageSize,
      offset:
        (trademarkColumns.paginationState.current - 1) *
        trademarkColumns.paginationState.pageSize,
    },
  });
  const onChangeFilter = (values: any) => {
    setFilters(values);
  };
  return {
    data: data?.getTrademarks,
    error,
    loading,
    tableColumns: {
      visibleColumns: trademarkColumns,
      defaultColumns,
    },
    filters,
    export: {
      download,
      loading: loadingExport,
    },
    onChangeFilter,
  };
};

/**
 * Service qui fetch la données, gère les colonnes des tableaux
 * et gère les différents filtres liés au tableau des NDD
 */
export const useSearchDomainsTable = (search: string) => {
  const { t } = useTranslation();
  const defaultColumns = searchColumns(t).patent;
  const visibleColumns = useTableColumn(defaultColumns);

  const [filters, setFilters] = useState<GetPatentsSearchDto>({
    clientRef: undefined,
    clientRef2: undefined,
    title: undefined,
    holder: undefined,
    effectiveHolder: undefined,
    inventors: undefined,
    bdlOfficer: undefined,
    clientOfficer: undefined,
    status: undefined,
    protectionCode: undefined,
    depositDate: undefined,
    publicationDate: undefined,
    issueDate: undefined,
    ownershipType: undefined,
  });

  const { data, error, loading } = useGetDomainsQuery({
    variables: {
      data: {
        filters: {
          ...filters,
          search,
        },
        limit: visibleColumns.paginationState.pageSize,
        offset:
          (visibleColumns.paginationState.current - 1) *
          visibleColumns.paginationState.pageSize,
        orderBy: visibleColumns.orderByState,
      },
    },
  });
  const onChangeFilter = (values: any) => {
    setFilters(values);
  };
  return {
    data: data?.getDomains,
    error,
    loading,
    tableColumns: {
      visibleColumns,
      defaultColumns,
    },
    filters,
    onChangeFilter,
    export: {
      download: () => {},
      loading: false,
    },
  };
};
/**
 * Service qui fetch la données, gère les colonnes des tableaux
 * et gère les différents filtres liés au tableau des modèles
 */
export const useSearchDesignTable = (search: string) => {
  const { t } = useTranslation();
  const defaultColumns = searchColumns(t).design;
  const designColumns = useTableColumn(defaultColumns);

  const [filters, setFilters] = useState<NonNullable<GetDesignsDto['where']>>({
    clientRef: undefined,
    clientRef2: undefined,
    title: undefined,
    holder: undefined,
    effectiveHolder: undefined,
    bdlOfficer: undefined,
    clientOfficer: undefined,
    status: undefined,
    protectionCode: undefined,
    depositDate: undefined,
    publicationDate: undefined,
    issueDate: undefined,
    ownershipType: undefined,
    adjournedPublication: undefined,
    abandonmentDate: undefined,
    countryCode: undefined,
    processAgentName: undefined,
    processAgentRef: undefined,
  });

  const { data, error, loading } = useGetdesignsQuery({
    variables: {
      input: {
        orderBy: designColumns.orderByState,
        limit: designColumns.paginationState.pageSize,
        offset:
          (designColumns.paginationState.current - 1) *
          designColumns.paginationState.pageSize,
        where: {
          search,
        },
      },
    },
  });
  const onChangeFilter = (values: any) => {
    setFilters(values);
  };
  return {
    data: data?.getDesigns,
    error,
    loading,
    tableColumns: {
      visibleColumns: designColumns,
      defaultColumns,
    },
    filters,
    onChangeFilter,
    export: {
      download: () => {},
      loading: false,
    },
  };
};

export const useSearchAllResources = (search: string) => {
  const { t } = useTranslation();
  const defaultColumns = searchColumns(t).patent;
  const visibleColumns = useTableColumn(defaultColumns);

  const [filters, setFilters] = useState<GetPatentsSearchDto>({
    clientRef: undefined,
    clientRef2: undefined,
    title: undefined,
    holder: undefined,
    effectiveHolder: undefined,
    bdlOfficer: undefined,
    clientOfficer: undefined,
    status: undefined,
    depositDate: undefined,
  });

  const { data } = useGetAllResourcesQuery({
    variables: {
      data: {
        filters: {
          ...filters,
          search,
        },
        limit: visibleColumns.paginationState.pageSize,
        offset:
          (visibleColumns.paginationState.current - 1) *
          visibleColumns.paginationState.pageSize,
        orderBy: visibleColumns.orderByState,
      },
    },
  });
  const onChangeFilter = (values: any) => {
    setFilters(values);
  };
  return {
    data: data?.searchAllResources,
    tableColumns: {
      visibleColumns,
      defaultColumns,
    },
    filters,
    onChangeFilter,
    export: {
      download: () => {},
      loading: false,
    },
  };
};

/**
 *
 * Service principale utilisé pour récupérer l'ensemble des données
 * pour la page de recherche avancée
 */
export const useGetResourcesData = (search: string) => {
  const patents = useSearchPatentTable(search);
  const designs = useSearchDesignTable(search);
  const trademarks = useSearchTrademarkTable(search);
  const domains = useSearchDomainsTable(search);
  // const allResources = useSearchAllResources(search); // On enleve cette feature pour le moment. Pas utile pour l'instant
  return {
    patents,
    designs,
    trademarks,
    domains,
    // allResources,
  };
};
