import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb as AntdBreadcrumb, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

export interface BreadcrumbSegment {
  label: string;
  link?: string;
  loading?: boolean;
}

interface BreadcrumbProps {
  segments: BreadcrumbSegment[];
  homeButtonRoute?: string;
}

const Breadcrumb = ({ segments, homeButtonRoute }: BreadcrumbProps) => {
  return (
    <AntdBreadcrumb style={{ marginTop: '20px' }}>
      {homeButtonRoute && (
        <AntdBreadcrumb.Item>
          <Link className={styles.link} to={homeButtonRoute}>
            <HomeOutlined />
          </Link>
        </AntdBreadcrumb.Item>
      )}
      {segments.map(({ label, link, loading }) => {
        const text = (
          <Link className={styles.link} to={link ?? '#'}>
            {label}
          </Link>
        );

        return (
          <AntdBreadcrumb.Item key={label}>
            {loading ? (
              <Skeleton.Button
                size="small"
                active
                className={styles.breadcrumbLoading}
              />
            ) : (
              text
            )}
          </AntdBreadcrumb.Item>
        );
      })}
    </AntdBreadcrumb>
  );
};

export default Breadcrumb;
