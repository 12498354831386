import { AppContextAttributes } from 'business/AppBootstrapper';
import LogsPage from 'business/admin/pages/logs';
import OpsDocumentsPage from 'business/admin/pages/opsDocumentsPage';
import { isBdlAdmin } from 'business/admin/services/permission';
import ArchivePageContainer from 'business/archive/pages/archive.page';
import ArchiveConfiguration from 'business/archive/pages/archiveConfiguration';
import ArchiveDeletion from 'business/archive/pages/archiveDeletion';
import ArchiveSubscriptions from 'business/archive/pages/archiveSubscriptions';
import CompaniesPage from 'business/configuration/companies/pages/companies';
import CompanyArchiveConfigurationPage from 'business/configuration/companies/pages/companyArchiveConfiguration';
import CompanyDetailPageContainer from 'business/configuration/companies/pages/companyDetailPageContainer';
import CompanyDomainInvoicing from 'business/configuration/companies/pages/companyDomainInvoicing';
import UsersPage from 'business/configuration/accessManagement/pages/users.page';
import BdlUsersPage from 'business/configuration/accessManagement/pages/bdlUsers.page';
import DesignPage from 'business/design/pages/design.page';
import DesignFamiliesPage from 'business/design/pages/designFamilies.page';
import DesignFamilyPage from 'business/design/pages/designFamily.page';
import SafranRequestPage from 'business/exportQuery/pages/safranRequest';
import GroupCreate from 'business/groups/pages/groupCreate';
import GroupList from 'business/groups/pages/groupList';
import GroupUpdate from 'business/groups/pages/groupUpdate';
import InvoicesPage from 'business/invoice/pages/invoicesPage';
import Routes from 'business/router/routes';
import {
  ConnectedHoc,
  ConnectedHocBdlAdminOrSafranUser,
  NotConnectedHoc,
} from 'business/user/helpers/protectedRouteContainer';
import ForgotPasswordPage from 'business/user/pages/forgotPassword';
import LoginProviderCallbackScreen from 'business/user/pages/loginProviderCallback';
import Profile from 'business/user/pages/profile';
import ResetPasswordPage from 'business/user/pages/resetPassword';
import SignInPage from 'business/user/pages/signIn';
import WelcomeSetPasswordPage from 'business/user/pages/welcomeSetPassword';
import { RouteProps } from './types';
import HomePage from 'business/home/home';
import V1RedirectionPage from 'business/resources/shared/pages/v1RedirectionPage.page';
import TrademarkOsrPage from 'business/resources/osr/pages/osrTrademark.page';
import DesignOsrPage from 'business/resources/osr/pages/osrDesign.page';
import PatentDetailPage from 'business/patent/pages/patent.page';
import PatentFamilyPage from 'business/patent/pages/patentFamily.page';
import PatentFamiliesPage from 'business/patent/pages/patentFamilies.page';
import SearchPage from 'business/search/pages/search.page';
import TrademarkFamiliesPage from 'business/trademark/pages/trademarkFamilies.page';
import TrademarkFamilyPage from 'business/trademark/pages/trademarkFamily.page';
import TrademarkPage from 'business/trademark/pages/trademark.page';
import ObjectionPage from 'business/objection/pages/objection.page';
import DomainFamiliesPage from 'business/domain/pages/domainFamilies.page';
import DomainFamilyPage from 'business/domain/pages/domainFamily.page';
import DomainPage from 'business/domain/pages/domain.page';
import EventPage from 'business/event/pages/event.page';

export const commonRoutesProps: RouteProps[] = [
  {
    path: Routes.LoginCallback,
    component: NotConnectedHoc(LoginProviderCallbackScreen),
  },
  {
    path: Routes.SignIn,
    component: NotConnectedHoc(SignInPage),
  },
  {
    path: Routes.ForgotPassword,
    component: NotConnectedHoc(ForgotPasswordPage),
  },
  {
    path: Routes.ResetPassword,
    component: NotConnectedHoc(ResetPasswordPage),
  },
  {
    path: Routes.WelcomSetPassword,
    component: NotConnectedHoc(WelcomeSetPasswordPage),
  },
];

const userRoutesProps: RouteProps[] = [
  /**
   *  === V1 Resources Access Routes ====
   */
  {
    path: Routes.ResourceAccessV1Url,
    component: ConnectedHoc(V1RedirectionPage),
  },

  /**
   *  ==============================
   */
  {
    path: Routes.Patent,
    component: ConnectedHoc(PatentFamiliesPage),
  },
  {
    path: Routes.PatentFamily,
    component: ConnectedHoc(PatentFamilyPage),
  },
  {
    path: Routes.PatentPage,
    component: ConnectedHoc(PatentDetailPage),
  },
  {
    path: Routes.Trademark,
    component: ConnectedHoc(TrademarkFamiliesPage),
  },
  {
    path: Routes.TrademarkFamily,
    component: ConnectedHoc(TrademarkFamilyPage),
  },
  {
    path: Routes.TrademarkPage,
    component: ConnectedHoc(TrademarkPage),
  },
  {
    path: Routes.Domain,
    component: ConnectedHoc(DomainFamiliesPage),
  },
  {
    path: Routes.DomainFamily,
    component: ConnectedHoc(DomainFamilyPage),
  },
  {
    path: Routes.DomainPage,
    component: ConnectedHoc(DomainPage),
  },
  {
    path: Routes.Design,
    component: ConnectedHoc(DesignFamiliesPage),
  },
  {
    path: Routes.DesignFamily,
    component: ConnectedHoc(DesignFamilyPage),
  },
  {
    path: Routes.DesignPage,
    component: ConnectedHoc(DesignPage),
  },
  {
    path: Routes.Events,
    component: ConnectedHoc(EventPage),
  },
  {
    path: Routes.Profile,
    component: ConnectedHoc(Profile),
  },
  {
    path: Routes.Archive,
    component: ConnectedHoc(ArchivePageContainer),
  },
  {
    path: Routes.Home,
    component: ConnectedHoc(HomePage),
  },
  {
    path: Routes.Search,
    component: ConnectedHoc(SearchPage),
  },
  {
    path: Routes.Invoices,
    component: ConnectedHoc(InvoicesPage),
  },
  {
    path: Routes.SafranExportQuery,
    component: ConnectedHocBdlAdminOrSafranUser(SafranRequestPage),
  },
  {
    path: Routes.TrademarkOsr,
    component: ConnectedHoc(TrademarkOsrPage),
  },
  {
    path: Routes.DesignOsr,
    component: ConnectedHoc(DesignOsrPage),
  },

  {
    path: Routes.ObjectionPage,
    component: ConnectedHoc(ObjectionPage),
  },
];

const companyRoutes: RouteProps[] = [
  {
    path: Routes.Companies,
    component: ConnectedHoc(CompaniesPage),
  },
  {
    path: Routes.CompanyDetails,
    component: ConnectedHoc(CompanyDetailPageContainer),
  },
  {
    path: Routes.CompanyGroups,
    component: ConnectedHoc(GroupList),
  },
  {
    path: Routes.GroupCreate,
    component: ConnectedHoc(GroupCreate),
  },
  {
    path: Routes.GroupUpdate,
    component: ConnectedHoc(GroupUpdate),
  },
  {
    path: Routes.CompanyArchiveConfiguration,
    component: ConnectedHoc(CompanyArchiveConfigurationPage),
  },
];

const bdlRoutesProps: RouteProps[] = [
  {
    path: Routes.Users,
    component: ConnectedHoc(UsersPage),
  },
  {
    path: Routes.Logs,
    component: ConnectedHoc(LogsPage),
  },
  {
    path: Routes.BdlUsers,
    component: ConnectedHoc(BdlUsersPage),
  },
  {
    path: Routes.ArchiveSubscriptions,
    component: ConnectedHoc(ArchiveSubscriptions),
  },
  {
    path: Routes.ArchiveConfiguration,
    component: ConnectedHoc(ArchiveConfiguration),
  },
  {
    path: Routes.ArchiveDeletionsRequest,
    component: ConnectedHoc(ArchiveDeletion),
  },
  {
    path: Routes.CompanyDomainInvoicingConfiguration,
    component: ConnectedHoc(CompanyDomainInvoicing),
  },
  {
    path: Routes.OpsDocumentsPage,
    component: ConnectedHoc(OpsDocumentsPage),
  },
];

const disconnectedRoutesProps: RouteProps[] = [
  {
    path: Routes.Home,
    redirectTo: Routes.SignIn,
  },
  // Redirect to signin if the user arrive disconnected
  {
    path: Routes.All,
    redirectTo: Routes.SignIn,
  },
];

export const routesPropsListBuilder = ({
  isConnected,
  user,
}: AppContextAttributes) => {
  if (isConnected && user) {
    let routes = userRoutesProps;

    if (isBdlAdmin(user)) {
      routes = [...routes, ...companyRoutes, ...bdlRoutesProps];
    } else if (user.companyIds.length) {
      routes = [...routes, ...companyRoutes];
    }

    return routes;
  }

  return disconnectedRoutesProps;
};
