import { generatePathByAssetType } from 'business/common/services';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { getCountryKey } from 'technical/countriesTranslation/constants';
import { formatDate } from 'technical/date';
import SimpleLink from 'ui/link/SimpleLink';

const domainColumns = (t: TFunction) => ({
  domainFamily: [
    {
      title: t('table.common.column.domain', { context: 'domain' }),
      dataIndex: 'domain',
      key: 'domain',
      sorter: true,
      render: (_: any, record: any) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={generatePathByAssetType('domain', record.id)}
        >
          {`${record.title}`.toUpperCase()}
        </Link>
      ),
    },
    {
      title: t('table.common.column.extension'),
      sorter: true,
      dataIndex: 'extension',
      key: 'extension',
    },
    {
      title: t('table.common.column.countries'),
      sorter: true,
      dataIndex: 'countryCode',
      key: 'countryCode',
      render: (_: any, data: any) => {
        if (data.countryCode === 'WW') {
          return <span>{t('common.generic')}</span>;
        }
        return <span>{data[getCountryKey()]}</span>;
      },
    },
    {
      key: 'title',
      title: t('table.common.column.link'),
      sorter: true,
      dataIndex: 'title',
      render: (_: any, record: any) => (
        <SimpleLink
          className="titleRedirect"
          data-test-id="titleRedirect"
          link={`https://www.${record.title}`}
          displayIcon={false}
        />
      ),
    },
    {
      key: 'reservationDate',
      title: t('table.common.column.reservationDate'),
      sorter: true,
      dataIndex: 'reservationDate',
      render: (_: any, record: any) => {
        if (record.reservationDate) {
          return formatDate(new Date(record.reservationDate));
        }
      },
    },
    {
      key: 'expirationDate',
      title: t('table.common.column.expirationDate'),
      sorter: true,
      dataIndex: 'expirationDate',
      render: (_: any, record: any) => {
        if (record.expirationDate) {
          return formatDate(new Date(record.expirationDate));
        }
      },
    },
    {
      key: 'bdlRef',
      title: t('table.common.column.bdlRef'),
      sorter: true,
      dataIndex: 'bdlRef',
    },
    {
      key: 'misRef',
      title: t('table.common.column.misRef'),
      sorter: true,
      dataIndex: 'misRef',
    },
    {
      key: 'registrar',
      title: t('table.common.column.registrar'),
      sorter: true,
      dataIndex: 'registrar',
    },
    {
      key: 'status',
      title: t('table.common.column.status'),
      sorter: true,
      dataIndex: 'status',
    },
  ],
  domainFamilies: [
    {
      title: t('table.common.column.holder'),
      dataIndex: 'holder',
      key: 'holder',
      sorter: true,
    },
    {
      title: t('table.common.column.domain', { context: 'domain' }),
      dataIndex: 'domain',
      key: 'domain',
      sorter: true,
      render: (_: any, record: any) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={`/domain/family/${record.id}`}
        >
          {record.domain}
        </Link>
      ),
    },
    {
      title: t('table.common.column.extensions'),
      sorter: true,
      dataIndex: 'extensions',
      key: 'extensions',
    },
    {
      title: t('table.common.column.bdlOfficer'),
      dataIndex: 'bdlOfficerName',
      key: 'bdlOfficerName',
      sorter: true,
    },
  ],
});

export default domainColumns;
