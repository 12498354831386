import {
  InfoCircleOutlined,
  PaperClipOutlined,
  TeamOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { Card } from 'antd';
import { GetPatentQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import InfoSection from 'ui/infoSection/infoSection';
import OptOut from './optOut';
import Status from 'business/common/components/status';

interface IPatentInformation {
  patent: GetPatentQuery['patent_by_pk'];
  images: string[];
  isLoading: boolean;
  officialRegisterLink?: string;
}

const PatentInformation = ({ patent }: IPatentInformation) => {
  const { t } = useTranslation();
  return (
    <Flex style={{ width: '100%' }}>
      <Card className="caseDetailCard">
        {/* Colonne des informations */}
        <InfoSection
          icon={<InfoCircleOutlined />}
          items={[
            {
              label: t('table.common.column.status').toUpperCase(),
              value: <Status status={patent?.status ?? ''} />,
            },
            {
              label: t('table.common.column.country').toUpperCase(),
              value: patent?.country?.labelFr,
            },
            {
              label: t('table.common.column.type').toUpperCase(),
              value: patent?.protection?.protectionLabel,
            },
            {
              label: t('table.common.column.optOut').toUpperCase(),
              value: (
                <OptOut publicationNumber={patent?.publicationNumber ?? ''} />
              ),
            },
          ]}
        />
        <InfoSection
          icon={<UsergroupDeleteOutlined />}
          items={[
            {
              label: t('table.common.column.holder').toUpperCase(),
              value: patent?.holder,
            },
            {
              label: t('table.common.column.effectiveHolder').toUpperCase(),
              value: patent?.effectiveHolder,
            },
          ]}
        />

        <InfoSection
          icon={<PaperClipOutlined />}
          items={[
            {
              label: t('table.common.column.bdlRef').toUpperCase(),
              value: patent?.bdlRef,
            },
            {
              label: t('table.common.column.clientRef').toUpperCase(),
              value: patent?.clientRef,
            },
            {
              label: t('table.common.column.clientRef2').toUpperCase(),
              value: patent?.clientRef2,
            },
          ]}
        />
        <InfoSection
          icon={<TeamOutlined />}
          items={[
            {
              label: t('table.common.column.clientOfficer').toUpperCase(),
              value: patent?.clientOfficer,
            },
            {
              label: t('table.common.column.bdlOfficerName').toUpperCase(),
              value: patent?.bdlOfficer?.fullName,
            },
            {
              label: t('table.common.column.bdlSecretaryName').toUpperCase(),
              value: patent?.bdlSecretary?.fullName,
            },
          ]}
        />
      </Card>
    </Flex>
  );
};

export default PatentInformation;
