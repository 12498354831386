import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'react-i18next';
import { formatDate } from 'technical/date';

const getObjectionColumns = (t: TFunction): ColumnsType<any> => [
  {
    title: t('table.common.column.caseOpenedAt'),
    dataIndex: 'caseOpenedAt',
    key: 'caseOpenedAt',
    render: (_, data) => {
      if (data?.caseOpenedAt) {
        return formatDate(new Date(data.caseOpenedAt));
      }
      return null;
    },
    sorter: true,
  },
  {
    title: t('table.common.column.type', { context: 'objection' }),
    dataIndex: 'type',
    key: 'type',
    sorter: true,
  },
  {
    title: t('table.common.column.title'),
    dataIndex: 'title',
    key: 'title',
    sorter: true,
  },
  {
    title: t('table.common.column.opposingParty'),
    dataIndex: 'opposingParty',
    key: 'opposingParty',
    sorter: true,
  },
  {
    title: t('table.common.column.bdlRef'),
    dataIndex: 'bdlRef',
    key: 'bdlRef',
    sorter: true,
  },
  {
    title: t('table.common.column.note'),
    dataIndex: 'note',
    key: 'note',
    sorter: true,
  },
];

export default getObjectionColumns;
