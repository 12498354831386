import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Map from 'ui/map/map';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import { isDefined } from 'technical/validation';
import DesignFamilyTable from 'business/design/components/designFamilyTable';
import { useGetDesignImages } from 'business/design/services/designImage.service';
import {
  useGetDesignFamilyInfos,
  useGetDesignFamilyMap,
} from 'business/design/services/designFamily.service';
import { useDesignBreadcrumbs } from 'business/design/services/design.service';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import { Image } from 'antd';

const DesignFamilyPage: React.VFC = () => {
  const { t } = useTranslation();
  const { id: familyId } = useParams<{ id: string }>();

  const { imageList } = useGetDesignImages([familyId]);

  const { dataMap, loading } = useGetDesignFamilyMap(familyId);

  const { getFamilyBreadcrumbs } = useDesignBreadcrumbs();
  const designFamily = useGetDesignFamilyInfos(familyId);

  return (
    <ConnectedPage
      breadcrumbs={getFamilyBreadcrumbs(designFamily)}
      translationKey="designFamily"
    >
      <Flex column>
        <HeaderTitles
          title={designFamily?.title ?? ''}
          subtitle={t('pages.header.designFamily')}
        />
        <Spacer justify="space-around" align="center">
          <Flex style={{ width: '40%' }}>
            <Image.PreviewGroup>
              {imageList
                .map((i) => i.image)
                .filter(isDefined)
                .map((url) => (
                  <Image key={url} src={url} style={{ objectFit: 'contain' }} />
                ))}
            </Image.PreviewGroup>
          </Flex>

          <Map resourceType="design" isDataLoading={loading} data={dataMap} />
        </Spacer>
        <DesignFamilyTable familyId={familyId} />
      </Flex>
    </ConnectedPage>
  );
};

export default DesignFamilyPage;
