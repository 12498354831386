import Spacer from 'ui/spacer';
import { TimelineEvent } from 'technical/timeline';
import { CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { formatDate } from 'technical/date';

interface TimelineProps {
  events: TimelineEvent[];
}

const Timeline = ({ events }: TimelineProps) => {
  return events.length ? (
    <div className="timelineSection">
      <Steps
        style={{
          overflowX: 'auto',
          padding: '5px',
        }}
        progressDot
        current={events.reduce((latestIdx, event, currentIndex, arr) => {
          const latestDate = new Date(arr[latestIdx].date);
          const currentDate = new Date(event.date);

          return currentDate > latestDate ? currentIndex : latestIdx;
        }, 0)}
        items={events.map((event) => {
          return {
            title: <span className="timelineTitle">{event.title}</span>,
            subTitle: (
              <Spacer
                direction="vertical"
                size="xxsmall"
                style={{ whiteSpace: 'nowrap' }}
              >
                <Spacer size="xxsmall">
                  <CalendarOutlined />
                  <span>{formatDate(new Date(event.date))}</span>
                </Spacer>

                <Spacer size="xxsmall">
                  {event.reference ? (
                    <>
                      <InfoCircleOutlined />
                      <span> n° {event.reference}</span>
                    </>
                  ) : undefined}
                </Spacer>
              </Spacer>
            ),
          };
        })}
      />
    </div>
  ) : null;
};

export default Timeline;
