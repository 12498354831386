import { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Flex from 'ui/flex';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import Map from 'ui/map/map';
import {
  useDownloadPatentFamily,
  useGetPatentFamily,
} from 'business/patent/services/patentFamily.service';
import Spacer from 'ui/spacer';
import useTableColumn from 'technical/table/useTableColumn';
import patentColumns from 'business/patent/components/constant';
import Table from 'ui/v2/table/table';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Button from 'ui/button';
import SearchInput from 'ui/v2/input/searchInput';
import { Checkbox } from 'antd';

const PatentFamilyPage = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isEffective, setIsEffective] = useState(true);

  const { id: familyId } = useParams<{ id: string }>();

  const columns = patentColumns(t).patentFamily;
  const patentFamilyColumns = useTableColumn(columns);

  const { data, loading, total, map, familyInfo, getFamilyBreadcrumbs } =
    useGetPatentFamily({
      search,
      isEffective,
      limit: patentFamilyColumns.paginationState.pageSize,
      offset:
        (patentFamilyColumns.paginationState.current - 1) *
        patentFamilyColumns.paginationState.pageSize,
      orderBy: patentFamilyColumns.orderByState,
      familyId: familyId,
    });

  const { download, loading: loadingExport } = useDownloadPatentFamily(
    columns,
    patentFamilyColumns.visibleColumns,
    {
      search,
      familyId,
    },
  );
  return (
    <ConnectedPage
      breadcrumbs={getFamilyBreadcrumbs(familyInfo)}
      translationKey="patentFamily"
    >
      <Flex column>
        <HeaderTitles
          title={familyInfo?.title ?? ''}
          subtitle={t('pages.header.patentFamily')}
        />

        <Spacer justify="space-between" align="center">
          <Spacer>
            <Flex
              column
              justify="space-around"
              className="cardInformationContainer"
            >
              <span className="cardInformationTotal">{total}</span>
              <span className="cardInformationLabel">Titres</span>
            </Flex>
            <Flex
              column
              justify="space-around"
              className="cardInformationContainer"
            >
              <span className="cardInformationTotal">
                {data.filter((d) => d.issueDate).length}
              </span>
              <span className="cardInformationLabel">Demandes en examen</span>
            </Flex>
            <Flex
              column
              justify="space-around"
              className="cardInformationContainer"
            >
              <span className="cardInformationTotal">
                {data.filter((d) => d.status === 'En cours').length}
              </span>
              <span className="cardInformationLabel">Titres en vigueur</span>
            </Flex>
          </Spacer>
          <Map
            resourceType="patent"
            isDataLoading={map.loading}
            data={map.data}
          />
        </Spacer>
      </Flex>

      <Table
        columns={patentFamilyColumns.tableColumns}
        dataTestId="patentFamilyTable"
        dataSource={data ?? []}
        loading={loading}
        onChange={patentFamilyColumns.handlePagination}
        pagination={{
          current: patentFamilyColumns.paginationState.current,
          pageSize: patentFamilyColumns.paginationState.pageSize,
          total,
        }}
        actions={
          <Flex justify="space-between">
            <Spacer align="center" size="xsmall">
              <SearchInput
                value={search}
                onDebouncedChange={(e) => setSearch(e)}
                placeholder={t('common.search', { context: 'table' })}
                style={{ width: '30%' }}
              />
              <EditColumnsButton
                initialColumns={columns}
                visibleColumns={patentFamilyColumns.visibleColumns}
                onChange={patentFamilyColumns.toggleColumnVisibility}
              />
              <Checkbox
                checked={isEffective}
                onChange={(e) => setIsEffective(e.target.checked)}
              >
                {t('table.common.effective')}
              </Checkbox>
            </Spacer>
            <Button
              loading={loadingExport}
              icon={<DownloadOutlined />}
              data-test-id="exportButton"
              type="primary"
              onClick={() => download()}
            >
              {t('table.common.exportButton')}
            </Button>
          </Flex>
        }
      />
    </ConnectedPage>
  );
};

export default PatentFamilyPage;
