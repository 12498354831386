import { DeleteOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Modal } from 'antd';
import { formatDate } from 'technical/date';
import Flex from 'ui/flex';
import {
  useGetPatentNote,
  usePatentNoteActions,
} from 'business/patent/services/patentNote.service';
import Button from 'ui/button';
import Spacer from 'ui/spacer';
import { useTranslation } from 'react-i18next';

const PatentNote = (props: { patentId: number }) => {
  const { t } = useTranslation();
  const { comments, refetch } = useGetPatentNote(props.patentId);
  const {
    onCreate,
    setCommentUpdateData,
    commentUpdateData,
    onUpdate,
    onDelete,
    setCommentDeleteId,
    commentDeleteId,
    createForm,
    updateForm,
  } = usePatentNoteActions(props.patentId);

  return (
    <>
      <div style={{ padding: '20px', height: '100%' }}>
        <h1 className="sectionTitle">NOTES</h1>
        <div className="noteList">
          {comments.map((c) => {
            return (
              <Flex column key={c.id}>
                <Spacer direction="vertical" size="xxsmall">
                  <Flex justify="space-between">
                    <span className="noteDate">
                      {formatDate(new Date(c.createdAt))}
                    </span>
                    <Flex>
                      <Spacer size="xsmall">
                        <EditOutlined
                          onClick={() => {
                            setCommentUpdateData({
                              comment: c.comment,
                              id: c.id,
                            });
                            updateForm.setFieldsValue({
                              comment: c.comment,
                            });
                          }}
                        />
                        <DeleteOutlined
                          size={20}
                          onClick={() => {
                            setCommentDeleteId(c.id);
                          }}
                        />
                      </Spacer>
                    </Flex>
                  </Flex>
                  <span className="noteUser">{c.user?.fullName}</span>
                  <span>{c.comment}</span>
                </Spacer>

                <Divider />
              </Flex>
            );
          })}
        </div>
      </div>
      <Flex justify="space-between" style={{ bottom: 0, padding: '20px' }}>
        <Form
          style={{ display: 'flex', justifyContent: 'space-between' }}
          form={createForm}
          onFinish={(data) => {
            onCreate(data.comment).then(() => {
              refetch();
              createForm.resetFields();
            });
          }}
        >
          <Form.Item name="comment">
            <Input
              size="small"
              className="noteInput"
              placeholder="Entrer votre note ici..."
            />
          </Form.Item>
          <Button
            icon={<SendOutlined />}
            className="noteInput"
            htmlType="submit"
          />
        </Form>
      </Flex>
      <Modal
        open={!!commentUpdateData?.id}
        onCancel={() => {
          setCommentUpdateData(null);
        }}
        onOk={() => updateForm.submit()}
        title={t('notes.edit', { context: 'title' })}
      >
        <Form
          form={updateForm}
          initialValues={{ comment: commentUpdateData?.comment }}
          onFinish={(updateFormData) => {
            onUpdate({
              id: commentUpdateData?.id ?? '',
              comment: updateFormData.comment,
            }).then(() => {
              refetch();
              setCommentUpdateData(null);
            });
          }}
        >
          <Form.Item name="comment">
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        onCancel={() => setCommentDeleteId(null)}
        onOk={() => {
          if (commentDeleteId) {
            onDelete(commentDeleteId).then(() => {
              refetch();
              setCommentDeleteId(null);
            });
          }
        }}
        title={t('notes.delete', { context: 'title' })}
        open={!!commentDeleteId}
      >
        <span>{t('notes.delete', { context: 'confirmation' })} </span>
      </Modal>
    </>
  );
};

export default PatentNote;
