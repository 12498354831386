import { Col } from 'antd';
import Spacer from 'ui/spacer';
import styles from './info.module.scss';

const InfoItem = ({
  label,
  value,
}: {
  label: string;
  value?: React.ReactNode;
}) => {
  if (!value) {
    return null;
  }
  return (
    <Col span={5}>
      <Spacer size="xxsmall" direction="vertical">
        <span className={styles.generalInformationLabel}>{label}</span>
        <span className={styles.generalInformationValue}>{value}</span>
      </Spacer>
    </Col>
  );
};

export default InfoItem;
