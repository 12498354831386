import { useResourceBreadcrumbs } from 'business/common/services/breadcrumbs';
import Routes from 'business/router/routes';
import {
  GetPatentEventsQuery,
  GetPatentEventsQueryVariables,
  GetPatentQuery,
  Order_By,
  useGetDrawingsSignedUrlQuery,
  useGetPatentEpoDocumentsQuery,
  useGetPatentEventsQuery,
  useGetPatentQuery,
} from 'generated/graphql';
import { Patent, PatentDocument } from './types';

import { getProperty } from 'technical/types';
import { isDefined } from 'technical/validation';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { Event } from 'business/resources/events/services/types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { TimelineEvent, createTimelineEvents } from 'technical/timeline';
import { isBeforeToday } from 'technical/date';
import { isResourceNotEffective } from 'business/common/services';
import {
  ALLOWED_DOCUMENT_TYPES_TO_DISPLAY_DRAWINGS,
  ANNUITY_TYPE_NOT_HANDLED_BY_BDL,
} from './constant';

export const useGetPatentEpoDocument = ({
  id,
  publicationDate,
}: {
  id: number;
  publicationDate: string | null | undefined;
}) => {
  const { data, ...patentDocumentDataState } = useGetPatentEpoDocumentsQuery({
    skip: publicationDate ? false : true, // publicationDate is mandatory to have data
    variables: { id },
  });

  return {
    patentDocuments: data?.patent_by_pk?.documents ?? [],
    loading: patentDocumentDataState?.loading,
  };
};

export const usePatentBreadcrumbs = () =>
  useResourceBreadcrumbs({
    type: 'patent',
    familyRoute: Routes.PatentFamily,
    rootRoute: Routes.Patent,
  });

const getDocumentIdsOnlyForAllowedTypes = (documents: PatentDocument[]) =>
  documents.filter(({ type }) =>
    ALLOWED_DOCUMENT_TYPES_TO_DISPLAY_DRAWINGS.includes(type),
  );

export const getDrawingIdsFromDocuments = (documents?: PatentDocument[]) => {
  if (!documents) {
    return [];
  }
  return getDocumentIdsOnlyForAllowedTypes(documents).reduce<string[]>(
    (acc, document) => {
      const drawingIds = document.patentDrawings.map(getProperty('id'));
      return [...acc, ...drawingIds];
    },
    [],
  );
};

export const useGetPatentDrawingsFromDocuments = (
  documents?: PatentDocument[],
  documentLoading?: boolean,
) => {
  const validDrawingIds = getDrawingIdsFromDocuments(documents);

  const { data, error, loading } = useGetDrawingsSignedUrlQuery({
    variables: {
      drawingIds: validDrawingIds,
    },
    skip: !validDrawingIds.length,
  });
  return {
    images: data?.getSignedDownloadUrlForPatentDrawings.filter(isDefined) ?? [],
    error,
    loading: documentLoading || loading,
  };
};

export const useGetPatentEvents = (patentId: number) =>
  useQueryForTable<Event, GetPatentEventsQuery, GetPatentEventsQueryVariables>(
    {
      subStorageKey: 'patentEvents',
      useQuery: ({ limit, offset, orderBy }) =>
        useGetPatentEventsQuery({
          variables: { limit, offset, patentId, orderBy },
        }),
      getTotal: (res) => res?.event_patentEvent_aggregate.aggregate?.count ?? 0,
      map: (res) => res?.event_patentEvent ?? [],
    },
    {
      orderBy: {
        dueDate: Order_By.Asc,
      },
    },
  );

export const getPatentTimeline = (
  t: TFunction,
  patent: GetPatentQuery['patent_by_pk'],
) => {
  if (!patent) {
    return [];
  }
  const events: TimelineEvent[] = [
    ...patent.priorities.map((n) => ({
      title: t('table.common.column.priority'),
      date: n.priorityDate,
      reference: n.priorityNumber,
    })),
    {
      title: t('table.common.column.firstDeposit'),
      date: patent.depositDate,
      reference: patent.depositNumber,
    },
    {
      title: t('table.common.column.publication'),
      date: patent.publicationDate,
      reference: patent.publicationNumber,
    },
    {
      title: t('table.common.column.issue'),
      date: patent.issueDate,
      reference: patent.issueNumber,
    },
  ];

  if (
    patent.abandonmentDate &&
    isBeforeToday(new Date(patent.abandonmentDate)) &&
    isResourceNotEffective(patent)
  ) {
    // If the patent is abandonned. No need to display next events but we display the abandoment date instead
    events.push({
      title: t('table.common.column.abandonment'),
      date: patent.abandonmentDate,
      type: 'error',
    });
  } else if (
    patent.expirationDate &&
    isBeforeToday(new Date(patent.expirationDate))
  ) {
    // If the patent is expired. No need to display next events but we display the expiration date instead
    events.push({
      title: t('table.common.column.expiration'),
      date: patent.expirationDate,
      type: 'error',
    });
  }

  return createTimelineEvents(events);
};

export const useGetPatent = (id: number) => {
  const { t } = useTranslation();
  const { data, ...patentDataState } = useGetPatentQuery({
    variables: { id },
  });
  const patent = data?.patent_by_pk;

  return {
    patent,
    timeline: getPatentTimeline(t, patent),
    patentDataState,
  };
};

export const isAnnuityNotHandledByBdl = ({
  annuityType,
}: Pick<Patent, 'annuityType'>) =>
  annuityType === ANNUITY_TYPE_NOT_HANDLED_BY_BDL;
