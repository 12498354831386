import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router';
import styles from './index.module.scss';
import Flex from 'ui/flex';
import { useTranslation } from 'react-i18next';
import {
  CalendarOutlined,
  FileSearchOutlined,
  GlobalOutlined,
  SearchOutlined,
  ThunderboltOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import Routes from 'business/router/routes';
import { useState } from 'react';
import { useAppContext } from 'business/AppBootstrapper';
import { useLocalStorage } from 'technical/storage/v2/storage.service';

const { Sider } = Layout;

const Icon = (props: { text: React.ReactNode; isIcon?: boolean }) => {
  return (
    <Flex
      justify="center"
      className={styles.icon}
      alignContent="center"
      alignItems="center"
    >
      {props.isIcon ? (
        props.text
      ) : (
        <span className={styles.iconText}>{props.text}</span>
      )}
    </Flex>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const localStorageService = useLocalStorage();

  const [collapse, setCollapse] = useState(() => {
    const stored = localStorageService.get('sidebar-collapse');
    return stored !== null ? stored : false;
  });
  const history = useHistory();

  const handleCollapse = () => {
    setCollapse(!collapse);
    localStorageService.add('sidebar-collapse', !collapse);
  };

  return (
    <Sider
      collapsed={collapse}
      onCollapse={handleCollapse}
      collapsible
      className={styles.sidebar}
      width={250}
      collapsedWidth={80}
    >
      <Menu
        mode="inline"
        items={[
          {
            key: 0,
            disabled: !user?.patentAccess,
            onClick: () => history.push(Routes.Patent),
            icon: <Icon text="B" />,
            label: t('pages.patent.title'),
            title: '',
          },
          {
            key: 1,
            disabled: !user?.designAccess,
            onClick: () => history.push(Routes.Design),
            icon: <Icon text="D" />,
            label: t('pages.design.title'),
            title: '',
          },
          {
            key: 2,
            disabled: !user?.trademarkAccess,
            onClick: () => history.push(Routes.Trademark),
            icon: <Icon text="M" />,
            label: t('pages.trademark.title'),
            title: '',
          },
          {
            key: 3,
            disabled: !user?.domainAccess,
            onClick: () => history.push(Routes.Domain),
            icon: (
              <Icon
                text={<GlobalOutlined style={{ fontSize: '20px' }} />}
                isIcon
              />
            ),
            label: t('pages.domain.title'),
            title: '',
          },
          {
            key: 4,
            onClick: () => history.push(Routes.Events),
            icon: (
              <Icon
                text={<CalendarOutlined style={{ fontSize: '20px' }} />}
                isIcon
              />
            ),
            label: t('pages.event.title'),
            title: '',
          },
          {
            key: 5,
            disabled: !user?.privateInfos.archivePageEnabled,
            onClick: () => history.push(Routes.Archive),
            icon: (
              <Icon
                text={<FileSearchOutlined style={{ fontSize: '20px' }} />}
                isIcon
              />
            ),
            label: t('pages.archive.title'),
            title: '',
          },
          {
            key: 6,
            onClick: () => history.push(Routes.ObjectionPage),
            icon: (
              <Icon
                text={<ThunderboltOutlined style={{ fontSize: '20px' }} />}
                isIcon
              />
            ),
            label: t('pages.objections.mainTitle'),
            title: '',
          },
          {
            key: 7,
            onClick: () => {
              return window.open(
                'https://www.bdl-ip.com/actualites-documentations/actualites/',
                '_blank',
              );
            },
            icon: (
              <Icon
                text={<WifiOutlined style={{ fontSize: '20px' }} />}
                isIcon
              />
            ),
            label: t('common.news'),
            title: '',
          },
          {
            key: 8,
            onClick: () => history.push(Routes.Search),
            icon: (
              <Icon
                text={<SearchOutlined style={{ fontSize: '20px' }} />}
                isIcon
              />
            ),
            label: t('pages.advancedSearch.mainTitle'),
            title: '',
          },
        ]}
      ></Menu>
    </Sider>
  );
};

export default Sidebar;
