import { getAlissiaResourceUrl } from 'business/resources/shared/services/constants';
import { Link } from 'react-router-dom';
import { formatDate } from 'technical/date';
import IconStatus from 'ui/iconStatus';
import { trademarkHandleTableRowColor } from 'business/resources/osr/components/common/osrRowColors';
import ClassesPopover from 'ui/v2/classes/classesPopover';

export const TO_RENEW_COLOR_DESCRIPTION = [
  {
    color: '#90EE90',
    label: 'Renouv. Possible',
  },
  {
    color: '#FFA502',
    label: 'Délai de grace',
  },
  {
    color: '#f27979',
    label: 'Hors délai',
  },
];

export const RENEWED_COLOR_DESCRIPTION = [
  {
    color: '#90EE90',
    label: 'Avec AR',
  },
];

export const RENEWED_COLUMNS = [
  {
    title: 'AR',
    dataIndex: 'confirmationReceipt',
    width: '50px',
    key: 'confirmationReceipt',
    render: (_: any, data: any) => {
      if (data.confirmationReceipt) {
        return <IconStatus success />;
      }
    },
    sorter: true,
  },
  {
    title: 'Date de dépôt (ren)',
    dataIndex: 'depositDate',
    key: 'depositDate',
    render: (_: any, data: any) => {
      if (data.depositDate) {
        return formatDate(new Date(data.depositDate));
      }
    },
    sorter: true,
  },
  {
    title: 'N° de dépôt (ren)',
    dataIndex: 'depositNumber',
    key: 'depositNumber',
    sorter: true,
  },
  {
    title: 'Réf. BDL',
    dataIndex: 'bdlRef',
    key: 'bdlRef',
    sorter: true,
  },
  {
    title: 'Anc. Réf. BDL',
    dataIndex: 'misRef',
    key: 'misRef',
    sorter: true,
  },
  {
    title: 'Agent Renouv.',
    dataIndex: 'renewAgentName',
    key: 'renewAgentName',
    sorter: true,
  },
  {
    title: 'Ref. Agent Renouv.',
    key: 'renewAgentRef',
    dataIndex: 'renewAgentRef',
    sorter: true,
  },
  {
    title: 'Marque',
    dataIndex: 'title',
    key: 'title',
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        target="_blank"
        to={`/trademark/${record.id}`}
      >
        {record.title}
      </Link>
    ),
    sorter: true,
  },
  {
    title: 'Pays',
    dataIndex: 'countryFr',
    key: 'countryFr',
    sorter: true,
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        to={{ pathname: getAlissiaResourceUrl(Number(record.alissiaId)) }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record.countryFr}
      </Link>
    ),
  },
  {
    title: 'Protection',
    dataIndex: 'protectionLabel',
    key: 'protectionLabel',
    sorter: true,
  },
  {
    title: 'Titulaire',
    dataIndex: 'holder',
    key: 'holder',
    sorter: true,
  },
  {
    title: 'Date de priorité',
    dataIndex: 'priorityDate',
    key: 'priorityDate',
    render: (_: any, data: any) => {
      if (data.priorityDate) {
        return formatDate(new Date(data.priorityDate));
      }
    },
    sorter: true,
  },
  {
    title: 'N° de priorité',
    dataIndex: 'priorityNumber',
    key: 'priorityNumber',
    sorter: true,
  },
  {
    title: 'Classes',
    dataIndex: 'classes',
    key: 'classes',
    sorter: false,
    render: (_: any, data: any) => {
      return <ClassesPopover classes={data.classes ?? []} />;
    },
  },
];

export const TO_RENEW_COLUMNS = [
  {
    title: 'Etat',
    dataIndex: 'status',
    width: '70px',
    key: 'status',
    render: (_: any, data: any) => {
      return <IconStatus className={trademarkHandleTableRowColor(data)} />;
    },
    sorter: false,
  },
  {
    title: "Date d'échéance",
    dataIndex: 'nextRenewDate',
    key: 'nextRenewDate',
    render: (_: any, data: any) => {
      if (data.nextRenewDate) {
        return formatDate(new Date(data.nextRenewDate));
      }
    },
    sorter: true,
  },
  {
    title: 'Réf. BDL',
    dataIndex: 'bdlRef',
    key: 'bdlRef',
    sorter: true,
  },
  {
    title: 'Anc. Réf. BDL',
    dataIndex: 'misRef',
    key: 'misRef',
    sorter: true,
  },
  {
    title: 'Agent Renouv.',
    dataIndex: 'renewAgentName',
    key: 'renewAgentName',
    sorter: true,
  },
  {
    title: 'Ref. Agent Renouv.',
    key: 'renewAgentRef',
    dataIndex: 'renewAgentRef',
    sorter: true,
  },
  {
    title: 'Marque',
    dataIndex: 'title',
    key: 'title',
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        target="_blank"
        to={`/trademark/${record.id}`}
      >
        {record.title}
      </Link>
    ),
    sorter: true,
  },
  {
    title: 'Pays',
    dataIndex: 'countryFr',
    key: 'countryFr',
    sorter: true,
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        to={{ pathname: getAlissiaResourceUrl(Number(record.alissiaId)) }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record.countryFr}
      </Link>
    ),
  },
  {
    title: 'Protection',
    dataIndex: 'protectionLabel',
    key: 'protectionLabel',
    sorter: true,
  },
  {
    title: 'Titulaire',
    dataIndex: 'holder',
    key: 'holder',
    sorter: true,
  },
  {
    title: 'Date de priorité',
    dataIndex: 'priorityDate',
    key: 'priorityDate',
    render: (_: any, data: any) => {
      if (data.priorityDate) {
        return formatDate(new Date(data.priorityDate));
      }
    },
    sorter: true,
  },
  {
    title: 'N° de priorité',
    dataIndex: 'priorityNumber',
    key: 'priorityNumber',
    sorter: true,
  },
  {
    title: 'Date de dépôt',
    dataIndex: 'depositDate',
    key: 'depositDate',
    render: (_: any, data: any) => {
      if (data.priorityDate) {
        return formatDate(new Date(data.depositDate));
      }
    },
    sorter: true,
  },
  {
    title: 'N° de dépôt',
    dataIndex: 'depositNumber',
    key: 'depositNumber',
    sorter: true,
  },
  {
    title: "Date d'enr.",
    dataIndex: 'firstRegistrationDate',
    key: 'firstRegistrationDate',
    hidden: true,
    render: (_: any, data: any) => {
      if (data.firstRegistrationDate) {
        return formatDate(new Date(data.firstRegistrationDate));
      }
      if (data.lastRegistrationDate) {
        return formatDate(new Date(data.lastRegistrationDate));
      }
    },
    sorter: true,
  },
  {
    title: "N° d'enr.",
    dataIndex: 'registrationNumber',
    key: 'registrationNumber',
    hidden: true,
    sorter: true,
  },
  {
    title: 'Classes',
    dataIndex: 'classes',
    key: 'classes',
    sorter: false,
    render: (_: any, data: any) => {
      return <ClassesPopover classes={data.classes ?? []} />;
    },
  },
];

export const AFU_TO_RENEW_COLUMNS = [
  {
    title: "Date d'échéance",
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (_: any, data: any) => {
      if (data.dueDate) {
        return formatDate(new Date(data.dueDate));
      }
    },
    sorter: true,
  },
  {
    title: "Type d'échéance",
    dataIndex: 'type',
    key: 'type',
    sorter: true,
    render: (_: any, data: any) => {
      if (data.type === 'Echeance affidavit' && data.countryCode === 'US') {
        return 'Dernier délai';
      }
      if (data.type === 'Echeance affidavit') {
        return 'Premier délai';
      }
      if (
        data.type === 'Borne échéance Affidavit' ||
        data.type === 'Echeance affidavit US'
      ) {
        return 'Dernier délai';
      }
      return data.type;
    },
  },
  {
    title: 'Réf. BDL',
    dataIndex: 'bdlRef',
    key: 'bdlRef',
    sorter: true,
  },
  {
    title: 'Anc. Réf. BDL',
    dataIndex: 'misRef',
    key: 'misRef',
    sorter: true,
  },
  {
    title: 'Marque',
    dataIndex: 'title',
    key: 'title',
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        target="_blank"
        to={`/trademark/${record.assetId}`}
      >
        {record.title}
      </Link>
    ),
    sorter: true,
  },
  {
    title: 'Pays',
    dataIndex: 'countryFr',
    key: 'countryFr',
    sorter: true,
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        to={{ pathname: getAlissiaResourceUrl(Number(record.alissiaId)) }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record.countryFr}
      </Link>
    ),
  },
  {
    title: "Date d'enr.",
    dataIndex: 'firstRegistrationDate',
    key: 'firstRegistrationDate',
    render: (_: any, data: any) => {
      if (data.firstRegistrationDate) {
        return formatDate(new Date(data.firstRegistrationDate));
      }
      if (data.lastRegistrationDate) {
        return formatDate(new Date(data.lastRegistrationDate));
      }
    },
    sorter: true,
  },
  {
    title: "Date d'octroi",
    dataIndex: 'grantDate',
    key: 'grantDate',
    render: (_: any, record: any) => {
      if (record.countryCode === 'US' && record.registrationDateUS) {
        return formatDate(new Date(record.registrationDateUS));
      }
      if (record.countryCode === 'JP' && record.registrationDateJP) {
        return formatDate(new Date(record.registrationDateJP));
      }
      if (record.countryCode === 'MX' && record.registrationDateMX) {
        return formatDate(new Date(record.registrationDateMX));
      }
      if (record.grantDate) {
        return formatDate(new Date(record.grantDate));
      }
    },
    sorter: true,
  },
  {
    title: 'Classes',
    dataIndex: 'classes',
    key: 'classes',
    sorter: false,
    render: (_: any, data: any) => {
      return <ClassesPopover classes={data.classes ?? []} />;
    },
  },
  {
    title: 'Titulaire',
    dataIndex: 'holder',
    key: 'holder',
    sorter: true,
  },
  {
    title: 'Agent Renouv.',
    dataIndex: 'renewAgentName',
    key: 'renewAgentName',
    sorter: true,
  },
  {
    title: 'Ref. Agent Renouv.',
    key: 'renewAgentRef',
    dataIndex: 'renewAgentRef',
    sorter: true,
  },
];

export const AFU_RENEWED_COLUMNS = [
  {
    title: 'AR',
    dataIndex: 'numberLabel',
    width: '50px',
    key: 'numberLabel',
    render: (_: any, data: any) => {
      if (data.numberLabel && data.numberLabel === 'AR') {
        return <IconStatus success />;
      }
    },
    sorter: true,
  },
  {
    title: 'Date de réalisation',
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (_: any, data: any) => {
      if (data.dueDate) {
        return formatDate(new Date(data.dueDate));
      }
    },
    sorter: true,
  },
  {
    title: "Type d'échéance",
    dataIndex: 'type',
    key: 'type',
    sorter: true,
  },
  {
    title: 'Réf. BDL',
    dataIndex: 'bdlRef',
    key: 'bdlRef',
    sorter: true,
  },
  {
    title: 'Anc. Réf. BDL',
    dataIndex: 'misRef',
    key: 'misRef',
    sorter: true,
  },
  {
    title: 'Marque',
    dataIndex: 'title',
    key: 'title',
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        target="_blank"
        to={`/trademark/${record.assetId}`}
      >
        {record.title}
      </Link>
    ),
    sorter: true,
  },
  {
    title: 'Pays',
    dataIndex: 'countryFr',
    key: 'countryFr',
    sorter: true,
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        to={{ pathname: getAlissiaResourceUrl(Number(record.alissiaId)) }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record.countryFr}
      </Link>
    ),
  },
  {
    title: "Date d'enr.",
    dataIndex: 'firstRegistrationDate',
    key: 'firstRegistrationDate',
    render: (_: any, data: any) => {
      if (data.firstRegistrationDate) {
        return formatDate(new Date(data.firstRegistrationDate));
      }
      if (data.lastRegistrationDate) {
        return formatDate(new Date(data.lastRegistrationDate));
      }
    },
    sorter: true,
  },
  {
    title: "Date d'octroi",
    dataIndex: 'grantDate',
    key: 'grantDate',
    render: (_: any, record: any) => {
      if (record.countryCode === 'US' && record.registrationDateUS) {
        return formatDate(new Date(record.registrationDateUS));
      }
      if (record.countryCode === 'JP' && record.registrationDateJP) {
        return formatDate(new Date(record.registrationDateJP));
      }
      if (record.countryCode === 'MX' && record.registrationDateMX) {
        return formatDate(new Date(record.registrationDateMX));
      }
      if (record.grantDate) {
        return formatDate(new Date(record.grantDate));
      }
    },
    sorter: true,
  },
  {
    title: 'Classes',
    dataIndex: 'classes',
    key: 'classes',
    sorter: false,
    render: (_: any, data: any) => {
      return <ClassesPopover classes={data.classes ?? []} />;
    },
  },
  {
    title: 'Titulaire',
    dataIndex: 'holder',
    key: 'holder',
    sorter: true,
  },
  {
    title: 'Agent Renouv.',
    dataIndex: 'renewAgentName',
    key: 'renewAgentName',
    sorter: true,
  },
  {
    title: 'Ref. Agent Renouv.',
    key: 'renewAgentRef',
    dataIndex: 'renewAgentRef',
    sorter: true,
  },
];

export const PROOF_OF_USED_TO_RENEW_COLUMNS = [
  {
    title: "Date d'échéance",
    dataIndex: 'nextRenewDate',
    key: 'nextRenewDate',
    render: (_: any, data: any) => {
      if (data.nextRenewDate) {
        return formatDate(new Date(data.nextRenewDate));
      }
    },
    sorter: true,
  },
  {
    title: "Type d'échéance",
    dataIndex: 'type',
    key: 'type',
    render: (_: any, data: any) => {
      if (data.renpu) {
        return data.renpu;
      }
      if (data.rendpu) {
        return data.rendpu;
      }
    },
    sorter: true,
  },
  {
    title: 'Réf. BDL',
    dataIndex: 'bdlRef',
    key: 'bdlRef',
    sorter: true,
  },
  {
    title: 'Anc. Réf. BDL',
    dataIndex: 'misRef',
    key: 'misRef',
    sorter: true,
  },
  {
    title: 'Marque',
    dataIndex: 'title',
    key: 'title',
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        target="_blank"
        to={`/trademark/${record.id}`}
      >
        {record.title}
      </Link>
    ),
    sorter: true,
  },
  {
    title: 'Pays',
    dataIndex: 'countryFr',
    key: 'countryFr',
    sorter: true,
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        to={{ pathname: getAlissiaResourceUrl(Number(record.alissiaId)) }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record.countryFr}
      </Link>
    ),
  },
  {
    title: "Date d'enr.",
    dataIndex: 'firstRegistrationDate',
    key: 'firstRegistrationDate',
    render: (_: any, data: any) => {
      if (data.firstRegistrationDate) {
        return formatDate(new Date(data.firstRegistrationDate));
      }
      if (data.lastRegistrationDate) {
        return formatDate(new Date(data.lastRegistrationDate));
      }
    },
    sorter: true,
  },
  {
    title: "Date d'octroi",
    dataIndex: 'grantDate',
    key: 'grantDate',
    render: (_: any, record: any) => {
      if (record.countryCode === 'US' && record.registrationDateUS) {
        return formatDate(new Date(record.registrationDateUS));
      }
      if (record.countryCode === 'JP' && record.registrationDateJP) {
        return formatDate(new Date(record.registrationDateJP));
      }
      if (record.countryCode === 'MX' && record.registrationDateMX) {
        return formatDate(new Date(record.registrationDateMX));
      }
      if (record.grantDate) {
        return formatDate(new Date(record.grantDate));
      }
    },
    sorter: true,
  },
  {
    title: 'Classes',
    dataIndex: 'classes',
    key: 'classes',
    sorter: false,
    render: (_: any, data: any) => {
      return <ClassesPopover classes={data.classes ?? []} />;
    },
  },
  {
    title: 'Titulaire',
    dataIndex: 'holder',
    key: 'holder',
    sorter: true,
  },
  {
    title: 'Agent Renouv.',
    dataIndex: 'renewAgentName',
    key: 'renewAgentName',
    sorter: true,
  },
  {
    title: 'Ref. Agent Renouv.',
    key: 'renewAgentRef',
    dataIndex: 'renewAgentRef',
    sorter: true,
  },
];

export const PROOF_OF_USED_RENEWED_COLUMNS = [
  {
    title: 'AR',
    dataIndex: 'numberLabel',
    width: '50px',
    key: 'numberLabel',
    render: (_: any, data: any) => {
      if (data.numberLabel && data.numberLabel === 'AR') {
        return <IconStatus success />;
      }
    },
    sorter: true,
  },
  {
    title: "Date d'échéance",
    dataIndex: 'depositDate',
    key: 'depositDate',
    render: (_: any, data: any) => {
      if (data.depositDate) {
        return formatDate(new Date(data.depositDate));
      }
    },
    sorter: true,
  },
  {
    title: 'N° de dépôt',
    dataIndex: 'depositNumber',
    key: 'depositNumber',
    sorter: true,
  },
  {
    title: "Type d'échéance",
    dataIndex: 'type',
    key: 'type',
    render: (_: any, data: any) => {
      if (data.renpu) {
        return data.renpu;
      }
      if (data.rendpu) {
        return data.rendpu;
      }
    },
    sorter: true,
  },
  {
    title: 'Réf. BDL',
    dataIndex: 'bdlRef',
    key: 'bdlRef',
    sorter: true,
  },
  {
    title: 'Anc. Réf. BDL',
    dataIndex: 'misRef',
    key: 'misRef',
    sorter: true,
  },
  {
    title: 'Marque',
    dataIndex: 'title',
    key: 'title',
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        target="_blank"
        to={`/trademark/${record.id}`}
      >
        {record.title}
      </Link>
    ),
    sorter: true,
  },
  {
    title: 'Pays',
    dataIndex: 'countryFr',
    key: 'countryFr',
    sorter: true,
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        to={{ pathname: getAlissiaResourceUrl(Number(record.alissiaId)) }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record.countryFr}
      </Link>
    ),
  },
  {
    title: "Date d'enr.",
    dataIndex: 'firstRegistrationDate',
    key: 'firstRegistrationDate',
    render: (_: any, data: any) => {
      if (data.firstRegistrationDate) {
        return formatDate(new Date(data.firstRegistrationDate));
      }
      if (data.lastRegistrationDate) {
        return formatDate(new Date(data.lastRegistrationDate));
      }
    },
    sorter: true,
  },
  {
    title: "Date d'octroi",
    dataIndex: 'grantDate',
    key: 'grantDate',
    render: (_: any, record: any) => {
      if (record.countryCode === 'US' && record.registrationDateUS) {
        return formatDate(new Date(record.registrationDateUS));
      }
      if (record.countryCode === 'JP' && record.registrationDateJP) {
        return formatDate(new Date(record.registrationDateJP));
      }
      if (record.countryCode === 'MX' && record.registrationDateMX) {
        return formatDate(new Date(record.registrationDateMX));
      }
      if (record.grantDate) {
        return formatDate(new Date(record.grantDate));
      }
    },
    sorter: true,
  },
  {
    title: 'Classes',
    dataIndex: 'classes',
    key: 'classes',
    sorter: false,
    render: (_: any, data: any) => {
      return <ClassesPopover classes={data.classes ?? []} />;
    },
  },
  {
    title: 'Titulaire',
    dataIndex: 'holder',
    key: 'holder',
    sorter: true,
  },
  {
    title: 'Agent Renouv.',
    dataIndex: 'renewAgentName',
    key: 'renewAgentName',
    sorter: true,
  },
  {
    title: 'Ref. Agent Renouv.',
    key: 'renewAgentRef',
    dataIndex: 'renewAgentRef',
    sorter: true,
  },
];

export const TRADEMARK_ABANDONMENT_COLUMNS = [
  {
    title: 'Statut',
    dataIndex: 'status',
    width: '80px',
    key: 'confirmationReceipt',
    render: (_: any, data: any) => {
      if (data.abandonmentDate) {
        return <IconStatus success={false} />;
      }
    },
    sorter: false,
  },
  {
    title: "Date d'abandon",
    dataIndex: 'abandonmentDate',
    key: 'abandonmentDate',
    render: (_: any, data: any) => {
      if (data.abandonmentDate) {
        return formatDate(new Date(data.abandonmentDate));
      }
    },
    sorter: true,
  },

  {
    title: 'Réf. BDL',
    dataIndex: 'bdlRef',
    key: 'bdlRef',
    sorter: true,
  },
  {
    title: 'Anc. Réf. BDL',
    dataIndex: 'misRef',
    key: 'misRef',
    sorter: true,
  },
  {
    title: 'Marque',
    dataIndex: 'title',
    key: 'title',
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        target="_blank"
        to={`/trademark/${record.id}`}
      >
        {record.title}
      </Link>
    ),
    sorter: true,
  },
  {
    title: 'Pays',
    dataIndex: 'countryFr',
    key: 'countryFr',
    sorter: true,
    render: (_: any, record: any) => (
      <Link
        className="titleRedirect"
        to={{ pathname: getAlissiaResourceUrl(Number(record.alissiaId)) }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record.countryFr}
      </Link>
    ),
  },
  {
    title: "Date d'enr.",
    dataIndex: 'firstRegistrationDate',
    key: 'firstRegistrationDate',
    render: (_: any, data: any) => {
      if (data.firstRegistrationDate) {
        return formatDate(new Date(data.firstRegistrationDate));
      }
      if (data.lastRegistrationDate) {
        return formatDate(new Date(data.lastRegistrationDate));
      }
    },
    sorter: true,
  },
  {
    title: 'Titulaire',
    dataIndex: 'holder',
    key: 'holder',
    sorter: true,
  },
  {
    title: 'Agent Renouv.',
    dataIndex: 'renewAgentName',
    key: 'renewAgentName',
    sorter: true,
  },
  {
    title: 'Ref. Agent Renouv.',
    key: 'renewAgentRef',
    dataIndex: 'renewAgentRef',
    sorter: true,
  },
];
