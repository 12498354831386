import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import Card from 'ui/card';
import ErrorOverlay from 'ui/errorOverlay';
import Spacer from 'ui/spacer';
import { useGetPublicDns } from 'business/domain/services/domain.service';

const PublicDns = (props: { domainId: number }) => {
  const { t } = useTranslation();
  const { certificate, publicDomainDNSDataState, servers, zones } =
    useGetPublicDns(props.domainId);

  return (
    <Spacer direction="vertical" style={{ marginTop: '20px' }}>
      <Card
        title={t('pages.domain.dnsPublicData.servers.title')}
        className="fullHeight"
      >
        <Table
          className="marginTop20"
          columns={[
            { key: 'serverName', dataIndex: 'serverName' },
            { key: 'server', dataIndex: 'server' },
          ]}
          showHeader={false}
          dataSource={servers}
        />
      </Card>
      <Card
        title={t('pages.domain.dnsPublicData.records.title')}
        className="fullHeight"
      >
        <Table
          className="marginTop20"
          columns={[
            { key: 'name', title: 'Nom', dataIndex: 'name' },
            { key: 'type', title: 'type', dataIndex: 'type' },
            {
              key: 'value',
              ellipsis: true,
              title: 'valeur',
              dataIndex: 'value',
            },
            {
              key: 'ttl',
              title: 'TTL',
              dataIndex: 'ttl',
              render: (_: any, data: any) =>
                data.ttl ? `${data.ttl} sec.` : null,
            },
          ]}
          dataSource={zones}
        />
      </Card>

      {certificate && (
        <ErrorOverlay {...publicDomainDNSDataState}>
          <Card title={t('pages.domain.dnsPublicData.sslCertificate.title')}>
            <Table
              className="marginTop10"
              columns={[
                { key: 'provider', dataIndex: 'provider' },
                { key: 'domain', dataIndex: 'domain' },
                {
                  key: 'validFrom',
                  render: (_: any, record: any) => {
                    return record.validFrom;
                  },
                },
                {
                  key: 'validTo',
                  render: (_: any, record: any) => {
                    return record.validTo;
                  },
                },
              ]}
              dataSource={[certificate]}
            />
          </Card>
        </ErrorOverlay>
      )}
    </Spacer>
  );
};

export default PublicDns;
