import { useTranslation } from 'react-i18next';
import eventsColumns from './events.columns';
import useTableColumn from 'technical/table/useTableColumn';
import { useState } from 'react';
import {
  useDownloadDesignEventsLazyQuery,
  useDownloadDomainEventsLazyQuery,
  useDownloadPatentEventsLazyQuery,
  useDownloadTrademarkEventsLazyQuery,
  useGetAllDesignEventsQuery,
  useGetAllDomainEventsQuery,
  useGetAllEventsQuery,
  useGetAllPatentEventsQuery,
  useGetAllTrademarkEventsQuery,
} from 'generated/graphql';
import { convertAndDownloadFile } from 'technical/fileManagement';
import { format } from 'date-fns';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { notification } from 'antd';

const useGetPatentEvents = () => {
  const { t } = useTranslation();
  const defaultColumns = eventsColumns(t).patentEvents;
  const visibleColumns = useTableColumn(defaultColumns);
  const [filters, setFilters] = useState<any>({
    displayAnnuities: true,
  });
  const [downloadPatentEvents, { loading: loadingExport }] =
    useDownloadPatentEventsLazyQuery();

  const download = async () => {
    try {
      const { data } = await downloadPatentEvents({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: defaultColumns
              .filter((c) => {
                return visibleColumns.visibleColumns.includes(
                  c.key?.toString() ?? '',
                );
              })
              .map((c) => ({
                key: c.key?.toString() ?? '',
                value: c.title?.toString() ?? '',
              })),
            filters: { ...filters },
          },
        },
      });
      return await convertAndDownloadFile(
        `export_patent_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadPatentEvents?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download patents',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };

  const { data, error, loading } = useGetAllPatentEventsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        filters: {
          ...filters,
        },
        orderBy: visibleColumns.orderByState,
        limit: visibleColumns.paginationState.pageSize,
        offset:
          (visibleColumns.paginationState.current - 1) *
          visibleColumns.paginationState.pageSize,
      },
    },
  });

  return {
    data: {
      resources: data?.getPatentEvents?.resources ?? [],
      count: data?.getPatentEvents?.count ?? 0,
      loading,
      error,
    },
    tableColumns: {
      visibleColumns,
      defaultColumns,
    },
    filters,
    setFilters,
    export: {
      download: download,
      loading: loadingExport,
    },
  };
};

const useGetTrademarkEvents = () => {
  const { t } = useTranslation();
  const defaultColumns = eventsColumns(t).trademarkEvents;
  const visibleColumns = useTableColumn(defaultColumns);
  const [filters, setFilters] = useState<any>({
    displayAnnuities: true,
  });

  const [downloadEvents, { loading: loadingExport }] =
    useDownloadTrademarkEventsLazyQuery();

  const download = async () => {
    try {
      const { data } = await downloadEvents({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: defaultColumns
              .filter((c) => {
                return visibleColumns.visibleColumns.includes(
                  c.key?.toString() ?? '',
                );
              })
              .map((c) => ({
                key: c.key?.toString() ?? '',
                value: c.title?.toString() ?? '',
              })),
            filters: { ...filters },
          },
        },
      });
      return await convertAndDownloadFile(
        `export_trademark_events_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadTrademarkEvents?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download trademark',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };

  const { data, error, loading } = useGetAllTrademarkEventsQuery({
    variables: {
      data: {
        filters: {
          ...filters,
        },
        orderBy: visibleColumns.orderByState,
        limit: visibleColumns.paginationState.pageSize,
        offset:
          (visibleColumns.paginationState.current - 1) *
          visibleColumns.paginationState.pageSize,
      },
    },
  });

  return {
    data: {
      resources: data?.getTrademarkEvents?.resources ?? [],
      count: data?.getTrademarkEvents?.count ?? 0,
      error,
      loading,
    },
    tableColumns: {
      visibleColumns,
      defaultColumns,
    },
    filters,
    setFilters,
    export: {
      download: download,
      loading: loadingExport,
    },
  };
};

const useGetDesignEvents = () => {
  const { t } = useTranslation();
  const defaultColumns = eventsColumns(t).designEvents;
  const visibleColumns = useTableColumn(defaultColumns);
  const [filters, setFilters] = useState<any>({
    displayAnnuities: true,
  });

  const [downloadEvents, { loading: loadingExport }] =
    useDownloadDesignEventsLazyQuery();

  const download = async () => {
    try {
      const { data } = await downloadEvents({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: defaultColumns
              .filter((c) => {
                return visibleColumns.visibleColumns.includes(
                  c.key?.toString() ?? '',
                );
              })
              .map((c) => ({
                key: c.key?.toString() ?? '',
                value: c.title?.toString() ?? '',
              })),
            filters: { ...filters },
          },
        },
      });
      return await convertAndDownloadFile(
        `export_design_events_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadDesignEvents?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download trademark',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };

  const { data, error, loading } = useGetAllDesignEventsQuery({
    variables: {
      data: {
        filters: {
          ...filters,
        },
        orderBy: visibleColumns.orderByState,
        limit: visibleColumns.paginationState.pageSize,
        offset:
          (visibleColumns.paginationState.current - 1) *
          visibleColumns.paginationState.pageSize,
      },
    },
  });
  return {
    data: {
      resources: data?.getDesignEvents?.resources ?? [],
      count: data?.getDesignEvents?.count ?? 0,
      error,
      loading,
    },
    tableColumns: {
      visibleColumns,
      defaultColumns,
    },
    filters,
    setFilters,
    export: {
      download: download,
      loading: loadingExport,
    },
  };
};

const useGetDomainEvents = () => {
  const { t } = useTranslation();
  const defaultColumns = eventsColumns(t).domainEvents;
  const visibleColumns = useTableColumn(defaultColumns);
  const [filters, setFilters] = useState<any>({
    displayAnnuities: true,
  });

  const [downloadEvents, { loading: loadingExport }] =
    useDownloadDomainEventsLazyQuery();

  const download = async () => {
    try {
      const { data } = await downloadEvents({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: defaultColumns
              .filter((c) => {
                return visibleColumns.visibleColumns.includes(
                  c.key?.toString() ?? '',
                );
              })
              .map((c) => ({
                key: c.key?.toString() ?? '',
                value: c.title?.toString() ?? '',
              })),
            filters: { ...filters },
          },
        },
      });
      return await convertAndDownloadFile(
        `export_domain_events_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadDomainEvents?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download trademark',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };
  const { data, error, loading } = useGetAllDomainEventsQuery({
    variables: {
      data: {
        filters: {
          ...filters,
        },
        orderBy: visibleColumns.orderByState,
        limit: visibleColumns.paginationState.pageSize,
        offset:
          (visibleColumns.paginationState.current - 1) *
          visibleColumns.paginationState.pageSize,
      },
    },
  });

  return {
    data: {
      resources: data?.getDomainEvents?.resources ?? [],
      count: data?.getDomainEvents?.count ?? 0,
      error,
      loading,
    },
    tableColumns: {
      visibleColumns,
      defaultColumns,
    },
    filters,
    setFilters,
    export: {
      download: download,
      loading: loadingExport,
    },
  };
};

export const useGetAllEvents = () => {
  const { t } = useTranslation();
  const defaultColumns = eventsColumns(t).domainEvents;
  const visibleColumns = useTableColumn(defaultColumns);
  const [filters, setFilters] = useState<any>({
    displayAnnuities: true,
  });

  const [downloadEvents, { loading: loadingExport }] =
    useDownloadDomainEventsLazyQuery();

  const download = async () => {
    try {
      const { data } = await downloadEvents({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: defaultColumns
              .filter((c) => {
                return visibleColumns.visibleColumns.includes(
                  c.key?.toString() ?? '',
                );
              })
              .map((c) => ({
                key: c.key?.toString() ?? '',
                value: c.title?.toString() ?? '',
              })),
            filters: { ...filters },
          },
        },
      });
      return await convertAndDownloadFile(
        `export_all_events_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadDomainEvents?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download all events',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };
  const { data } = useGetAllEventsQuery({
    variables: {
      data: {
        filters: {
          ...filters,
        },
        orderBy: visibleColumns.orderByState,
        limit: visibleColumns.paginationState.pageSize,
        offset:
          (visibleColumns.paginationState.current - 1) *
          visibleColumns.paginationState.pageSize,
      },
    },
  });

  return {
    data: {
      resources: data?.searchAllEvents?.resources ?? [],
      count: data?.searchAllEvents?.count ?? 0,
    },
    tableColumns: {
      visibleColumns,
      defaultColumns,
    },
    filters,
    setFilters,
    export: {
      download: download,
      loading: loadingExport,
    },
  };
};

export const useGetEventsData = () => {
  const patentEvents = useGetPatentEvents();
  const trademarkEvents = useGetTrademarkEvents();
  const designEvents = useGetDesignEvents();
  const domainEvents = useGetDomainEvents();
  // const allEvents = useGetAllEvents(); // On enleve cette feature pour le moment car non utilisé

  return {
    patentEvents,
    designEvents,
    trademarkEvents,
    domainEvents,
    // allEvents,
  };
};
