import {
  InfoCircleOutlined,
  PaperClipOutlined,
  TeamOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { Card } from 'antd';
import Status from 'business/common/components/status';
import OfficalLinkButton from 'business/resources/shared/components/officialLinkButton';
import { GetTrademarkQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Carousel from 'ui/carousel';
import Flex from 'ui/flex';
import InfoSection from 'ui/infoSection/infoSection';

interface ITrademarkInformation {
  trademark: GetTrademarkQuery['trademark_by_pk'];
  images: string[];
  isLoading: boolean;
  officialRegisterLink?: string;
}

const TrademarkInformation = ({
  trademark,
  images,
  isLoading,
  officialRegisterLink,
}: ITrademarkInformation) => {
  const { t } = useTranslation();
  return (
    <Flex>
      <Card className="caseDetailCard">
        <InfoSection
          icon={<InfoCircleOutlined />}
          items={[
            {
              label: t('table.common.column.status').toUpperCase(),
              value: <Status status={trademark?.status ?? ''} />,
            },
            {
              label: t('table.common.column.country').toUpperCase(),
              value: trademark?.country?.labelFr,
            },
            {
              label: t('table.common.column.type').toUpperCase(),
              value: trademark?.protection?.protectionLabel,
            },
          ]}
        />
        <InfoSection
          icon={<UsergroupDeleteOutlined />}
          items={[
            {
              label: t('table.common.column.holder').toUpperCase(),
              value: trademark?.holder,
            },
            {
              label: t('table.common.column.effectiveHolder').toUpperCase(),
              value: trademark?.effectiveHolder,
            },
          ]}
        />
        <InfoSection
          icon={<PaperClipOutlined />}
          items={[
            {
              label: t('table.common.column.bdlRef').toUpperCase(),
              value: trademark?.bdlRef,
            },
            {
              label: t('table.common.column.clientRef').toUpperCase(),
              value: trademark?.clientRef,
            },
            {
              label: t('table.common.column.clientRef2').toUpperCase(),
              value: trademark?.clientRef2,
            },
          ]}
        />
        <InfoSection
          icon={<TeamOutlined />}
          items={[
            {
              label: t('table.common.column.clientOfficer').toUpperCase(),
              value: trademark?.clientOfficer,
            },
            {
              label: t('table.common.column.bdlOfficerName').toUpperCase(),
              value: trademark?.bdlOfficer?.fullName,
            },
            {
              label: t('table.common.column.bdlSecretaryName').toUpperCase(),
              value: trademark?.bdlSecretary?.fullName,
            },
          ]}
        />
      </Card>

      {/* SECTION IMAGE */}
      <div
        style={{
          background: '#FFFFFF',
          width: '30%',
          height: '100%',
          borderRadius: '20px',
          boxShadow: '0px 1px 4.6px 0px #00000038',
        }}
      >
        <Carousel urls={images} loading={isLoading} />
        {officialRegisterLink ? (
          <OfficalLinkButton link={officialRegisterLink} />
        ) : undefined}
      </div>
    </Flex>
  );
};

export default TrademarkInformation;
