import Routes from 'business/router/routes';
import Spacer from 'ui/spacer';

interface HeaderTitlesProps {
  title: string;
  subtitle: string;
  dataTestId?: string;
  children?: React.ReactNode;
  family?: { id?: string | null; route: Routes };
}

const HeaderTitles = ({ title, subtitle }: HeaderTitlesProps) => {
  return (
    <Spacer size="xxsmall" direction="vertical">
      <h1 className="caseCountryTitle">{title}</h1>
      <h2 className="caseTitle">{subtitle}</h2>
    </Spacer>
  );
};

export default HeaderTitles;
