// $$$ => replace with asset publication or deposit number
export const links = {
  patent: {
    FR: 'https://data.inpi.fr/brevets/FR$$$',
    FR_BE: 'https://data.inpi.fr/brevets/EP$$$',
    EP: 'https://register.epo.org/application?tab=main&number=EP$$$',
    DE: 'https://register.dpma.de/DPMAregister/pat/register?lang=en&AKZ=E$$$',
    US: 'https://patentcenter.uspto.gov/applications/$$$',
    JP: 'https://register.epo.org/ipfwretrieve?apn=JP.$$$.A',
    CN: 'https://register.epo.org/ipfwretrieve?apn=CN.$$$.A',
    GB: 'https://www.ipo.gov.uk/p-ipsum/Case/PublicationNumber/GB$$$',
    GB_EP: 'https://www.ipo.gov.uk/p-ipsum/Case/PublicationNumber/EP$$$',
    QT: 'https://patentscope.wipo.int/search/fr/detail.jsf?docId=WO$$$',
  },
  trademark: {
    EM: 'https://euipo.europa.eu/eSearch/#details/trademarks/$$$',
    FR: 'https://data.inpi.fr/marques/FR$$$',
    WO: 'http://www.wipo.int/madrid/monitor/fr/showData.jsp?ID=ROM.$$$',
    US_NAT:
      'http://tsdr.uspto.gov/#caseNumber=$$$&caseType=SERIAL_NO&searchType=statusSearch',
    US_MP:
      'http://tsdr.uspto.gov/#caseNumber=$$$&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch',
    GB_NAT: 'https://www.ipo.gov.uk/tmcase/Results/1/UK$$$',
    GB_MP: 'https://www.ipo.gov.uk/tmcase/Results/2/WO$$$',
  },
  design: {
    WO: 'https://www.wipo.int/designdb/en/showData.jsp?&ID=HAGUE.D$$$',
    EM: 'https://euipo.europa.eu/eSearch/#details/designs/$$$',
    GB: 'https://www.registered-design.service.gov.uk/find/$$$',
    FR: 'https://data.inpi.fr/dessins_modeles/FR$$$',
    CN: 'https://www3.wipo.int/designdb/en/showData.jsp?ID=CNID.$$$',
    RU: 'https://www.fips.ru/registers-doc-view/fips_servlet?DB=RUDE&DocNumber=$$$',
  },
};
