import { Col, Divider, Row } from 'antd';
import InfoItem from './infoItem';

const InfoSection = ({
  icon,
  items,
}: {
  icon: React.ReactNode;
  items: { label: string; value?: React.ReactNode }[];
}) => {
  return (
    <>
      <Row>
        <Col span={1}>{icon}</Col>
        {items.map((item, idx) => (
          <InfoItem key={idx} label={item.label} value={item.value} />
        ))}
      </Row>
      <Divider />
    </>
  );
};

export default InfoSection;
