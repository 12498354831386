import { MailOutlined } from '@ant-design/icons';
import { createMailProps } from 'business/common/services/mailto';
import { ResourceType } from 'business/common/services/types';
import { Resource } from 'business/common/services/types';
import { useTranslation } from 'react-i18next';
import MailButton from 'ui/button/mailButton';

type ContactBdlByMailButtonProps = {
  resource: Resource;
  resourceType: ResourceType;
};

const ContactBdlByMailButton: React.FC<ContactBdlByMailButtonProps> = ({
  resource,
  resourceType,
}) => {
  const { t } = useTranslation();

  return (
    <MailButton
      {...createMailProps(resource, resourceType, t)}
      icon={<MailOutlined />}
    >
      {t('pages.common.contactBdlByMailButton')}
    </MailButton>
  );
};

export default ContactBdlByMailButton;
