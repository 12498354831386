import EventTable from 'business/resources/shared/components/event/eventTable';
import { useGetDomainEvents } from 'business/domain/services/domain.service';

const EventTab = ({ id }: { id: number }) => {
  const { data, tableProps } = useGetDomainEvents(id);

  return (
    <EventTable
      dataSource={data}
      {...tableProps}
      searchBarEnabled={false}
      editButtonEnabled={false}
    />
  );
};

export default EventTab;
