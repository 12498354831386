import {
  GetFiltersDataQuery,
  Protection_Bool_Exp,
  useGetFiltersDataQuery,
} from 'generated/graphql';
import { TFunction } from 'i18next';
import { getCountryLabel } from 'technical/countriesTranslation';
import { isDefined } from 'technical/validation';
import { RESOURCES_STATUS_FILTERS } from './constant';
import { getProperty } from 'technical/types';

const DOMAIN_EVENT_TYPES = ['domain_renew', 'domain_transfer', 'domain_create'];

const familyToProtectionConditionRecord: Record<string, Protection_Bool_Exp> = {
  patent: { objectCode: { _eq: 'PATENT' } },
  design: { objectCode: { _eq: 'DESIGN' } },
  domain: { objectCode: { _eq: 'DOMAIN' } },
  trademark: { objectCode: { _eq: 'TRADEMAR' } },
  all: {},
};

const familyToStatuses = (t: TFunction, family: string) =>
  RESOURCES_STATUS_FILTERS[family].map((code) => ({
    code,
    label: t(`pages.advancedSearch.filtersDrawer.statuses`, { context: code }),
  }));

export const getEventTypes = (
  t: TFunction,
  family: string,
  data: GetFiltersDataQuery,
) => {
  const formatEventTypes = <T extends { type: string }>(arr: T[]) =>
    arr.map(getProperty('type')).map((code) => ({ code, label: code }));

  const eventTypesByFamily = {
    patent: formatEventTypes(data.event_patentEvent ?? []),
    design: formatEventTypes(data.event_designEvent ?? []),
    trademark: formatEventTypes(data.event_trademarkEvent ?? []),
    domain: DOMAIN_EVENT_TYPES.map((type) => ({
      code: type,
      label: t('table.common.column.domainType', { context: type }),
    })),
  };

  if (family === 'all') {
    return eventTypesByFamily.patent.concat(
      eventTypesByFamily.design,
      eventTypesByFamily.trademark,
      eventTypesByFamily.domain,
    );
  }
  return eventTypesByFamily[family as keyof typeof eventTypesByFamily];
};

export const useGetFiltersData = (
  t: TFunction,
  family: string,
  includeEvents = false,
) => {
  const { data } = useGetFiltersDataQuery({
    variables: {
      protectionWhere: familyToProtectionConditionRecord[family],
      includeEvents,
    },
  });
  if (!data) {
    return {
      countryCode: [],
      extensions: [],
      protectionCode: [],
      statuses: [],
    };
  }

  let countries;
  if (family === 'design' || family === 'trademark') {
    countries = data.country
      .filter((d) => d.code != 'EP')
      .map((country) => ({
        code: country.code,
        label: getCountryLabel(country),
      }));
  } else {
    countries = data.country.map((country) => ({
      code: country.code,
      label: getCountryLabel(country),
    }));
  }

  return {
    countryCode: countries,
    extensions: data.domain
      .map(({ extension }) =>
        extension ? { code: extension, label: extension } : null,
      )
      .filter(isDefined),
    protectionCode: data.protection.map(
      ({ protectionCode: code, protectionLabel: label }) => ({ code, label }),
    ),
    statuses: familyToStatuses(t, family),
    eventTypes: getEventTypes(t, family, data),
  };
};
