import { Drawer, Tabs } from 'antd';
import ConnectedPage from 'business/user/helpers/connectedPage';
import Button from 'ui/button';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import Spacer from 'ui/spacer';
import Table from 'ui/v2/table/table';
import {
  useGetResourcesData,
  useSearch,
} from 'business/search/services/search.service';
import styles from 'business/search/search.module.scss';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import { useState } from 'react';
import PatentSearchFilters from 'business/search/components/patentSearchFilters';
import DesignSearchFilters from 'business/search/components/designSearchFilters';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, FilterFilled } from '@ant-design/icons';
import TrademarkSearchFilters from 'business/search/components/trademarkSearchFilters';
import {
  GetDesignsDto,
  GetDomainsDto,
  GetPatentsSearchDto,
  GetTrademarksDto,
} from 'generated/graphql';
import DomainSearchFilters from 'business/search/components/domainSearchFilters';

const SearchPage = () => {
  const { t } = useTranslation();
  const { search } = useSearch();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('patents');
  const resources = useGetResourcesData(search);

  const currentResource = resources[activeTab as keyof typeof resources];

  return (
    <ConnectedPage>
      <Spacer style={{ marginTop: '50px' }} direction="vertical" size="small">
        <Spacer justify="space-between">
          <HeaderTitles title="Recherche avancée" subtitle="" />
          <Spacer size="xsmall">
            <Button
              data-test-id="advancedFiltersButton"
              className={styles.filtersButton}
              onClick={() => setFiltersOpen(!filtersOpen)}
              icon={<FilterFilled color="black" />}
            >
              {t('pages.advancedSearch.advancedFiltersButton')}
            </Button>
            <EditColumnsButton
              className={styles.editColumnsButton}
              initialColumns={currentResource.tableColumns.defaultColumns}
              visibleColumns={
                currentResource.tableColumns.visibleColumns.visibleColumns
              }
              onChange={
                currentResource.tableColumns.visibleColumns
                  .toggleColumnVisibility
              }
            />
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              loading={currentResource.export.loading}
              onClick={() => currentResource.export.download()}
            >
              {t('table.common.exportButton')}
            </Button>
          </Spacer>
        </Spacer>
        <Tabs
          type="card"
          size="large"
          animated
          onChange={(key) => {
            setActiveTab(key);
          }}
          items={[
            {
              label: t('ressources.patent', {
                total: resources.patents.data?.count ?? 0,
              }),
              key: 'patents',
              children: (
                <Table
                  loading={currentResource.loading}
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            {
              label: t('ressources.trademark', {
                total: resources.trademarks.data?.count ?? 0,
              }),
              key: 'trademarks',
              children: (
                <Table
                  loading={currentResource.loading}
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            {
              label: t('ressources.design', {
                total: resources.designs.data?.count ?? 0,
              }),
              key: 'designs',
              children: (
                <Table
                  loading={currentResource.loading}
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            {
              label: t('ressources.domain', {
                total: resources.domains.data?.count ?? 0,
              }),
              key: 'domains',
              children: (
                <Table
                  loading={currentResource.loading}
                  dataSource={currentResource.data?.resources ?? []}
                  columns={
                    currentResource.tableColumns.visibleColumns.tableColumns
                  }
                  onChange={
                    currentResource.tableColumns.visibleColumns.handlePagination
                  }
                  pagination={{
                    ...currentResource.tableColumns.visibleColumns
                      .paginationState,
                    total: currentResource.data?.count ?? 0,
                  }}
                />
              ),
            },
            // On enleve cette feature pour le moment. NOn utilisé selon le client
            // {
            //   label: t('ressources.allResources', {
            //     total: resources.allResources.data?.count ?? 0,
            //   }),
            //   key: 'allResources',
            //   children: (
            //     <Table
            //       dataSource={currentResource.data?.resources ?? []}
            //       columns={
            //         currentResource.tableColumns.visibleColumns.tableColumns
            //       }
            //       onChange={
            //         currentResource.tableColumns.visibleColumns.handlePagination
            //       }
            //       pagination={{
            //         ...currentResource.tableColumns.visibleColumns
            //           .paginationState,
            //         total: currentResource.data?.count ?? 0,
            //       }}
            //     />
            //   ),
            // },
          ]}
        />
        <Drawer
          closable
          open={filtersOpen}
          width={450}
          title={t('pages.advancedSearch.filtersDrawer.title', {
            context: activeTab,
          })}
          onClose={() => setFiltersOpen(!filtersOpen)}
        >
          {activeTab === 'patents' ? (
            <PatentSearchFilters
              initialValues={currentResource.filters as GetPatentsSearchDto}
              onFinish={(values) => {
                currentResource.onChangeFilter(values);
              }}
            />
          ) : undefined}
          {activeTab === 'designs' ? (
            <DesignSearchFilters
              initialValues={
                currentResource.filters as NonNullable<GetDesignsDto['where']>
              }
              onFinish={(values) => {
                currentResource.onChangeFilter(values);
              }}
            />
          ) : undefined}

          {activeTab === 'trademarks' ? (
            <TrademarkSearchFilters
              initialValues={currentResource.filters as GetTrademarksDto}
              onFinish={(values) => {
                currentResource.onChangeFilter(values);
              }}
            />
          ) : undefined}

          {activeTab === 'domains' ? (
            <DomainSearchFilters
              initialValues={
                currentResource.filters as GetDomainsDto['filters']
              }
              onFinish={(values) => {
                currentResource.onChangeFilter(values);
              }}
            />
          ) : undefined}
        </Drawer>
      </Spacer>
    </ConnectedPage>
  );
};
export default SearchPage;
