import { TFunction } from 'i18next';

import { formatDate } from 'technical/date';
import ArchiveDocumentTableActionColumn from './archiveDocumentTable/actionsColumn';
import IsValidatedIcon from 'ui/isValidatedIcon';
import { getFormatFile } from 'technical/fileManagement/constants';
import { formatBytes } from 'technical/fileManagement';
import { UserArchivesDocuments } from 'business/archive/services/types';

const getArchiveColumns = (
  refetch: (() => void) | undefined,
  setEditingArchive: (archive: UserArchivesDocuments) => void,
  t: TFunction,
) => [
  {
    key: 'id',
    dataIndex: 'id',
    title: t(`table.common.column.id`),
    ellipsis: true,
    checked: false,
    width: 70,
  },
  {
    key: 'fileName',
    dataIndex: 'fileName',
    title: t(`table.common.column.fileName`),
    width: 160,
  },
  {
    key: 'keyword',
    dataIndex: 'keyword',
    title: t(`table.common.column.keyword`),
    ellipsis: true,
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: t(`table.common.column.description`),
    width: 200,
  },
  {
    key: 'createdAt',
    title: t(`table.common.column.archiveDate`),
    ellipsis: true,
    render: (_: any, { createdAt }: any) => (
      <span>{formatDate(new Date(createdAt))}</span>
    ),
  },
  {
    key: 'storageEndDate',
    title: t(`table.common.column.archiveEndDate`),
    ellipsis: true,
    checked: false,
    render: (_: any, { storageEndDate, createdAt }: any) => (
      <span>{formatDate(new Date(storageEndDate ?? createdAt))}</span>
    ),
  },
  {
    key: 'companyRef',
    dataIndex: 'companyRef',
    title: t(`table.common.column.companyRef`),
    checked: false,
    ellipsis: true,
  },
  {
    key: 'clientReference1',
    dataIndex: 'clientReference1',
    title: t(`table.common.column.clientReference1`),
    ellipsis: true,
  },
  {
    key: 'clientReference2',
    dataIndex: 'clientReference2',
    title: t(`table.common.column.clientReference2`),
    ellipsis: true,
  },
  {
    key: 'company',
    dataIndex: 'company',
    title: t(`table.common.column.company`),
    checked: false,
    ellipsis: true,
  },
  {
    key: 'authorName',
    dataIndex: 'authorName',
    title: t(`table.common.column.authorName`),
    ellipsis: true,
  },
  {
    key: 'fileSize',
    title: t(`table.common.column.fileSize`),
    checked: false,
    width: 100,
    render: (_: any, { fileSize }: any) => (
      <span>{formatBytes(fileSize ?? 0)}</span>
    ),
  },
  {
    key: 'fileType',
    title: t(`table.common.column.fileType`),
    width: 100,
    render: (_: any, { fileType }: any) => (
      <span>{getFormatFile(fileType)?.toUpperCase()}</span>
    ),
  },
  {
    key: 'arkhineoArchiveId',
    title: t(`table.common.column.iua`),
    checked: false,
    ellipsis: true,
    render: (_: any, { arkhineoArchiveId }: any) => {
      if (arkhineoArchiveId) {
        return <span>{arkhineoArchiveId}</span>;
      }
      return (
        <span>{t('table.common.column.archive', { context: 'loading' })}</span>
      );
    },
  },
  {
    key: 'delivered',
    title: t(`table.common.column.delivered`),
    ellipsis: true,
    checked: false,
    render: (_: any, record: any) => (
      <IsValidatedIcon isValid={record.delivered} />
    ),
  },
  {
    key: 'actions',
    title: t(`table.common.column.actions`),
    alwaysVisible: true,
    sorter: false,
    width: 130,
    render: (_: any, record: any) => (
      <ArchiveDocumentTableActionColumn
        setEditingArchive={setEditingArchive}
        refetch={refetch}
        archive={record}
      />
    ),
  },
];

export default getArchiveColumns;
