import React, { useState } from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import Spacer from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import useTableColumn from 'technical/table/useTableColumn';
import domainColumns from 'business/domain/services/columns.constant';
import { useGetDomainFamilies } from 'business/domain/services/domainFamilies.service';
import Table from 'ui/v2/table/table';
import Flex from 'ui/flex';
import TagResults from 'ui/tag/tagResults';
import { Checkbox } from 'antd';
import SearchInput from 'ui/v2/input/searchInput';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Button from 'ui/button';
import { DownloadOutlined } from '@ant-design/icons';

const DomainFamiliesPage: React.FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isEffective, setIsEffective] = useState(false);

  const columns = domainColumns(t).domainFamilies;
  const domainFamiliesColumns = useTableColumn(columns);

  const { data, loading, total } = useGetDomainFamilies({
    isEffective,
    search,
    limit: domainFamiliesColumns.paginationState.pageSize,
    offset:
      (domainFamiliesColumns.paginationState.current - 1) *
      domainFamiliesColumns.paginationState.pageSize,
    orderBy: domainFamiliesColumns.orderByState,
  });

  return (
    <ConnectedPage translationKey="domains">
      <Spacer direction="vertical" size="small" style={{ marginTop: '35px' }}>
        <Spacer align="center" size="xxsmall">
          <h1 className="headerTitle">{t('pages.header.domainFamilies')}</h1>
          <TagResults total={total} dataTestId="domainTotal" />
        </Spacer>
        <Table
          columns={domainFamiliesColumns.tableColumns}
          dataTestId="patentFamiliesTable"
          dataSource={data ?? []}
          onChange={domainFamiliesColumns.handlePagination}
          loading={loading}
          pagination={{
            current: domainFamiliesColumns.paginationState.current,
            pageSize: domainFamiliesColumns.paginationState.pageSize,
            total,
          }}
          actions={
            <Flex justify="space-between" alignItems="center">
              <Spacer size="xsmall" justify="start" align="center">
                <SearchInput
                  value={search}
                  onDebouncedChange={(e) => setSearch(e)}
                  placeholder={t('common.search', { context: 'table' })}
                  style={{ width: '30%' }}
                />

                <EditColumnsButton
                  initialColumns={columns}
                  visibleColumns={domainFamiliesColumns.visibleColumns}
                  onChange={domainFamiliesColumns.toggleColumnVisibility}
                />
                <Checkbox
                  checked={isEffective}
                  onChange={(e) => setIsEffective(e.target.checked)}
                >
                  {t('table.common.effective')}
                </Checkbox>
              </Spacer>
              <Button
                icon={<DownloadOutlined />}
                loading={false}
                data-test-id="exportButton"
                type="primary"
                onClick={() => {}}
              >
                {t('table.common.exportButton')}
              </Button>
            </Flex>
          }
        />
      </Spacer>
    </ConnectedPage>
  );
};

export default DomainFamiliesPage;
