import {
  useDownloadDesignByFamilyId,
  useGetDesigns,
} from 'business/design/services/designFamily.service';
import { useState } from 'react';
import designColumns from './constant';
import { useTranslation } from 'react-i18next';
import useTableColumn from 'technical/table/useTableColumn';
import Table from 'ui/v2/table/table';
import Spacer from 'ui/spacer';
import Button from 'ui/button';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Flex from 'ui/flex';
import SearchInput from 'ui/v2/input/searchInput';

const DesignFamilyTable = (props: { familyId: string }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const columns = designColumns(t).designFamily;
  const designFamilyColumns = useTableColumn(columns);

  const { data, loading, total } = useGetDesigns({
    id: props.familyId,
    search,
    orderBy: designFamilyColumns.orderByState,
    limit: designFamilyColumns.paginationState.pageSize,
    offset:
      (designFamilyColumns.paginationState.current - 1) *
      designFamilyColumns.paginationState.pageSize,
  });

  const { download, loading: loadingExport } = useDownloadDesignByFamilyId(
    columns,
    designFamilyColumns.visibleColumns,
    {
      search,
      id: props.familyId,
    },
  );

  return (
    <Table
      columns={designFamilyColumns.tableColumns}
      dataTestId="designFamilyTable"
      dataSource={data ?? []}
      onChange={designFamilyColumns.handlePagination}
      loading={loading}
      pagination={{
        current: designFamilyColumns.paginationState.current,
        pageSize: designFamilyColumns.paginationState.pageSize,
        total,
      }}
      actions={
        <Flex justify="space-between">
          <Spacer align="center" size="xsmall" justify="start">
            <SearchInput
              value={search}
              onDebouncedChange={(e) => setSearch(e)}
              placeholder={t('common.search', { context: 'table' })}
              style={{ width: '30%' }}
            />
            <EditColumnsButton
              initialColumns={columns}
              visibleColumns={designFamilyColumns.visibleColumns}
              onChange={designFamilyColumns.toggleColumnVisibility}
            />
          </Spacer>
          <Button
            loading={loadingExport}
            data-test-id="exportButton"
            type="primary"
            onClick={() => download()}
          >
            {t('table.common.exportButton')}
          </Button>
        </Flex>
      }
    />
  );
};

export default DesignFamilyTable;
