import { useTranslation } from 'react-i18next';
import Table from 'ui/v2/table/table';
import columns from './columns.constant';

interface ITrademarkObjections {
  objections: any[];
  isBdlUser: boolean;
}

const TrademarkObjections = (props: ITrademarkObjections) => {
  const { t } = useTranslation();
  const columnsData = columns.trademarkObjections(t, props.isBdlUser);
  return (
    <Table
      loading={false}
      dataSource={props.objections}
      columns={columnsData}
      onChange={() => {}}
      pagination={{
        pageSize: 10,
      }}
      pageSizeOptions={['5']}
    />
  );
};

export default TrademarkObjections;
