import Spacer from 'ui/spacer';
import { formatColumnsToColumnType } from './columns';
import TableActions from './tableActions';
import TableContent from './tableContent';
import { TableProps } from './types';
import useTable from './useTableHook';

const Table = <RecordType extends object>({
  header,
  defaultColumns,
  defaultSort,
  setFilterValue,
  customFilters,
  error,
  editButtonEnabled = true,
  searchBarEnabled = true,
  showEffectiveCheckbox = false,
  showOwnCheckbox = false,
  showDisplayAFUCheckbox = false,
  showDisplayAnnuitiesCheckbox = false,
  family,
  filtersValues,
  customAction,
  refetch,
  loadingExport,
  onExport,
  confirmationReceipt,
  resetButtonEnabled,
  ...props
}: TableProps<RecordType>) => {
  const {
    columns,
    onChangeColumnsVisibility,
    columnsEditionItems,
    resetTable,
  } = useTable({
    defaultColumns,
    defaultSort,
    storageColumnsKeys: filtersValues.visibleColumns,
    setStorageVisibleColumnsKey: (columnsKeys: (keyof RecordType)[]) => {
      setFilterValue('visibleColumns', columnsKeys);
    },
  });

  return (
    <Spacer direction="vertical" size="small">
      <Spacer align="center" size="small">
        {header}
        <TableActions
          onExport={
            onExport
              ? async () => {
                  onExport(formatColumnsToColumnType(columns));
                }
              : undefined
          }
          setColumnsVisibility={onChangeColumnsVisibility}
          columnsEditionItems={columnsEditionItems}
          customFilters={customFilters}
          family={family}
          showDisplayAFUCheckbox={showDisplayAFUCheckbox}
          showDisplayAnnuitiesCheckbox={showDisplayAnnuitiesCheckbox}
          showOwnCheckbox={showOwnCheckbox}
          confirmationReceipt={confirmationReceipt}
          editButtonEnabled={editButtonEnabled}
          showEffectiveCheckbox={showEffectiveCheckbox}
          filtersValues={filtersValues}
          customAction={customAction}
          searchBarEnabled={searchBarEnabled}
          resetButtonEnabled={resetButtonEnabled}
          resetTable={resetTable}
          setFilterValue={setFilterValue}
          loadingExport={loadingExport ?? false}
        />
      </Spacer>
      <TableContent
        {...props}
        columns={columns}
        error={error}
        refetch={refetch}
      />
    </Spacer>
  );
};

export default Table;
