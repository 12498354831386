import {
  isResourceNotEffective,
  resourcesToMapData,
} from 'business/common/services';
import {
  GetDomainQuery,
  GetDomainsDto,
  useGetDomainFamilyBreadcrumbQuery,
  useGetDomainFamilyMapQuery,
  useGetDomainsQuery,
} from 'generated/graphql';
import { DomainMap, DomainOfFamily } from './domain.types';
import { MapStatus } from 'ui/map/types';
import {
  BreadcrumbResource,
  useResourceBreadcrumbs,
} from 'business/common/services/breadcrumbs';
import Routes from 'business/router/routes';
import { useMemo } from 'react';

const DOMAIN_EXTENSION_REGEX = /\..+/;

export const removeExtension = (domain: string) =>
  domain.replace(DOMAIN_EXTENSION_REGEX, '');

const useDomainBreadcrumbs = () => {
  const { getResourceBreadcrumbs, getFamilyBreadcrumbs } =
    useResourceBreadcrumbs({
      type: 'domain',
      familyRoute: Routes.DomainFamily,
      rootRoute: Routes.Domain,
    });

  return {
    getFamilyBreadcrumbs: (domain: Pick<DomainOfFamily, 'title'> | null) =>
      getFamilyBreadcrumbs(
        domain
          ? {
              ...domain,
              title: removeExtension(domain.title ?? ''),
            }
          : null,
      ),
    getResourceBreadcrumbs: (
      domain: null | Pick<
        NonNullable<GetDomainQuery['domain_by_pk']>,
        keyof BreadcrumbResource
      >,
    ) =>
      getResourceBreadcrumbs(
        domain
          ? {
              ...domain,
              title: removeExtension(domain.title),
              country: {
                code: domain.title,
                labelEn: domain.title,
                labelFr: domain.title,
              },
            }
          : null,
      ),
  };
};

const isGenericExtensionDomain = ({ countryCode }: DomainMap) =>
  countryCode === 'WW';

export const useGetDomainFamilyMap = (familyId: string) => {
  const { data, loading } = useGetDomainFamilyMapQuery({
    variables: { id: familyId },
  });
  const domains = data?.domain ?? [];

  const dataMap = resourcesToMapData(domains);

  const genericExtensions = domains
    .filter(isGenericExtensionDomain)
    .map((domain) => ({
      code: `${domain.extension}`,
      status: isResourceNotEffective(domain)
        ? MapStatus.NotEffective
        : MapStatus.Valid,
      tooltip: null,
    }));

  return {
    loading,
    dataMap,
    genericExtensions,
  };
};

const useGetDomains = (filters: GetDomainsDto) => {
  const { data, loading, error } = useGetDomainsQuery({
    variables: {
      data: filters,
    },
  });
  return {
    data: data?.getDomains?.resources ?? [],
    count: data?.getDomains?.count ?? 0,
    error,
    loading,
  };
};

const useGetDomainFamilyInfos = (familyId: string) => {
  const { data } = useGetDomainFamilyBreadcrumbQuery({
    variables: { familyId: familyId ?? '' },
    skip: !familyId,
  });
  return data?.family?.[0] ?? null;
};

export const useGetDomainFamilyData = (filters: GetDomainsDto) => {
  const { data, error, loading, count } = useGetDomains(filters);
  const {
    dataMap,
    loading: mapLoading,
    genericExtensions,
  } = useGetDomainFamilyMap(filters.filters?.familyId ?? '');

  const { getFamilyBreadcrumbs } = useDomainBreadcrumbs();

  const familyInfo = useGetDomainFamilyInfos(filters.filters?.familyId ?? '');
  const domainFamilyTitle = useMemo(
    () => removeExtension(familyInfo?.title ?? '').toUpperCase(),
    [familyInfo],
  );
  return {
    data,
    error,
    loading,
    total: count,
    map: {
      loading: mapLoading,
      data: dataMap,
      genericExtensions,
    },
    getFamilyBreadcrumbs,
    familyInfo,
    domainFamilyTitle,
  };
};
