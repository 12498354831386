import Routes from 'business/router/routes';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { ExtractRouteParams, useParams } from 'react-router';
import { useGetDomainData } from 'business/domain/services/domain.service';
import Spacer from 'ui/spacer';
import HeaderTitles from 'ui/headerTitles/headerTitle';
import Timeline from 'ui/timeline';
import { Tabs } from 'antd';
import Flex from 'ui/flex';
import DomainNote from 'business/domain/components/domainNote';
import EventTab from 'business/domain/components/domainEvents';
import DomainInformation from 'business/domain/components/domainInformation';
import PublicDns from 'business/domain/components/publicDns';
import PrivateDns from 'business/domain/components/privateDns';
import { useTranslation } from 'react-i18next';
import ContactBdlByMailButton from 'business/common/components/contactBdlByMailButton';

const DomainPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<ExtractRouteParams<Routes.DomainPage, string>>();
  const { breadcrumbs, data, timeline, isGandiDomain } = useGetDomainData(
    Number(id),
  );
  return (
    <ConnectedPage translationKey="domainDetails" breadcrumbs={breadcrumbs}>
      <Spacer direction="vertical">
        <Spacer>
          <HeaderTitles title={data?.title.toUpperCase() ?? ''} subtitle="" />
          {data ? (
            <ContactBdlByMailButton resource={data} resourceType="domain" />
          ) : undefined}
        </Spacer>

        <Spacer size="small">
          <Spacer size="small" style={{ width: '85%' }} direction="vertical">
            <Timeline events={timeline} />
            <Tabs
              type="card"
              items={[
                {
                  key: 'generalInformation',
                  label: 'Général',
                  children: <DomainInformation domain={data} />,
                },
                {
                  key: 'events',
                  label: 'Echéances',
                  children: (
                    <div style={{ height: '100%' }}>
                      <EventTab id={Number(id)} />
                    </div>
                  ),
                },
                {
                  key: 'publicDNS',
                  label: t('pages.domain.dnsPublicData.title'),
                  children: <PublicDns domainId={Number(id)} />,
                },
                {
                  key: 'dns',
                  label: t('pages.privateDomain.dnsData.title'),
                  children: (
                    <PrivateDns domainId={Number(id)} skip={isGandiDomain} />
                  ),
                },
              ]}
            />
          </Spacer>
          <Flex className="noteSection" column style={{ height: '733px' }}>
            <DomainNote domainId={Number(id)} />
          </Flex>
        </Spacer>
      </Spacer>
    </ConnectedPage>
  );
};

export default DomainPage;
