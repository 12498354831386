import { Button, Checkbox } from 'antd';

import { useTranslation } from 'react-i18next';
import {
  useDownloadDesignFamilies,
  useGetDesignFamilies,
} from 'business/design/services/designFamilies.service';
import { useState } from 'react';
import designColumns from 'business/design/components/constant';
import useTableColumn from 'technical/table/useTableColumn';
import Spacer from 'ui/spacer';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Table from 'ui/v2/table/table';
import Flex from 'ui/flex';
import SearchInput from 'ui/v2/input/searchInput';

const DesignFamiliesTable = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isEffective, setIsEffective] = useState(true);

  const columns = designColumns(t).designFamilies;
  const designFamiliesColumns = useTableColumn(columns);

  const { download, loading: loadingExport } = useDownloadDesignFamilies(
    columns,
    designFamiliesColumns.visibleColumns,
    {
      isEffective,
      search,
    },
  );

  const { designWithImages, loading, total } = useGetDesignFamilies({
    isEffective: isEffective,
    search: search,
    limit: designFamiliesColumns.paginationState.pageSize,
    offset:
      (designFamiliesColumns.paginationState.current - 1) *
      designFamiliesColumns.paginationState.pageSize,
    orderBy: designFamiliesColumns.orderByState,
  });

  return (
    <Table
      columns={designFamiliesColumns.tableColumns}
      dataTestId="designFamiliesTable"
      dataSource={designWithImages ?? []}
      onChange={designFamiliesColumns.handlePagination}
      loading={loading}
      pagination={{
        current: designFamiliesColumns.paginationState.current,
        pageSize: designFamiliesColumns.paginationState.pageSize,
        total,
      }}
      actions={
        <Flex alignItems="center" justify="space-between">
          <Spacer size="xsmall" align="center" justify="start">
            <SearchInput
              value={search}
              onDebouncedChange={(e) => setSearch(e)}
              placeholder={t('common.search', { context: 'table' })}
              style={{ width: '30%' }}
            />
            <EditColumnsButton
              initialColumns={columns}
              visibleColumns={designFamiliesColumns.visibleColumns}
              onChange={designFamiliesColumns.toggleColumnVisibility}
            />

            <Checkbox
              checked={isEffective}
              onChange={(e) => setIsEffective(e.target.checked)}
            >
              {t('table.common.effective')}
            </Checkbox>
          </Spacer>
          <Button
            loading={loadingExport}
            data-test-id="exportButton"
            type="primary"
            onClick={() => download()}
          >
            {t('table.common.exportButton')}
          </Button>
        </Flex>
      }
    />
  );
};

export default DesignFamiliesTable;
